import { Injectable } from "@angular/core";
import { gsap, ScrollTrigger } from 'gsap/all';
import Scrollbar from 'smooth-scrollbar';

@Injectable()
export class SmoothScroll {
    bodyScrollBar;

    destroyScrollBar(){
        document.querySelector('body').classList.remove('no-overflow');
        document.querySelector('.scroller').classList.remove('active');

        const scroller = <HTMLElement>document.querySelector('.scroller');
        Scrollbar.destroy(scroller);
    }

    setSmoothScrolling(){

      var isTouchDevice =
      (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0));
        if(isTouchDevice){
          return;
      }

      document.querySelector('body').classList.add('no-overflow');
      document.querySelector('body').classList.add('smoothed');
      document.querySelector('.scroller').classList.add('active');
        
        const scroller = <HTMLElement>document.querySelector('.scroller');

        Scrollbar.use(AnchorPlugin);
        const bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: false });
        
        ScrollTrigger.scrollerProxy(".scroller", {
        scrollTop(value) {
            if (arguments.length) {
                bodyScrollBar.scrollTop = value;
            }
            return bodyScrollBar.scrollTop;
        }
        });
    
        bodyScrollBar.addListener(ScrollTrigger.update);
    
        ScrollTrigger.defaults({ scroller: scroller });

        // Only necessary to correct marker position - not needed in production
        if (document.querySelector('.gsap-marker-scroller-start')) {    
        const markers = gsap.utils.toArray('[class *= "gsap-marker"]'); 

        bodyScrollBar.addListener(({ offset }) => {  
            gsap.set(markers, { marginTop: -offset.y })
        });
        }

        return bodyScrollBar;
    }
    
}

class AnchorPlugin extends Scrollbar.ScrollbarPlugin {
    static pluginName = 'anchor';
  
    onHashChange = () => {
      this.handleHash(location.hash);
    };
  
    onClick = (event) => {
      const { target } = event;
  
      if (target.tagName !== 'A') {
        return;
      }
  
      const hash = target.getAttribute('href');
  
      if (!hash || hash.charAt(0) !== '#') {
        return;
      }
  
      this.handleHash(hash);
    }

    handleHash = (hash) => {

      console.log('hash:', hash);
      
      if (!hash) {
        return;
      }
  
      if (hash === '#top') {
          this.scrollbar.setMomentum(0, -this.scrollbar.scrollTop);
      } else {
        console.log('scrollTop:', this.scrollbar.containerEl.scrollTop);
  
        this.scrollbar.scrollIntoView(document.querySelector(hash), {
          offsetTop: -this.scrollbar.containerEl.scrollTop,
        });
      }
    };
  
    onInit() {
      console.log('init');
      this.handleHash(location.hash);
  
      window.addEventListener('hashchange', this.onHashChange);
      window.addEventListener('hashchange', function(e){
          console.log('changed');
      });

      this.scrollbar.contentEl.addEventListener('click', this.onClick);
    }
  
    onDestroy() {
      window.removeEventListener('hashchange', this.onHashChange);
  
      this.scrollbar.contentEl.removeEventListener('click', this.onClick);
    }

    clearHistory(){
      history.pushState("", document.title, window.location.pathname);
      console.log('cleared');
    }
  }