<section id="header">
    <div class="h1-container">
        <h1><span class="pure-bar">\</span> le feed</h1>
    </div>
    
    <a (click)="scrollActus()" class="scroll-bottom">
        <svg id="picto-cta-scroll" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.16 96.16">
            <g id="fleche">
                <path id="tete_de_fleche" data-name="tete de fleche" d="M45.67,38.09,33.83,49.93,45.67,61.77" transform="translate(-2.1 -1.85)" fill="none" stroke="#9C974F" stroke-width="6.56"/>
                <path id="corps_de_fleche" data-name="corps de fleche" d="M66.6,49.93H33.75" transform="translate(-2.1 -1.85)" fill="none" stroke="#9C974F" stroke-width="6.56"/>
            </g>
            <circle id="cercle-noir" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#9C974F" stroke-width="2.09"/>
            <circle id="cercle-blanc" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#000000" stroke-width="2.09"/>
        </svg>
    </a>
</section>

<section id="actus">
    <div class="container">
        <div *ngFor="let feed of feed" class="feed-container">

            <span class="date"><span class="pure-bar">\</span> {{feed.year}}</span>

            <article *ngFor="let actu of feed.actus" id="actu-{{actu.id}}">
                <h3>{{actu.title}}</h3>
                <div class="content">
                    <div class="content-txt" [innerHTML]="actu.content">
                    </div>
                    <div class="img-container">
                        <img src="../../../assets/images/le-feed/article-{{actu.id}}-visuel.jpg" alt="{{actu.title}}">
                    </div>
                </div>
                <div class="meta">
                    <div class="meta-date">{{actu.date}}</div>
                    <a class="read-more" (click)="readMoreToggle(actu.id)">lire l'article</a>
                    <div class="fix-meta"></div>
                </div>
            </article>
        </div>
    </div>
</section>

<section id="decouvrir">
    <div class="container">
        <div class="col">
            <h2>Découvrir aussi</h2>
        </div>
        <div class="col">
            <app-main-bt [routerLink]="['/l-agence']" class="link-bt">l'agence</app-main-bt>
            <app-main-bt [routerLink]="['/references']" class="link-bt">références</app-main-bt>
        </div>
    </div>
</section>
<app-footer></app-footer>