import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Route, RouterLinkActive } from '@angular/router';
import { gsap, ScrollTrigger } from 'gsap/all';
import { Globals } from 'src/app/global';
import { SmoothScroll } from 'src/app/smoothscroll';
declare var $: any;

@Component({
  selector: 'app-agence',
  templateUrl: './agence.component.html',
  styleUrls: ['./agence.component.scss']
})
export class AgenceComponent implements OnInit, AfterViewInit, OnDestroy {
  slideConfigEquipe = {
    "useTransform": true,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "centerMode": true,
    'centerPadding': '600px',
    'responsive': [
      {
        'breakpoint': 1920,
          'settings': {
            'centerPadding': '30vw',
          }
        },
        {
          'breakpoint': 769,
            'settings': {
              'centerPadding': '30vw',
            }
          },
          {
            'breakpoint': 481,
              'settings': {
                'centerPadding': '60px',
              }
            }
    ]
  };
  slideConfigMetier = {
    "speed": 1000,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "adaptiveHeight": true
  };
  slidesEquipe = [
    {
      img: "Yannick.jpg",
      name: "Yannick Michel",
      poste: "Directeur de clientèle"
    },
    {
      img: "Jennifer.jpg",
      name: "Jennifer André",
      poste: "Chef de Projet"
    },
    {
      img: "Mathilde.jpg",
      name: "Mathilde Barbaria",
      poste: "Conceptrice rédactrice"
    },
    {
      img: "Anthony.jpg",
      name: "Anthony Garcia",
      poste: "Directeur de l'Agence"
    },
    {
      img: "Marie.jpg",
      name: "Marie Roudière",
      poste: "Responsable Administratif & Financier"
    },
    {
      img: "Jeremie.jpg",
      name: "Jérémie Cieslarczyk",
      poste: "Développeur web"
    },
    {
      img: "Emilie.jpg",
      name: "Émilie Truong",
      poste: "Designer graphique"
    },
    {
      img: "Leila.jpg",
      name: "Leïla-Marie Jreige",
      poste: "Directrice Artistique"
    },
    {
      img: "Stephane.jpg",
      name: "Stéphane Voisin",
      poste: "UX/UI Designer"
    },
    {
      img: "Nathalie.jpg",
      name: "Nathalie Pruvost",
      poste: "Directrice du Développement"
    },
    {
      img: "Jackson.jpg",
      name: "Jackson",
      poste: "Ambassadeur"
    }
  ];

  scrollbar;
  tlMetier1;
  tlMetier2;
  tlMetier3;
  tlMetier4;
  tlMetier5;
  tlMetier6;
  tlMetier7;
  config: any;
  fullpage_api: any;


  constructor(private _smoothScroll: SmoothScroll,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    public globals: Globals) {
    }

  getRef(fullPageRef) {
    this.fullpage_api = fullPageRef;
    this.globals.globalScrollbar = this.fullpage_api;
    this.globals.pageType = "fullpage";
    //this.fullpage_api.build();
  }

  ngOnInit() {
      this._smoothScroll.destroyScrollBar();
      this.slidesEquipe = this.shuffle(this.slidesEquipe);
      //this.scrollbar = this._smoothScroll.setSmoothScrolling();
      // for more details on config options please visit fullPage.js docs
      this.config = {

        // fullpage options
        licenseKey: "17B7CED9-E00F43D5-91BB0F5F-CD1EDBD4",
        navigation: false,
        responsiveWidth: 1366,
        css3: true,
        // fullpage callbacks
        afterResize: () => {
          console.log("After resize");
        },
        afterLoad: (origin, destination, direction) => {
          if(destination.index === 2){
            this.tlMetier1.resume();
          }
          if(destination.index === 0){
            $('#scrolltop').fadeOut();
          }else{
            $('#scrolltop').fadeIn();
          }
        }
      };
      //this.animHeader();
      $('#scrolltop').hide();
  }

  animHeader(){
    var heroTl = gsap.timeline();
    heroTl.fromTo("#header-section", {backgroundColor: '#1d1d1d'} ,{backgroundColor: '#F6F4F1', duration: 1});
    heroTl.delay(1);
    heroTl.fromTo("h1", {opacity:0, y: 100} ,{opacity: 1, y: 0, duration: 1.5});
  }
  
  /**
   * Shuffles array in place.
   * @param {Array} a items An array containing the items.
   */
  shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
  }

  ngAfterViewInit() {
    this.setMetiersAnimations();
  }

  setMetiersAnimations(){
    /*
    const rows = gsap.utils.toArray('.metier-1 .skill-list span');
    var delay = 0;
    rows.forEach(row => {
      gsap.fromTo(row, 
        {
          x: 200,
          opacity: 0
        },
        { 
        x: 0,
        opacity: 1,
        delay: delay,
        scrollTrigger: {
          trigger: '#metiers',
          start: "bottom bottom+=150px"
        }
      });
      delay += 0.2;
    });
    gsap.fromTo(".metier-1 h3", 
      {
        opacity: 0
      },
      { 
      opacity: 1,
      scrollTrigger: {
        start: "top top+=50%",
        trigger: '#metiers',
      }
    });*/

    //metier 1
    
    this.tlMetier1 = gsap.timeline();
    this.tlMetier1.fromTo(".metier-1 h3", {opacity: 0}, {opacity: 1, duration: .5});
    var rows2 = gsap.utils.toArray('.metier-1 .skill-list span');
    var delay = 0;
    rows2.forEach(row => {
      this.tlMetier1.fromTo(row, {x:200, opacity: 0}, {x:0, opacity: 1, duration: .5}, "-=.2");
    });
    this.tlMetier1.pause();

    //metier 2
    
    this.tlMetier2 = gsap.timeline();
    this.tlMetier2.fromTo(".metier-2 h3", {opacity: 0}, {opacity: 1, duration: .5});
    rows2 = gsap.utils.toArray('.metier-2 .skill-list span');
    var delay = 0;
    rows2.forEach(row => {
      this.tlMetier2.fromTo(row, {x:200, opacity: 0}, {x:0, opacity: 1, duration: .5}, "-=.2");
    });
    this.tlMetier2.pause();

    //metier 3
    
    this.tlMetier3 = gsap.timeline();
    this.tlMetier3.fromTo(".metier-3 h3", {opacity: 0}, {opacity: 1, duration: .5});
    rows2 = gsap.utils.toArray('.metier-3 .skill-list span');
    var delay = 0;
    rows2.forEach(row => {
      this.tlMetier3.fromTo(row, {x:200, opacity: 0}, {x:0, opacity: 1, duration: .5}, "-=.2");
    });
    this.tlMetier3.pause();

    //metier 4
    
    this.tlMetier4 = gsap.timeline();
    this.tlMetier4.fromTo(".metier-4 h3", {opacity: 0}, {opacity: 1, duration: .5});
    rows2 = gsap.utils.toArray('.metier-4 .skill-list span');
    var delay = 0;
    rows2.forEach(row => {
      this.tlMetier4.fromTo(row, {x:200, opacity: 0}, {x:0, opacity: 1, duration: .5}, "-=.2");
    });
    this.tlMetier4.pause();

    //metier 5
    
    this.tlMetier5 = gsap.timeline();
    this.tlMetier5.fromTo(".metier-5 h3", {opacity: 0}, {opacity: 1, duration: .5});
    rows2 = gsap.utils.toArray('.metier-5 .skill-list span');
    var delay = 0;
    rows2.forEach(row => {
      this.tlMetier5.fromTo(row, {x:200, opacity: 0}, {x:0, opacity: 1, duration: .5}, "-=.2");
    });
    this.tlMetier5.pause();

    //metier 6
    
    this.tlMetier6 = gsap.timeline();
    this.tlMetier6.fromTo(".metier-6 h3", {opacity: 0}, {opacity: 1, duration: .5});
    rows2 = gsap.utils.toArray('.metier-6 .skill-list span');
    var delay = 0;
    rows2.forEach(row => {
      this.tlMetier6.fromTo(row, {x:200, opacity: 0}, {x:0, opacity: 1, duration: .5}, "-=.2");
    });
    this.tlMetier6.pause();

    //metier 7
    
    this.tlMetier7 = gsap.timeline();
    this.tlMetier7.fromTo(".metier-7 h3", {opacity: 0}, {opacity: 1, duration: .5});
    rows2 = gsap.utils.toArray('.metier-7 .skill-list span');
    var delay = 0;
    rows2.forEach(row => {
      this.tlMetier7.fromTo(row, {x:200, opacity: 0}, {x:0, opacity: 1, duration: .5}, "-=.2");
    });
    this.tlMetier7.pause();
  }

  scrollApproche(){
    
    this.fullpage_api.moveTo('notre-approche');
  }

  scrollTopEvent(){
    this.fullpage_api.moveTo('header');
  }

  setScrollGsap(){
    var thiObj = this;
    gsap.utils.toArray("section").forEach((panel, i) => {
      ScrollTrigger.create({
        trigger: panel,
        onEnter: () => thiObj.goToSection(i)
      });
      
      ScrollTrigger.create({
        trigger: panel,
        start: "bottom bottom",
        onEnterBack: () => thiObj.goToSection(i),
      });
    });
  }
  
  goToSection(i, anim?) {
    gsap.to(window, {
      scrollTo: {y: i*innerHeight, autoKill: false},
      duration: 1
    });
    
    if(anim) {
      anim.restart();
    }
  }
  
  slickInitMetier(e) {
    //this.setMetiersAnimations();
  }
  
  afterChangeMetier(e) {
    var nextSlide = e.currentSlide + 1;
    if (nextSlide === 8){
      nextSlide = 1;
    }
    if(nextSlide == 2){
      this.tlMetier2.resume();
    }
    if(nextSlide == 3){
      this.tlMetier3.resume();
    }
    if(nextSlide == 4){
      this.tlMetier4.resume();
    }
    if(nextSlide == 5){
      this.tlMetier5.resume();
    }
    if(nextSlide == 6){
      this.tlMetier6.resume();
    }
    if(nextSlide == 7){
      this.tlMetier7.resume();
    }
  }
  
  beforeChangeMetier(e) {
    var nextSlide = e.nextSlide;
    if(nextSlide !== 0){
      $('#metiers-section .nav-slider').addClass('navWhite');
    }else{
      $('#metiers-section .nav-slider').removeClass('navWhite');
    }

    $('#metiers-section .nav-page .current').html(nextSlide+1);
  }
  
  slickInitEquipe(e) {
    $('#equipe-section .slick-current').addClass('animEquipeSlide');
  }
  
  afterChangeEquipe(e) {
    console.log('afterChange');
  }
  
  beforeChangeEquipe(e) {
    
    var nextSlide = e.nextSlide;
    $('.animEquipeSlide').removeClass('animEquipeSlide');
    $('#equipe-section div[data-slick-index='+nextSlide+']').addClass('animEquipeSlide');

    $('#equipe-section .nav-page .current').html(nextSlide+1);
  }

  slickNextSlide(e){
    $('.'+e).slick('slickNext');
  }

  slickPrevSlide(e){
    $('.'+e).slick('slickPrev');
  }

  ngOnDestroy(): void {
    $('#scrolltop').show();
  }

}
