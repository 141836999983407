import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alt-bt',
  templateUrl: './alt-bt.component.html',
  styleUrls: ['./alt-bt.component.scss']
})
export class AltBtComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
