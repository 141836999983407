import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { REFERENCES } from 'src/app/mock-references';
import { SmoothScroll } from 'src/app/smoothscroll';
import { gsap, ScrollTrigger } from 'gsap/all';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/global';
declare var $: any;

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})
export class ReferencesComponent implements OnInit, OnDestroy, AfterViewInit {
  currentRef = "services";
  currentProjet = "mas-tolosa"
  references = REFERENCES;
  allReferences = REFERENCES;
  slideConfigreferences = {
    "useCSS": false,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "centerMode": true,
    'centerPadding': '25vw',
    'infinite': true,
    'arrows': false,
    'responsive': [
      {
        'breakpoint': 1920,
          'settings': {
            'centerPadding': '20vw',
          }
        },
        {
          'breakpoint': 993,
            'settings': {
              "slidesToShow": 2,
              "centerMode": false,
            }
          },
    ]
  };

  scrollbar;

  constructor(private router: Router,
    private _smoothScroll: SmoothScroll,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    public globals: Globals) { 
  }

  ngOnInit(): void {
    console.log('ref INIT');
    this._smoothScroll.destroyScrollBar();
    this.scrollbar = this._smoothScroll.setSmoothScrolling();
    this.globals.globalScrollbar = this.scrollbar;
    this.globals.pageType = "scroll";
        
    gsap.fromTo('#scrolltop', 
    {
      opacity: 0,
    }
    ,
    { 
      opacity: 1,
      scrollTrigger: {
        trigger: "#header",
        start: "bottom top",
        toggleActions: "play none reverse reset"
      }
    });

    var thiObj = this;
    setTimeout(function(){
      thiObj.setAnimations();
    }, 600);
  }
  setAnimations(){

    ScrollTrigger.matchMedia({
      "(min-width: 992px)": function() {

        gsap.utils.toArray(".all-references .single-ref:nth-of-type(odd)").forEach(box => {

          gsap.fromTo(box, 
          {
            translateY: 150,
          }
          ,
          { 
            translateY: -150,
            scrollTrigger: {
              trigger: box,
              scrub: true,
            }
          });


        });

        gsap.utils.toArray(".all-references .single-ref:nth-of-type(even)").forEach(box => {

          gsap.fromTo(box, 
          {
            translateY: 50,
          }
          ,
          { 
            translateY: -50,
            scrollTrigger: {
              trigger: box,
              scrub: true,
            }
          });


        });

      }
    }); 

  }

  ngAfterViewInit(): void {

    var menuFilter = document.getElementById('menu-filter');

    this.scrollbar.addListener(({ offset }) => {  
      if(this.scrollbar.scrollTop >  window.innerHeight){
        var newTop = offset.y -  window.innerHeight;
        menuFilter.style.top = newTop + 'px';
        var val = window.innerHeight + document.getElementById('reference-section').offsetHeight - 600;
        if(val < this.scrollbar.scrollTop){
          menuFilter.classList.add('hideMe');
        }else{
          menuFilter.classList.remove('hideMe');
        }
      }
    });
  }

  startAnimations(){
    const rows = gsap.utils.toArray('.reference-category');
    rows.forEach(row => {
      gsap.fromTo(row, 
        {
          y: 300,
          opacity: 0
        },
        { 
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: row,
        }
      })
    });
  }

  scrollAll(){
    var isTouchDevice =
    (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
    if(isTouchDevice){
        document.getElementById('reference-section').scrollIntoView({ behavior: 'smooth' });
    }else{
      this.scrollbar.scrollIntoView(document.querySelector('#reference-section'), {
        offsetTop: -this.scrollbar.containerEl.scrollTop,
      });
    }
  }

  filterThis(elem : string){
    var thiObj = this;
    
    var filterTl = gsap.timeline();
    filterTl.fromTo(".all-references", {opacity: 1}, {opacity: 0, duration: .2});
    filterTl.add(function(){
      thiObj.allReferences = Array();
      if(elem !== 'all'){
        for ( var i=0, len=thiObj.references.length; i < len; i++ ){
          if(thiObj.references[i].slug === elem){
            thiObj.allReferences.push(thiObj.references[i]);
            break;
          }
        }
      }else{
        thiObj.allReferences = REFERENCES;
      }
    }, "+=.2");
    filterTl.add(function(){
      thiObj.setAnimations();
    }, "+=.2");
    filterTl.to(".all-references", {opacity: 1, duration: 1}, "+=.6");


    this.scrollAll();
    $('.reference-filter.active').removeClass('active');
    $('#filter-id-'+elem).addClass('active');
/*
    var thiObj = this;
    setTimeout(function(){
      thiObj.allReferences = Array();
      if(elem !== 'all'){
        for ( var i=0, len=thiObj.references.length; i < len; i++ ){
          if(thiObj.references[i].slug === elem){
            thiObj.allReferences.push(thiObj.references[i]);
            break;
          }
        }
      }else{
        thiObj.allReferences = REFERENCES;
      }
    }, 600);

    var thiObj = this;
    setTimeout(function(){
      thiObj.setAnimations();
    }, 700);*/
    
  }

  ngOnDestroy(): void {
  }

}
