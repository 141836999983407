import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HomepageComponent } from './page/homepage/homepage.component';
import { HomepageLoaderComponent } from './page/homepage/homepage-loader/homepage-loader.component';
import { MainBtComponent } from './components/main-bt/main-bt.component';
import { AltBtComponent } from './components/alt-bt/alt-bt.component';
import { FooterComponent } from './components/footer/footer.component';
import { SmoothScroll } from './smoothscroll';
import { AgenceComponent } from './page/agence/agence.component';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PictoFlecheComponent } from './components/picto-fleche/picto-fleche.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LeFeedComponent } from './page/le-feed/le-feed.component';
import { ReferencesComponent } from './page/references/references.component';
import { SingleReferenceComponent } from './page/references/single-reference/single-reference.component';
import { ContactComponent } from './page/contact/contact.component';
import { ReactiveFormsModule } from '@angular/forms';  
import { HttpClientModule } from '@angular/common/http';
import { ContactService } from './contact.service';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header/header.component';
import { AngularFullpageModule } from '@fullpage/angular-fullpage';
import { Globals } from './global';
import { CandidatureComponent } from './page/contact/candidature/candidature.component';
import { SEOService } from './seo.services';
import { MentionsLegalesComponent } from './page/mentions-legales/mentions-legales.component';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';

const cookieConfig:NgcCookieConsentConfig = {
  "cookie": {
    "domain": "agence-pure.com"
  },
  "position": "bottom-right",
  "theme": "block",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#9c974f",
      "text": "#ffffff",
      "border": "transparent"
    }
  },
  "type": "info",
  "content": {
    "message": "Nous utilisons des cookies pour nous permettre de mieux comprendre comment le site est utilisé. En continuant à utiliser ce site, vous acceptez cette politique.",
    "dismiss": "J'accepte",
    "deny": "Refuse cookies",
    "link": "En savoir plus",
    "href": "https://agence-pure.com/mentions-legales/",
    "policy": "Cookie Policy"
  }
};


const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 0],
};

const routes: Routes = [
  { 
    path: '',
    component: HomepageComponent,
    data: {
      title: 'Pure – Agence de communication à Toulouse et Paris',
      description:'Pure est une agence de communication qui propose à ses clients une approche créative, design et unique de la communication.',
      ogUrl: 'https://agence-pure.com/',
    }
  },
  { 
    path: 'contact',
    component: ContactComponent,
    data: {
      title: 'Pure - Contact',
      description:'Un projet, un brief, une question ? Toute notre équipe se tient à votre disposition pour vous répondre et vous conseiller au mieux.',
      ogUrl: 'https://agence-pure.com/contact',
    }
  },
  { 
    path: 'recrutement',
    component: CandidatureComponent,
    data: {
      title: 'Pure – Nous rejoindre',
      description:'Vous souhaitez rejoindre notre équipe sur Toulouse ou Paris, vous pouvez déposer votre candidature.',
      ogUrl: 'https://agence-pure.com/recrutement',
    }
  },
  { 
    path: 'references',
    component: ReferencesComponent,
    data: {
      title: 'Pure – Nos clients',
      description:'Découvrez nos réalisations branding, de stratégie en communication, en création de sites web, en ux-ui design, de campagnes d’activation… ',
      ogUrl: 'https://agence-pure.com/references',
    }
  },
  { 
    path: 'references/:cat/:slug',
    component: SingleReferenceComponent,
    data: {
      title: 'Pure – Cas client',
      description:'Découvrez le travail que nous avons réalisé.',
      ogUrl: 'https://agence-pure.com/references',
    }
  },
  { 
    path: 'le-feed',
    component: LeFeedComponent,
    data: {
      title: 'Pure – Nos actualités',
      description:'Découvrez toutes nos actualités : la vie de l’agence, nos dernières réalisations et les potins !',
      ogUrl: 'https://agence-pure.com/le-feed',
    }
  },
  { 
    path: 'l-agence',
    component: AgenceComponent,
    data: {
      title: 'Pure – No standards',
      description:'Chez Pure, nous accompagnons nos clients là où ils n’auraient pas osés. Découvrez notre philosophie, notre équipe et nos métiers.',
      ogUrl: 'https://agence-pure.com/l-agence',
    }
  },
  { 
    path: 'mentions-legales',
    component: MentionsLegalesComponent,
    data: {
      title: 'Pure – Agence de communication à Toulouse et Paris',
      description:'Pure est une agence de communication qui propose à ses clients une approche créative, design et unique de la communication.',
      ogUrl: 'https://agence-pure.com/mentions-legales',
    }
  },
  {path: '404', component: HomepageComponent},
  {path: '**', redirectTo: '/'}
];
@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HomepageLoaderComponent,
    MainBtComponent,
    AltBtComponent,
    FooterComponent,
    AgenceComponent,
    PictoFlecheComponent,
    LeFeedComponent,
    ReferencesComponent,
    SingleReferenceComponent,
    ContactComponent,
    HeaderComponent,
    CandidatureComponent,
    MentionsLegalesComponent
  ],
  imports: [
    AngularFullpageModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserModule,
    CommonModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top'
    }),
    SlickCarouselModule,
    BrowserAnimationsModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    SEOService,
    ContactService,
    SmoothScroll,
    Globals
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
