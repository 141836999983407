<footer>
    <div class="container">
        <div class="col-left">
            <div class="menu menu-1">
                <a [routerLink]="['/']">Accueil</a>
                <a [routerLink]="['/references']">Références</a>
                <a [routerLink]="['/l-agence']">l'Agence</a>
                <a [routerLink]="['/le-feed']">Le Feed</a>
                <a [routerLink]="['/contact']">Contact</a>
                <a [routerLink]="['/recrutement']">Recrutement</a>
                <a [routerLink]="['/mentions-legales']">Mentions légales</a>
            </div>
            <div class="menu menu-2">
                <a href="https://www.facebook.com/AgenceCommunicationPure/" target="_blank">Facebook</a>
                <a href="https://www.instagram.com/agencepure/" target="_blank">Instagram</a>
                <a href="https://www.linkedin.com/company/agence-pure/?originalSubdomain=fr" target="_blank">Linkedin</a>
            </div>
        </div>
        <div class="col-center">
            <a href="tel:+33534260039">+33 (0)5 34 26 00 39</a>
        </div>
        <div class="col-right">
            <svg xmlns="http://www.w3.org/2000/svg" width="143.855" height="35.955" viewBox="0 0 143.855 35.955">
                <g id="Groupe_325" data-name="Groupe 325" transform="translate(-31.943 -24.459)">
                  <path id="Tracé_397" data-name="Tracé 397" d="M100.808,45.586c0,5.7-2.119,8.472-6.808,8.472-4.74,0-6.808-2.772-6.808-8.472V24.459H80.687V45.688c0,9.734,4.739,14.726,13.212,14.726,8.421,0,13.414-4.992,13.414-14.726V24.459h-6.505Z"/>
                  <path id="Tracé_398" data-name="Tracé 398" d="M159.56,54.086V44.731h12.1V39.058h-12.1V30.146H175.6V24.459h-22.54v35.3H175.8V54.086Z"/>
                  <path id="Tracé_399" data-name="Tracé 399" d="M31.943,24.461l20.14,35.3h7.288l-20.14-35.3Z"/>
                  <path id="Tracé_400" data-name="Tracé 400" d="M62.792,24.475H47.985l3.254,5.671H62.061a5.6,5.6,0,0,1,0,11.207H57.671l3.256,5.673H62.86a11.024,11.024,0,0,0,11-11.277A11.055,11.055,0,0,0,62.792,24.475Z"/>
                  <path id="Tracé_401" data-name="Tracé 401" d="M143.371,35.749A11.055,11.055,0,0,0,132.3,24.475h-13.84V59.758h6.5V47.026h4.251l6.34,12.732h7.816L136.3,46.3A11.126,11.126,0,0,0,143.371,35.749Zm-11.8,5.6h-6.607V30.146h6.6a5.6,5.6,0,0,1,0,11.207Z"/>
                </g>
              </svg>
              <p>&copy; Pure - No Standards - <span class="copy-line-break">Toulouse - Paris - 2021</span></p>
        </div>
    </div>
</footer>