import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { REFERENCES } from 'src/app/mock-references';
import { projet, Reference } from 'src/app/references';
import { SmoothScroll } from 'src/app/smoothscroll';
import { gsap, ScrollTrigger } from 'gsap/all';
import { Globals } from 'src/app/global';
import { SEOService } from 'src/app/seo.services';

@Component({
  selector: 'app-single-reference',
  templateUrl: './single-reference.component.html',
  styleUrls: ['./single-reference.component.scss']
})
export class SingleReferenceComponent implements OnInit, AfterViewInit {
  slug: string;
  cat: string;
  references = REFERENCES;
  reference: Reference;
  projet: projet;
  scrollbar;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private _smoothScroll: SmoothScroll,
    public globals: Globals,
    private _seoService:SEOService) {
      this.router.routeReuseStrategy.shouldReuseRoute = function(){
        return false;
     }

     this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
           // trick the Router into believing it's last link wasn't previously loaded
           this.router.navigated = false;
           // if you need to scroll back to top, here is the right place
           window.scrollTo(0, 0);
        }
    });
    }

  ngOnInit(): void {
    this._smoothScroll.destroyScrollBar();
    this.scrollbar = this._smoothScroll.setSmoothScrolling();
    this.globals.globalScrollbar = this.scrollbar;
    this.globals.pageType = "scroll";

    this.slug = this.route.snapshot.paramMap.get('slug');
    this.cat = this.route.snapshot.paramMap.get('cat');
    this.reference = this.references.find(elem => elem.slug === this.cat);
    this.projet = this.reference.projet.find(elem => elem.slug === this.slug);
    if(!this.projet){
      this.router.navigate(['references']);
    }

    this._seoService.updateTitle('Pure – Cas client ' + this.projet.fullName);
    this._seoService.updateOgUrl('https://www.agence-pure.com/references/' + this.cat + '/' + this.slug);
    this._seoService.updateOgTitle('Pure – Cas client ' + this.projet.fullName);
    this._seoService.updateOgDesc(this.projet.metaDesc);
    //Updating Description tag dynamically with title
    this._seoService.updateDescription(this.projet.metaDesc);
        
    gsap.fromTo('#scrolltop', 
    {
      opacity: 0,
    }
    ,
    { 
      opacity: 1,
      scrollTrigger: {
        trigger: "#header",
        start: "bottom top",
        toggleActions: "play none reverse reset"
      }
    });

  }

  ngAfterViewInit(): void {
    var thiObj = this;
    setTimeout(function(){
      thiObj.startAnimations();
    }, 500);
  }

  startAnimations(){
    gsap.fromTo('.h1-container', 
      {
        y: 0,
      },
      { 
      y: -150,
      scrollTrigger: {
        trigger: '#header',
        scrub: true,
        start: "top top",
      }
    })
    
    ScrollTrigger.matchMedia({
      "(min-width: 769px)": function() {
        gsap.fromTo('.logo', 
          {
            y: 200,
          },
          { 
          y: -200,
          scrollTrigger: {
            trigger: '#infos',
            scrub: true,
            start: "top bottom",
            end: "bottom top"
          }
        })
      }
    })

    const rows = gsap.utils.toArray('.row-all-skill p');
    var delay = 0;
    rows.forEach(row => {
      gsap.fromTo(row, 
        {
          y: 100,
          opacity: 0
        },
        { 
        y: 0,
        opacity: 1,
        duration: 1,
        delay: delay,
        scrollTrigger: {
          trigger: '#infos',
          start: "bottom bottom",
        }
      });
      delay += 0.2;
    });
  }

  navTo(slug, projet){
    console.log("yes");
    this.router.navigate(['/references'], {
      queryParamsHandling: 'merge' //== if you need to keep queryParams
    })
  }

  scrollInfos(){
    var isTouchDevice =
    (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
    if(isTouchDevice){
        document.getElementById('infos').scrollIntoView({ behavior: 'smooth' });
    }else{
      this.scrollbar.scrollIntoView(document.querySelector('#infos'), {
        offsetTop: -this.scrollbar.containerEl.scrollTop,
      });
    }
  }

  layoutColRight(type){
    return type === 'col-right';
  }

  layoutColLeft(type){
    return type === 'col-left';
  }
  layoutNormal(type){
    if (type === '1-1'){
      return true;
    }
    if (type === 'full'){
      return true;
    }
    return false;
  }

}
