<section id="header">
    <div class="container">
        <div class="col title">
            <div class="h1-container">
                <h1><span class="pure-bar">\</span> contact</h1>
            </div>
        </div>
        <div class="col form">
            <!-- START FORM -->
                <form id="formContact" [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">
                    <div class="col-left-form">
                        <div class="form-group-container">
                            <div class="form-group">
                                <input required type="text" class="form-control" name="Nom" formControlName="Nom">
                                <label for="Nom">
                                    <span class="content-name">Nom*</span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group-container">
                            <div class="form-group">
                                <input required type="text" class="form-control" name="Prenom" formControlName="Prenom">
                                <label for="Prenom">
                                    <span class="content-name">Prénom*</span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group-container">
                            <div class="form-group">
                                <input required type="text" class="form-control" name="Telephone" formControlName="Telephone">
                                <label for="Telephone">
                                    <span class="content-name">Téléphone*</span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group-container">
                            <div class="form-group">
                                <input required type="text" class="form-control" name="Email" formControlName="Email">
                                <label for="Email">
                                    <span class="content-name">Adresse mail*</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-right-form">
                        <div class="form-group-container">
                            <div class="form-group">
                                <input required type="text" class="form-control" name="Entreprise" formControlName="Entreprise">
                                <label for="Entreprise">
                                    <span class="content-name">Entreprise*</span>
                                </label>
                            </div>
                        </div>
                        <div class="form-group-container">
                            <div class="form-group form-group-textarea">
                                <textarea required class="form-control" formControlName="Message" name="Message">
                                </textarea>
                                <label for="Message">
                                    <span class="content-name">Message*</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-action">
                        <re-captcha (resolved)="resolved($event)" 
                                    formControlName="recaptchaReactive" 
                                    siteKey="6LemCDYaAAAAAGgC-PLBgTK-y2U-rZXDncFpvcB9">  
                        </re-captcha>

                        <button type="submit" class="btn btn-primary" [className]="!FormData.valid ? 'disable' : 'enable'" [disabled]="!FormData.valid">
                            <app-alt-bt>envoyer</app-alt-bt>
                        </button>
                    </div>
                    <p *ngIf="this.formSend">Votre demande nous a bien été communiqué. Notre équipe vous recontactera dans les plus brefs délais.
                        <br>Pure – No Standards.</p>
                    <p *ngIf="this.formError">Une erreur s’est produite lors de l’envoi de votre message. Veuillez essayer à nouveau plus tard.</p>
                </form>

            <!-- END FORM -->
        </div>
    </div>
</section>

<section id="separateur-reunion">
    <div class="bg"></div>
</section>

<section id="infos">
    <div class="container">
        <div class="row">
            <div class="col-title">
                <h2>Contact</h2>
            </div>
            <div class="col-info-1">
                <a href="mailto:contact@agence-pure.com">
                    contact@agence-pure.com
                </a>
            </div>
            <div class="col-info-2">
                <a href="tel:+33534260039">
                    +33 (0)5 34 26 00 39
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-title">
                <h2>Adresse</h2>
            </div>
            <div class="col-info-1">
                <a href="https://goo.gl/maps/nuE7nXWUk7grYfhz9" target="_blank">
                    17 allée des Demoiselles
                    <br>31400 Toulouse
                </a>
            </div>
            <div class="col-info-2">
                <a href="https://goo.gl/maps/7njhLJo1xYiXFwtF9" target="_blank">
                    59 rue de Ponthieu,
                    <br>75008 Paris
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-title">
                <h2>Suivez-nous</h2>
            </div>
            <div class="col-info-rs">
                <div class="separator"></div>
                <a href="https://fr-fr.facebook.com/AgenceCommunicationPure/" target="_blank">
                    Facebook
                </a>
                <a href="https://www.instagram.com/agencepure/" target="_blank">
                    Instagram
                </a>
                <a href="https://fr.linkedin.com/company/agence-pure" target="_blank">
                    LinkedIn
                </a>
            </div>
        </div>
    </div>
</section>

<section id="decouvrir">
    <div class="container">
        <div class="col">
            <h2>Retour sur</h2>
        </div>
        <div class="col">
            <app-main-bt [routerLink]="['/l-agence']" class="link-bt">l'agence</app-main-bt>
            <app-main-bt [routerLink]="['/references']" class="link-bt">références</app-main-bt>
        </div>
    </div>
</section>

<app-footer></app-footer>