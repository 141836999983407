<section class="header-ml">
    <div class="container-ml">
    <h1><span class="pure-bar">\</span> Mentions légales</h1>
    </div>
</section>
<section class="content-ml">
    <div class="container-ml">

<h2>Propriétaire du site</h2>
<p>Directeur de la publication : Anthony Garcia
<br>Téléphone : 05 34 26 00 39</p>

<h2>Conception et hébergement</h2>
<p>
    Conception, réalisation et développement du site : PURE
    <br>Hébergement : <a href="https://www.ovh.com/fr/" target="_blank">OVH</a>
</p>

<h2>Informations</h2>
<p>
Ce site Internet a pour objet de fournir des informations sur les activités de PURE.
<br>Sauf mention contraire, les droits de propriété intellectuelle sur les documents contenus dans le site Internet et chacun des éléments créés pour ce site Internet sont la propriété exclusive de PURE, cette entreprise ne concédant aucune licence, ni aucun droit que celui de consulter le site Internet. En particulier, les marques et autres droits de propriété intellectuelle cités sur le site sont la propriété des entités concernées. La reproduction de tous documents publiés sur le site Internet est seulement autorisée aux fins exclusives d’information pour un usage personnel et privé, toute reproduction et toute utilisation de copies réalisées à d’autres fins étant expressément interdite.
<br>En application des dispositions de la loi française du 6 janvier 1978, toute personne citée sur une page de ce site Internet peut exercer ses droits d’accès, de modification, de rectification ou de suppression des informations le concernant via notre formulaire de contact ou par mail : contact@agence-pure.com.
</p>

<h2>Données personnelles</h2>
<h3>Les droits que vous avez sur vos données</h3>
<p>Si vous avez utilisé les formulaires de contact et/ou de candidature, vous pouvez demander à recevoir un fichier contenant toutes les données personnelles que nous possédons à votre sujet, incluant celles que vous nous avez fournies. Vous pouvez également demander la suppression des données personnelles vous concernant. Cela ne prend pas en compte les données stockées à des fins administratives, légales ou pour des raisons de sécurité.</p>

<h3>Transmission de vos données personnelles</h3>
<p>
Les informations recueillies via nos formulaires de contact et de candidature; à savoir : Nom, Prénom, Mail, Téléphone, Entreprise, pièce jointe et Message ; sont uniquement lues par PURE afin d’apporter des réponses à vos questions, à vos demandes ou à vos candidatures.
<br>Elles sont conservées pendant un maximum de 3 ans et sont destinées au service communication de PURE.
<br>Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d’accès aux données vous concernant et les faire rectifier en nous contactant par mail, à contact@agence-pure.com, ou par téléphone, au 05 34 26 00 39.
</p>

<h2>Mentions relatives aux cookies</h2>
<h3>Qu'est-ce qu'un cookie et à quoi sert-il ?</h3>
<p>
Un cookie (ou témoin de connexion) est un fichier texte susceptible d'être enregistré, sous réserve de vos choix, dans un espace dédié du disque dur de votre terminal (ordinateur, tablette …) à l'occasion de la consultation d'un service en ligne grâce à votre logiciel de navigation.
<br>Il est transmis par le serveur d'un site internet à votre navigateur. A chaque cookie est attribué un identifiant anonyme. Le fichier cookie permet à son émetteur d'identifier le terminal dans lequel il est enregistré pendant la durée de validité ou d'enregistrement du cookie concerné. Un cookie ne permet pas de remonter à une personne physique.
<br>Lorsque vous consultez le site agence-pure.com, nous pouvons être amenés à installer, sous réserve de votre choix, différents cookies.
</p>

<h3>Quels types de cookies sont déposés par le site Web ?</h3>
<h4>Cookies de Statistiques Google Analytics</h4>
<p>
    Il s'agit des seul cookies que le site agence-pure.com utilise. Ces cookies permettent d'établir des statistiques de fréquentation du site et de détecter des problèmes de navigation afin de suivre et d'améliorer la qualité de nos services.
</p>

<h3>Exercez vos choix selon le navigateur</h3>
<p>
Les navigateurs internet sont habituellement paramétrés de manière à accepter les « cookies ». Vous pouvez bien entendu vous opposer à leur présence et les refuser ou les supprimer. A cette fin, il vous est possible de les supprimer de votre Equipement en consultant la page d’aide du navigateur utilisé ou vous opposer à l’enregistrement de « cookies » en désactivant cette fonction dans votre navigateur.
<br>Les modalités pour la désactivation des « cookies » sont précisées sur le site Internet de la Commission Nationale de l’Informatique et des Libertés (CNIL), dans sa rubrique relative aux « cookies et autres traceurs » et consultable à l’adresse suivante : http://www.cnil.fr
</p>
</div>
</section>

<app-footer></app-footer>