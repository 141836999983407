<app-header></app-header>

<div class="viewport">
<div class="scroller">
    <router-outlet (activate)="changeOfRoutes()"></router-outlet>
</div>
</div>

<div id="scrolltop" (click)="scrollTopEvent()">
    <svg xmlns="http://www.w3.org/2000/svg" width="25.893" height="34.197" viewBox="0 0 25.893 34.197">
        <g id="Groupe_6819" data-name="Groupe 6819" transform="translate(19.75 37.197) rotate(180)">
          <path id="Tracé_9725" data-name="Tracé 9725" d="M0,0,10.825,10.825,21.651,0" transform="translate(-4.022 22.13)" fill="none" stroke="#f6f4f1" stroke-width="6"/>
          <path id="Tracé_9726" data-name="Tracé 9726" d="M0,0V30.026" transform="translate(6.804 3)" fill="none" stroke="#f6f4f1" stroke-width="6"/>
        </g>
      </svg>      
</div>