<section id="header" style="background-image: url(../../../../assets/images/references/{{reference.slug}}/{{projet.slug}}/{{projet.slug}}-header.jpg);">
    
    <div class="h1-container">
        <h1>\ <span [innerHTML]="projet.name"></span></h1>
    </div>
    
    <a (click)="scrollInfos()" class="scroll-bottom">
        <svg id="picto-cta-scroll" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.16 96.16">
            <g id="fleche">
                <path id="tete_de_fleche" data-name="tete de fleche" d="M45.67,38.09,33.83,49.93,45.67,61.77" transform="translate(-2.1 -1.85)" fill="none" stroke="#fff" stroke-width="6.56"/>
                <path id="corps_de_fleche" data-name="corps de fleche" d="M66.6,49.93H33.75" transform="translate(-2.1 -1.85)" fill="none" stroke="#fff" stroke-width="6.56"/>
            </g>
            <circle id="cercle-noir" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#fff" stroke-width="2.09"/>
            <circle id="cercle-blanc" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#9C974F" stroke-width="2.09"/>
        </svg>
    </a>
</section>

<section id="infos">
    <div class="container">
        <div class="row-infos">
            <div class="col">
                <h2>{{projet.fullName}}</h2>
                <p class="quote">{{projet.quote}}</p>
                <p class="content" [innerHTML]="projet.content"></p>
            </div>
            <div class="col">
                <img class="logo" src="../../../../assets/images/references/{{reference.slug}}/{{projet.slug}}/{{projet.slug}}-logo.png" alt="Logo {{projet.name}}">
            </div>
        </div>
        <div class="row row-skill-line">
        </div>
        <div class="row row-all-skill">
            <p [ngClass]="{active: projet.csm}">Conseil &<br>stratégie<br>de marque</p>
            <p [ngClass]="{active: projet.bc}">Brand<br>content</p>
            <p [ngClass]="{active: projet.sip}">Stratégie<br>d'image<br>& publicité</p>
            <p [ngClass]="{active: projet.edition}">Édition</p>
            <p [ngClass]="{active: projet.sod}">Stratégie<br>& outils<br>digitaux</p>
            <p [ngClass]="{active: projet.sms}">Social media<br>strategy</p>
            <p [ngClass]="{active: projet.event}">Événementiel</p>
        </div>
    </div>
</section>

<section id="gallery">
    <div class="container">
        <div *ngFor="let gallery of projet.gallery" class="row {{gallery.type}}">
            <ng-template [ngIf]="layoutNormal(gallery.type)">
                <div *ngFor="let image of gallery.images" class="img-container">
                    <img src="../../../../assets/images/references/{{reference.slug}}/{{projet.slug}}/{{image.url}}" alt="{{image.alt}}">
                </div>
            </ng-template>

            <ng-template [ngIf]="layoutColRight(gallery.type)">
                <div class="img-container">
                    <img src="../../../../assets/images/references/{{reference.slug}}/{{projet.slug}}/{{gallery.images[0].url}}" alt="{{gallery.images[0].alt}}">
                    <img src="../../../../assets/images/references/{{reference.slug}}/{{projet.slug}}/{{gallery.images[1].url}}" alt="{{gallery.images[1].alt}}">
                    <img *ngIf="gallery.images[3]" src="../../../../assets/images/references/{{reference.slug}}/{{projet.slug}}/{{gallery.images[3].url}}" alt="{{gallery.images[3].alt}}">
                </div>
                <div class="img-container">
                    <img src="../../../../assets/images/references/{{reference.slug}}/{{projet.slug}}/{{gallery.images[2].url}}" alt="{{gallery.images[2].alt}}">
                </div>
            </ng-template>

            <ng-template [ngIf]="layoutColLeft(gallery.type)">
                <div class="img-container">
                    <img src="../../../../assets/images/references/{{reference.slug}}/{{projet.slug}}/{{gallery.images[0].url}}" alt="{{gallery.images[0].alt}}">
                </div>
                <div class="img-container">
                    <img src="../../../../assets/images/references/{{reference.slug}}/{{projet.slug}}/{{gallery.images[1].url}}" alt="{{gallery.images[1].alt}}">
                    <img src="../../../../assets/images/references/{{reference.slug}}/{{projet.slug}}/{{gallery.images[2].url}}" alt="{{gallery.images[2].alt}}">
                    <img *ngIf="gallery.images[3]" src="../../../../assets/images/references/{{reference.slug}}/{{projet.slug}}/{{gallery.images[3].url}}" alt="{{gallery.images[3].alt}}">
                </div>
            </ng-template>
        </div>
    </div>
</section>

<section id="decouvrir">
    <div class="container">
        <div class="col">
            <h2>Continuer vers</h2>
        </div>
        <div class="col">
            <app-main-bt [routerLink]="['/references', projet.nextRefCat, projet.nextRefSlug]" class="link-bt">{{projet.nextRefName}}</app-main-bt>
            <app-main-bt [routerLink]="['/references']" fragment="all" class="link-bt">+ de références</app-main-bt>
        </div>
    </div>
</section>
<app-footer></app-footer>