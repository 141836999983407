import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { gsap, ScrollTrigger } from 'gsap/all';
import { Globals } from 'src/app/global';
import { SmoothScroll } from 'src/app/smoothscroll';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, AfterViewInit   {

  aboutsUsLabel = "+ sur Pure";
  currentSlide = 2;
  slider;
  scrollbar;
  heroTl;
  videoPaused = true;

  constructor(private _smoothScroll: SmoothScroll,
    public globals: Globals) {
  }

  ngOnInit(): void {
    var thiObj = this;
    if(this.globals.showLoader){
      this._smoothScroll.destroyScrollBar();
      setTimeout(function(){
        thiObj.scrollbar = thiObj._smoothScroll.setSmoothScrolling();
        thiObj.globals.globalScrollbar = thiObj.scrollbar;
        thiObj.globals.pageType = "scroll";
        thiObj.setAnimations();
        thiObj.setAnimationHeader();
        document.querySelector('body').classList.remove('hide-header');
        document.querySelector('body').classList.remove('no-overflow');
        thiObj.globals.showLoader = false;
      }, 5800);
    }else{
      this._smoothScroll.destroyScrollBar();
      this.scrollbar = this._smoothScroll.setSmoothScrolling();
      this.globals.globalScrollbar = this.scrollbar;
      this.globals.pageType = "scroll";
      this.setAnimationHeader();
      setTimeout(function(){
        thiObj.setAnimations();
      }, 500);
    }
  }

  ngAfterViewInit(): void {
  }

  scrollAboutUs(){
    var isTouchDevice =
    (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
    if(isTouchDevice){
        document.getElementById('about-us').scrollIntoView({ behavior: 'smooth' });
    }else{
      this.scrollbar.scrollIntoView(document.querySelector('#about-us'), {
        offsetTop: -this.scrollbar.containerEl.scrollTop,
      });
    }
  }

  setAnimationHeader(){
    this.heroTl = gsap.timeline();
    this.heroTl.delay(.5);
    this.heroTl.to("h1 .top-hide", {yPercent: -110, duration: .5});
    this.heroTl.to("h1 .bot-hide", {yPercent: 110, duration: .5}, '-=.5');
    this.heroTl.to("h1 #logo-p", {left: 0, duration: 1.5});
    this.heroTl.to("h1 #logo-ure", {right: 0,xPercent: 0, duration: 1.5}, '-=1.5');
    this.heroTl.add( function(){ document.getElementById('logo-p').classList.add("noAfter") } );
    this.heroTl.fromTo(".signature", {yPercent: 100}, {yPercent: 0, duration: 1});
    this.heroTl.fromTo(".bg-txt-v2 span.item-1", {translateY: "1em"}, {translateY: "0em", duration: 1});
    this.heroTl.fromTo(".bg-txt-v2 span.item-2", {translateY: "1em"}, {translateY: "-1em", duration: 1}, '-=1');
    this.heroTl.fromTo(".bg-txt-v2 span.item-3", {translateY: "1em"}, {translateY: "-1.5em", duration: 1}, '-=1');
    this.heroTl.fromTo(".bg-txt-v2 span.item-4", {translateY: "1em"}, {translateY: "-2em", duration: 1}, '-=1');
    this.heroTl.add(function(){
      document.querySelector('.pointer-none').classList.remove('pointer-none');
      ScrollTrigger.matchMedia({
        "(min-width: 992px)": function() {
        
            gsap.fromTo('.bg-txt-v2 span.item-4', 
            {
              translateY: "-2em",
            }
            ,
            { 
              translateY: "-0.5em",
              scrollTrigger: {
                trigger: "#hero",
                start: "bottom bottom",
                end: "bottom top",
                scrub: true,
              }
            });

            gsap.fromTo('.bg-txt-v2 span.item-3', 
            {
              translateY: "-1.5em",
            }
            ,
            { 
              translateY: "-0.5em",
              scrollTrigger: {
                trigger: "#hero",
                start: "bottom bottom",
                end: "bottom top",
                scrub: true,
              }
            });

            gsap.fromTo('.bg-txt-v2 span.item-2', 
            {
              translateY: "-1em",
            }
            ,
            { 
              translateY: "-0.5em",
              scrollTrigger: {
                trigger: "#hero",
                start: "bottom bottom",
                end: "bottom top",
                scrub: true,
              }
            });
          },
        });

    });
  }

  showVideo(){
    var thiObj = this;
    let video = <HTMLMediaElement>document.getElementById('video-pure');
    document.getElementById('bt-mute').classList.add('showMe');
    video.classList.add('showMe');
    if(this.videoPaused){
      video.play();
      this.videoPaused = false;
    }else{
      video.pause();
      this.videoPaused = true;
    }
    video.onended = function() {
      video.classList.remove('showMe');
      document.getElementById('bt-mute').classList.remove('showMe');
      document.getElementById('bt-unmute').classList.remove('showMe');
      thiObj.videoPaused = true;
    };
  }

  muteVideo(){
    let video = <HTMLMediaElement>document.getElementById('video-pure');
    document.getElementById('bt-mute').classList.remove('showMe');
    document.getElementById('bt-unmute').classList.add('showMe');
    video.muted = true;
  }

  unMuteVideo(){
    let video = <HTMLMediaElement>document.getElementById('video-pure');
    document.getElementById('bt-mute').classList.add('showMe');
    document.getElementById('bt-unmute').classList.remove('showMe');
    video.muted = false;
  }

  setAnimations(){
        
    gsap.fromTo('#scrolltop', 
    {
      opacity: 0,
    }
    ,
    { 
      opacity: 1,
      scrollTrigger: {
        trigger: "#header",
        start: "bottom top",
        toggleActions: "play none reverse reset"
      }
    });

    ScrollTrigger.matchMedia({
      "(min-width: 992px)": function() {
        gsap.fromTo('#projets .projet-1', 
        {
          translateY: 150,
        }
        ,
        { 
          translateY: -150,
          scrollTrigger: {
            trigger: "#projets .projet-1",
            scrub: true,
          }
        });

        gsap.fromTo('#projets .projet-2', 
        {
          translateY: 50,
        }
        ,
        { 
          translateY: -50,
          scrollTrigger: {
            trigger: "#projets .projet-2",
            scrub: true,
          }
        });

        gsap.fromTo('#projets .projet-3', 
        {
          translateY: 70,
        }
        ,
        { 
          translateY: -70,
          scrollTrigger: {
            trigger: "#projets .projet-3",
            scrub: true,
          }
        });

        gsap.fromTo('#projets .projet-4', 
        {
          translateY: 130,
        }
        ,
        { 
          translateY: -130,
          scrollTrigger: {
            trigger: "#projets .projet-4",
            scrub: true,
          }
        });

        gsap.fromTo('#projets .projet-5', 
        {
          translateY: 30,
        }
        ,
        { 
          translateY: -30,
          scrollTrigger: {
            trigger: "#projets .projet-5",
            scrub: true,
          }
        });
      }
    }); 

    gsap.fromTo('.text-container span.item-1', 
    {
      opacity: 0,
      translateY: '100%',
    }
    ,
    { 
      opacity: 1,
      translateY: '0%',
      scrollTrigger: {
        trigger: '.text-container',
        start: 'top center',
      }
    });

    gsap.fromTo('.text-container span.item-2', 
    {
      opacity: 0,
      translateY: '0em',
    }
    ,
    { 
      opacity: 1,
      translateY: '-1em',
      scrollTrigger: {
        trigger: '.text-container',
        start: 'top center',
      }
    });

    gsap.fromTo('.text-container span.item-3', 
    {
      opacity: 0,
      translateY: '0em',
    }
    ,
    { 
      opacity: 40,
      translateY: '-1.5em',
      scrollTrigger: {
        trigger: '.text-container',
        start: 'top center',
      }
    });

    gsap.fromTo('.text-container span.item-4', 
    {
      opacity: 0,
      translateY: '0em',
    }
    ,
    { 
      opacity: .30,
      translateY: '-2em',
      scrollTrigger: {
        trigger: '.text-container',
        start: 'top center',
      }
    });

    gsap.fromTo('.text-container span.item-5', 
    {
      opacity: 0,
      translateY: '0em',
    }
    ,
    { 
      opacity: .17,
      translateY: '-2.5em',
      scrollTrigger: {
        trigger: '.text-container',
        start: 'top center',
      }
    });
  }

  isDesktop(){
    
    var isTouchDevice = (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
      if(isTouchDevice){
        return false;
    }
    return true;
  }
}
