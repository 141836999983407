import {Injectable} from '@angular/core'; 
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class SEOService {
  constructor(private title: Title, private meta: Meta) { }


  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url })
  }

  updateOgTitle(url: string) {
    this.meta.updateTag({ name: 'og:title', content: url })
  }

  updateOgDesc(url: string) {
    this.meta.updateTag({ name: 'og:description', content: url })
  }

  updateOgImg(url: string) {
    this.meta.updateTag({ name: 'og:image', content: url })
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }
}