import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SmoothScroll } from 'src/app/smoothscroll';
import { gsap, ScrollTrigger } from 'gsap/all';
import { Globals } from 'src/app/global';

@Component({
  selector: 'app-le-feed',
  templateUrl: './le-feed.component.html',
  styleUrls: ['./le-feed.component.scss']
})
export class LeFeedComponent implements OnInit {
  feed = [
    {
      year: 2020,
      actus: [
        {
          id: '21',
          date: '6 octobre 2020',
          title: 'Solum, light is coming',
          content: `Et la lumière fut pour Solum, marque spécialisée dans les solutions d’éclairages à LED !
          <br><br>
          Pour le lancement de la marque et de son activité, Pure a mené un accompagnement global, de la conception de packaging produit jusqu’au développement du site vitrine de la société.
          <br><br>
          Notre première mission a été de donner corps à la nouvelle marque, par la création d’un territoire d’expression cohérent avec son positionnement : des produits à la fois fiables, accessibles et beaux. Une marque agile et humaine, avec une forte expertise technique.
          <br><br>
          Et on doit vous dire qu’on est plutôt fiers du résultat ! 😊💡`
        },
        {
          id: '20',
          date: '14 septembre 2020',
          title: 'Deux nouvelles recrues chez Pure !',
          content: `Nouveau rebondissement chez Pure ! Pour la rentrée, l’équipe s’agrandit avec deux nouvelles recrues qui viennent soutenir le développement du pôle digital de l’Agence
          <br><br>
          Une intuition à toute épreuve et un regard affuté́, c’est Stéphane, notre nouveau et redoutable designer UI/UX. Quand il n’est pas en train d’imaginer votre future plateforme digitale idéale, Stéphane poursuit sa quête de la meilleure pizza de Toulouse. On vous tiendra informés de ses prochaines découvertes 🍕😉
          <br><br>
          Jules, c’est la force tranquille, intégrateur grande vitesse la journée et en première ligne sur le terrain de rugby le soir (non, les pros du web ne sont pas tous des geeks amateurs de Cosplay 🙃🙃) ! Sinon, Jules aime (beaucoup) les sneakers 👟, les punchlines du rap français et la photographie.
          <br><br>
          Bref, une rentrée sur les chapeaux de roues s’annonce pour le pôle digital, alors n’hésitez pas à nous contacter pour tous vos projets web, on se fera un plaisir d’en discuter avec vous ! 😊`
        },
        {
          id: '19',
          date: '17 août 2020',
          title: 'Pure with Anywaves',
          content: `Au printemps dernier, nous lancions l’opération #Purewithyou, pour soutenir les entreprises impactées et contribuer à la création d’un nouvel élan au sortir de l’épidémie. Aujourd’hui, nous sommes heureux de vous dévoiler le travail réalisé pour la société Anywaves, spécialisée dans la conception et le développement d’antennes satellites miniatures.
          <br><br>
          Pour cette jeune entreprise toulousaine, pionnière dans l’industrie aérospatiale, les équipes Pure signent une plateforme de marque structurante et pérenne, et la nouvelle identité visuelle de la marque. Plus audacieux, scientifique et contemporain, le nouveau territoire visuel de la marque soutient désormais les ambitions futures d’Anywaves.
          <br><br>
          Une collaboration passionnante, initiée par le hasard (ou presque !), et qui s’achève de la meilleure façon :  un client et des équipes fiers de leur travail. 😉`
        },
        {
          id: '18',
          date: '21 juillet 2020',
          title: 'Pure en pays rhodanien',
          content: `Nous sommes ravis de vous dévoiler l’une de nos dernières réalisations pour Ma P’tite Syrah, vin de pays des collines Rhodaniennes.
          <br>
          Définitivement féminin, nous avons imaginé pour cette bouteille un univers minimaliste et élégant. Une étiquette pétillante et toute en rondeur, à l’image de ces arômes en bouche !
          <br>
          À consommer avec modération bien sûr 😉
          
          `
        },
        {
          id: '17',
          date: '3 juin 2020',
          title: 'HUPPER, part. 2',
          content: `Il est grand temps de reprendre la présentation de nos dernières réalisations !
          <br><br>
          On commence aujourd’hui, avec un projet mené pour Hupper. Après un accompagnement sur le positionnement et le discours de marque, et la réalisation de l’univers visuel, nos équipes web sont intervenues dans la création et le développement du site internet d’Hupper. Outil central dans le développement de son activité, nous l’avons imaginé à la fois fluide et dynamique. Notre objectif était de créer un outil comme juste représentation de l’activité d’Hupper : un accompagnement expert et fiable, à destination d’une cible de chefs d’entreprise et de directeurs commerciaux.`
        },
        {
          id: '16',
          date: '15 mai 2020',
          title: 'Pure fait sa rentrée !',
          content: `Après 55 jours un peu particuliers de travail à la maison, de réunions ZOOM, et d’apéros virtuels, les équipes Pure ont fait leur rentrée rue des Demoiselles !
          <br><br>
          Notre retour s’est fait après quelques ajustements logistiques, pour accueillir les équipes en toute sécurité : réagencement des bureaux, distribution de masques homemade et de lotions hydroalcooliques…
          <br><br>
          Bref, on est de retour, et au point pour poursuivre les projets de nos clients, et démarrer de nouvelles collaborations ! 💪💪`
        },
        {
          id: '15',
          date: '10 avril 2020',
          title: '#PUREWITHYOU',
          content: `Par les temps qui courent, la solidarité et l’entraide nous paraissent être des valeurs plus que jamais d’actualité. Nous souhaitons nous relever les manches, et mettre au service d’une entreprise impactée par cette épreuve, nos compétences et notre savoir-faire.
          <br><br>
          C’est pourquoi l’agence Pure lance l’opération #Purewithyou. Nous nous donnons la mission de contribuer à la création d’un nouvel élan à votre société, au sortir de l’épidémie. Comment ? En vous offrant gracieusement un accompagnement, afin de booster votre communication. Concrètement, si vous êtes tiré au sort, nous interviendrons selon vos souhaits sur la création d’une nouvelle identité graphique pour votre entreprise, dans un objectif de valorisation de votre marque. Pour un retour nous l’espérons et nous le souhaitons ; sur les chapeaux de roue !
          <br><br>
          Le tirage au sort sera effectué le 26 avril. D’ici là, nous restons disponibles pour répondre à vos questions !`
        },
        {
          id: '14',
          date: '12 mars 2020',
          title: 'Cyméa, pour Icade Promotion',
          content: `Chez Pure, l’immobilier, on commence à connaître 😏
          Notre dernière réalisation est pour le groupe de promotion immobilière ICADE, et son nouveau projet d’habitations situé en parti sur les anciens locaux de la société Latécoère. Nos équipes sont intervenues sur la conception du nom, d’une signature, et sur la réalisation de la plaquette de présentation de la résidence.
          <br><br>
          Nom : CYMÉA
          <br>
          Signature : Prenez de la hauteur
          <br><br>
          Du latin « Cyma » qui signifie « cîme », le nom Cyméa fait référence à des notions de hauteur et d’élévation. Grâce à l’imaginaire de l’arbre, on invite la nature dans la ville, et faisons écho à la démarche durable, en faveur de la biodiversité que mène Icade sur ce projet.  Les consonances latines sont quant à elles, gage de qualité et de rigueur. La signature vient en soutien de ces notions d’altitude, de hauteur et d’ambition. Cyméa par Icade, c’est la promesse d’un investissement durable et sûr.`
        },
        {
          id: '13',
          date: '6 février 2020',
          title: `Lysera, dernière création pour Premium Promotion`,
          content: `L’épopée Premium Promotion continue ! On vous présente aujourd’hui notre travail de création pour la nouvelle résidence Lysera. Comme pour chaque réalisation, nous imaginons en collaboration avec Premium Promotion un univers identitaire propre.
          <br><br>
          Le nom, Lysera, a été créé comme annonciateur d’un environnement naturel foisonnant. L’identité visuelle est déclinée de la charte graphique imaginée en amont : chaleureuse, elle est révélatrice d’un cadre de vie idéal. Tous ces éléments participent à créer une identité forte, qu’on découvre avec plaisir, et surtout fidèle à la démarche et au projet imaginé par notre client.
          <br><br>
          Pour assurer continuité et cohérence, nous sommes intervenus sur la globalité de ce projet, de la création du concept créatif à la production de tous les supports de communication du programme.`
        },
        {
          id: '12',
          title: "HUPPER",
          date: "10 janvier 2020",
          content: `Chez PURE, on a un goût tout particulier pour les projets “from scratch” ! Pour Hupper, société de conseil en recrutement et management de carrières commerciales, nous avons imaginé en collaboration avec son créateur un univers de marque fort, distinctif et aussi vif que son concept. Un nom, une signature de marque, un territoire de marque, une plateforme de discours, à partir desquels nous avons développé différents supports de communication.
          <br><br>
          On doit vous dire qu’on est particulièrement fiers du résultat, et de cette nouvelle marque pleine d’ambition et de bienveillance.`,
        }
      ]
    },
    {
      year : 2019,
      actus: [
        {
          id: '9',
          title: "Esprit Traiteur : Une campagne audacieuse pour des gastronomes passionnés",
          date: "6 novembre 2019",
          content: `Entreprise familiale créée il y a plus de 50 ans, Esprit Traiteur est devenu, à force de labeur et d’une passion intarissable, leader du marché traiteur à Toulouse.
          <br><br>
          L’agence PURE a été sollicitée afin de mener un travail sur le renouvellement du positionnement de la marque Esprit Traiteur. Notre challenge a été d’arriver à laisser transparaître, à travers une marque forte et distinctive, l’identité d’Esprit Traiteur. Des gastronomes ambulants, des artisans du goût, au savoir-faire éprouvé et à la créativité débordante.
          <br><br>
          Nos équipes ont donc travailler sur le développement d’un concept fort et déclinable en une campagne institutionnelle. Des visuels au fini poli, témoin d’une haute compétence, des couleurs pop et fraîches, reflet d’un esprit novateur. Mais surtout, des expressions détournées, un ton décalé et impertinent pour placer la marque à sa très juste valeur : un partenaire d’émotions, pour une cuisine d’expression.
          <br><br>
          La campagne institutionnelle a été l’un des moyens principaux mis en place pour installer la nouvelle identité de la marque Esprit Traiteur.
          Elle a été complétée par la création de nouveaux outils print, ainsi que par la refonte de son site internet.
          <br><br>
          Un projet qui a inspiré nos équipes ! Chez PURE, on partage ce goût du second degré…`,
        },
        {
          id: '8',
          title: "Atelier cooking à l'Espace Saint Georges",
          date: "29 octobre 2019",
          content: `Actuellement en période de profonde restructuration, le Centre commercial Saint Georges doit continuer à fédérer ses clients et sa communauté, et maintenir son rôle dans la vie locale du centre-ville de Toulouse.
          Pour répondre à ces enjeux, l’agence Pure conçoit et développe des opérations innovantes et attractives.
          <br><br>
          Au mois d’octobre, c’est l’Atelier Cooking qui a pris place au cœur de la galerie. Les participants se sont affrontés sur une compétition de cake design, accompagnés par la Chef Rachel Levesque, gagnante de l’édition 2017 de l’émission Le Meilleur Pâtissier sur M6.
          Relayée sur les réseaux sociaux de l’Espace Saint Georges, l’opération a permis de développer le trafic et d’accroître la visibilité de la marque.
          <br><br>
          Une opération qui s’est rapidement transformée en un moment de convivialité, d’échange et de partage.
          Un PURE moment, qu’on vous laisse découvrir en images.`,
        },
        {
          id: '7',
          title: "Bobine et Bobinette, par Maison B",
          date: "1 octobre 2019",
          content: `La fondatrice des deux concept stores de renom à Toulouse, Bobine et Bobinette, nous a consulté pour l’accompagner dans son projet de transformation digitale. Le premier objectif de cette collaboration a été la création d’une marque ombrelle, avec laquelle puisse vivre les marques Bobine et Bobinette. Maison B a été une évidence : un concept qui englobe les activités de la maison, une identité à la fois créative et mode, comme un repère, un incontournable !
          <br><br>
          Dans un second temps, pour compléter l’offre de Maison B et aussi parce qu’aujourd’hui, une stratégie store to web est indispensable, nous avons mené le développement des sites de e-commerce Bobine et Bobinette. Toujours sous la coupe de la marque Maison B, nos équipes se sont concentrées sur la création de deux univers de vente en ligne, suffisamment cohérents et intuitifs, pouvant cohabiter sur une seule et même plateforme.
          <br><br>
          Un projet décisif dans le développement de cette entreprise toulousaine et que nous avons mené avec une bonne dose d’enthousiasme.`,
        },
        {
          id: '6',
          title: "Un pure popup",
          date: "10 juillet 2019",
          content: `Faire vivre des expériences, ressentir des émotions, continuer d’innover pour sans cesse valoriser le consommateur au centre de toutes les attentions…
          ne serait-ce pas les fondements même d’une marque telle qu’elle soit…
          <br><br>
          Nos habitudes de consommation connaissent des mutations exceptionnelles. 2019-2020 est une année de changement pour le Centre Commercial Espace Saint-Georges.
          C’est avec fierté que nous vous dévoilons aujourd’hui l’un de nos derniers projets après plusieurs mois de conception, d’élaboration et de fabrication.
          Véritable expérience shopping a part entière : Le CORNER de l’Espace Saint Georges vous permettra de découvrir l’évolution future du Centre commercial !
          Épuré et harmonieux, nos équipes l’ont réalisé à l’image du futur Centre commercial situé au cœur de la ville Rose !`,
        },
        {
          id: '5',
          title: "Alain Grand Construction",
          date: "3 juillet 2019",
          content: `Alain Grand Construction, un client qui a choisi PURE pour le développement de son image
          <br>
          Parce que nous avons toujours à cœur d’accompagner nos clients dans leurs réussites, c’est avec une grande fierté que nous avons agrandi notre portefeuille clients avec Alain Grand Construction.
          <br><br>
          Alain Grand Construction en quelques mots…
          <br><br>
          Alain Grand Construction organise et déploie son activité autour de la construction de maisons individuelles sur un marché haut de gamme.
          Après une réputation clairement identifiée grâce à la qualité de ses prestations, la société souhaite aujourd’hui déployer significativement son image de marque afin d’assurer une transition d’ordre générationnel sur le long terme.
          <br><br>
          Un nouveau positionnement pour pérenniser la marque durablement…
          <br><br>
          Parce que la transmission et le partage sont des valeurs tout particulièrement importantes pour l’agence, il était important de proposer des solutions afin d’optimiser un nouveau territoire de communication adapté aux enjeux de demain.
          <br><br>
          Nos solutions…
          <br><br>
          Il était ainsi naturel pour nous de proposer une stratégie axée sur un déploiement de l’identité et l’implantation d’un nouveau territoire de communication, au travers d’une refonte complète de la charte graphique ainsi que sur des actions de communication modernisées (plaquettes de présentation, vidéo, présence sur les réseaux sociaux…).
          <br><br>
          Attention 2020, on va casser la baraque !`,
        },
        {
          id: '4',
          title: "30 ans de Saint Martial, un Pure anniversaire !",
          date: "24 avril 2019",
          content: `Le centre commercial Saint Martial fête cette année ses 30 ans !
          <br>
          Il est normal de lui trouver un cadeau à la hauteur : De nouveaux ambassadeurs !
          <br><br>
          Ces 30 ambassadeurs ont été sélectionnés, parmi les clients du Centre commercial, par le biais d’un concours pour tenter de gagner un relooking complet !
          <br>
          Une fois sélectionnés, le Centre commercial a été aux petits soins pour eux : total look offert, coiffure sur-mesure et maquillage professionnel.
          <br>
          On a profité de l’occasion pour monter tout un studio photo/vidéo, pour les faire poser et les prendre en photo pendant ce moment magique.
          <br>
          Résultat : des visages plus souriants les uns que les autres, des visuels parfaits pour représenter le lien fort qui existe entre le Centre commercial Saint Martial et ses clients.
          <br><br>
          Ces nouveaux ambassadeurs deviendront la nouvelle image de Saint Martial.
          Tenez-vous prêt à les admirer sur les prochaines campagnes de communication du Centre commercial Saint Martial.
          <br><br>
          Plus de photos sur <a href='https://saint-martial.com' target='_blank'>saint-martial.com</a>`,
        },
        {
          id: '3',
          title: "Un Pure chantier !",
          date: "24 avril 2019",
          content: `Un des plus gros projets de l’agence, les travaux de l’Espace Saint Georges !
          <br><br>
          Il y a quelques semaines, votre centre commercial préféré a lancé le début de sa grande rénovation.
          <br>
          C’est autour de petits fours et d’un grand coup de masse que l’opération a commencé.
          <br>
          Pendant que votre Espace Saint Georges fait peau neuve, on lui a taillé un beau costume à arborer pendant sa transformation :
          <br><br>
          ► Création d’une campagne graphique, wording et visuels pour la durée des travaux,
          <br>
          ► Organisation de la Démolition Party avec toutes les parties prenantes de ce beau projet !
          <br>
          ► Création d’un espace dédié aux travaux dans le centre commercial,
          <br>
          ► Maintiens et réalisations des animations du centre commercial durant les travaux.`,
        },
        {
          id: '2',
          title: "Du nouveau chez les puristes",
          date: "27 mars 2019",
          content: `Si nos locaux s’agrandissent c’est surtout pour pouvoir faire plus de place à de nouvelles têtes !
          <br>
          Notre développement est possible grâce à vous, et votre satisfaction l’est grâce à notre équipe. Alors, pour vous proposer des idées fraîches, des designs différents, pour s’enrichir des autres et surtout pouvoir bénéficier des tarifs de groupe lors de nos sorties extra-professionnelles, nous accueillons plein de nouvelles recrues !
          <br><br>
          Bienvenue à Jérémy, notre nouveau Webdesigner, expert en tendances web, design UX (expérience utilisateur) !
          Bienvenue également à Frédéric notre intégrateur web qui vous prépare des sites super fonctionnels sans faire de bruit, il ne parle qu’en html !
          <br><br>
          Un peu en retard mais le cœur y est, bienvenue à Emilie, graphiste exceptionnelle à la fibre très arty, signature beaux-arts !
          <br><br>
          Et bienvenue aussi à Jennifer, on n’était pas bien vieux dans la team mais c’est juste pour nous rappeler qu’à 30 ans on l’est peut-être déjà un peu… Elle a seulement 20 ans mais gère ses projets comme une experte, en stage pour quelques mois, on va être triste de la voir partir… !
          Jennifer reste encore un peu, on est sympa, Yannick te fera une pizza.`,
        },
        {
          id: '1',
          title: "The body by Chris",
          date: "14 mars 2019",
          content: `Un esprit créatif sain nécessite un corps sain !
          <br><br>
          Quand Chris nous a demandé de travailler sur ses nouveaux supports institutionnels, nous avons littéralement couru sur l’occasion. Chris propose du coaching sur-mesure au sein d’un studio de remise en forme haut de gamme. Nous avions donc à cœur de réaliser des supports de communication à la hauteur des accompagnements qu’il propose, plus que du sur-mesure, nous voulions un support qui s’adresse personnellement à chaque client.
          <br><br>
          Le bleu est ici travaillé comme couleur motivante, symbole de confiance et de liberté pour un ensemble de prestations haut de gamme et l’effet métallisé vient soutenir le tout pour marquer les prestations personnalisées que propose Chris.
          <br><br>
          Nos baskets sont ready Chris, on vient pour quelle heure ?`,
        }
      ]
    }
  ]

  scrollbar;

  constructor(private _smoothScroll: SmoothScroll,
    public globals: Globals) { }

  ngOnInit(): void {
    this._smoothScroll.destroyScrollBar();
    this.scrollbar = this._smoothScroll.setSmoothScrolling();
    this.globals.globalScrollbar = this.scrollbar;
    this.globals.pageType = "scroll";
    //this.animHeader();
        
    gsap.fromTo('#scrolltop', 
    {
      opacity: 0,
    }
    ,
    { 
      opacity: 1,
      scrollTrigger: {
        trigger: "#header",
        start: "bottom top",
        toggleActions: "play none reverse reset"
      }
    });
}

animHeader(){
  var heroTl = gsap.timeline();
  heroTl.fromTo("#header", {backgroundColor: '#1d1d1d'} ,{backgroundColor: '#F6F4F1', duration: 1});
  heroTl.delay(1);
  heroTl.fromTo("h1", {opacity:0, y: 100} ,{opacity: 1, y: 0, duration: 1.5});
}
  
  scrollActus(){
    var isTouchDevice =
    (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
    if(isTouchDevice){
        document.getElementById('actus').scrollIntoView({ behavior: 'smooth' });
    }else{
      this.scrollbar.scrollIntoView(document.querySelector('#actus'), {
        offsetTop: -this.scrollbar.containerEl.scrollTop,
      });
    }
  }

  readMoreToggle(id){
    document.getElementById('actu-'+id).classList.toggle('reading');

    var isTouchDevice =
    (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
    if(isTouchDevice){
        document.getElementById('actu-'+id).scrollIntoView({ behavior: 'smooth' });
    }else{
      this.scrollbar.scrollIntoView(document.querySelector('#actu-'+id), {
        offsetTop: -this.scrollbar.containerEl.scrollTop,
      });
    }
  }

}
