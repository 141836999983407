import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-main-bt',
  templateUrl: './main-bt.component.html',
  styleUrls: ['./main-bt.component.scss']
})
export class MainBtComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }

}
