import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homepage-loader',
  templateUrl: './homepage-loader.component.html',
  styleUrls: ['./homepage-loader.component.scss']
})
export class HomepageLoaderComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    this.backgroundAnimation();
  }

  backgroundAnimation(){
    var thiObj = this;
    window.scroll({top: 0});
    document.querySelector('body').classList.add('no-overflow');
    document.querySelector('body').classList.add('hide-header');

    var bgAnimRandomizer = Array('0%', '7.14%', '14.28%', '21.42%', '28.56%', '35.7%', '42.84%', '49.98%', '57.12%', '64.26%', '71.4%', '78.54%', '85.68%', '92.82%', '100%', '107.14%');
    var currentVal = 0;

    let containerElement = <HTMLElement>document.querySelector("#homepage-loader .container");
    let bgElement = <HTMLElement>document.querySelector("#homepage-loader .background-image");

    let loop = true;

    var bgAnimation = window.setInterval(function(){
      if(currentVal > 15){
        bgElement.style.background = '#000';
        setTimeout(function(){
          containerElement.classList.add('full-screen');
          document.getElementById('cadre').classList.add('hideMe');
          setTimeout(function(){
            document.querySelector('#homepage-loader-container').remove();
            window.scroll({top: 0});
          }, 2000);
        }, 400);
        clearInterval(bgAnimation);
      }else{
        console.log(currentVal);
        document.querySelectorAll("#homepage-loader .project-name span").forEach((element) => {
          let elementTemp = <HTMLElement>element;
          elementTemp.style.display = "none";
        });
        bgElement.style.backgroundPositionX = bgAnimRandomizer[currentVal];
        let currentProjet = <HTMLElement>document.querySelector("#homepage-loader .project-name span.projet-"+currentVal++);
        currentProjet.style.display = "block";
      }
    }, 200);
  }

}
