<div id="cta-projet">
    <div class="txt">
        <span><ng-content></ng-content></span>
        <div class="trait">
            <div class="trait-anim"></div>
        </div>
    </div>
    <div class="picto">
        <svg id="picto-cta" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.16 96.16">
            <g id="fleche">
                <path id="tete_de_fleche" data-name="tete de fleche" d="M38.33,54.44,50.17,66.28,62,54.44" transform="translate(-2.1 -1.85)" fill="none" stroke="#9c974f" stroke-width="6.56"/>
                <path id="corps_de_fleche" data-name="corps de fleche" d="M50.17,33.51V66.35" transform="translate(-2.1 -1.85)" fill="none" stroke="#9c974f" stroke-width="6.56"/>
            </g>
            <circle cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#9c974f" stroke-width="2.09"/>
            <circle id="cercle-noir" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#000" stroke-width="2.09"/>
        </svg>                         
    </div>
</div>