import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-picto-fleche',
  templateUrl: './picto-fleche.component.html',
  styleUrls: ['./picto-fleche.component.scss']
})
export class PictoFlecheComponent implements OnInit {
  @Input()
  flecheC: string;
  @Input()
  cercleC: string;
  @Input()
  animC: string;
  @Input()
  animFleche: boolean;
  @Input()
  flecheD: boolean;
  @Input()
  size: string;

  
  constructor() { }

  ngOnInit(): void {
    console.log(this.size);
  }

}
