<header>
    <div class="logo-pure">
        <svg [routerLink]="['/']" id="_0_New_Logo_PURE_2020" data-name="0_New Logo PURE 2020" xmlns="http://www.w3.org/2000/svg" width="81.333" height="20.326" viewBox="0 0 81.333 20.326">
            <title>Pure</title>
            <path id="Tracé_402" data-name="Tracé 402" d="M92.066,36.407c0,3.223-1.2,4.789-3.85,4.789s-3.85-1.566-3.85-4.789V24.46H80.69v12c0,5.5,2.68,8.323,7.469,8.323s7.582-2.827,7.582-8.323v-12H92.066Z" transform="translate(-53.127 -24.46)" fill="#F6F4F1"/>
            <path id="Tracé_403" data-name="Tracé 403" d="M124.791,16.753V11.461h6.841V8.255h-6.841V3.217h9.063V0H121.11V19.959h12.857V16.753Z" transform="translate(-52.634)" fill="#F6F4F1"/>
            <path id="Tracé_404" data-name="Tracé 404" d="M0,0,11.387,19.959h4.122L4.122,0Z" fill="#F6F4F1"/>
            <path id="Tracé_405" data-name="Tracé 405" d="M56.362,24.47H48l1.843,3.211H55.95a3.166,3.166,0,1,1,0,6.332H53.467l1.843,3.195H56.4a6.37,6.37,0,0,0-.04-12.738Z" transform="translate(-38.92 -24.464)" fill="#F6F4F1"/>
            <path id="Tracé_406" data-name="Tracé 406" d="M132.544,30.848a6.253,6.253,0,0,0-6.259-6.378H118.46V44.423h3.7V37.208h2.4l3.59,7.2h4.416l-4.02-7.593a6.3,6.3,0,0,0,4-5.965Zm-6.672,3.166h-3.715V27.681H125.9a3.166,3.166,0,1,1,0,6.332Z" transform="translate(-69.542 -24.464)" fill="#F6F4F1"/>
          </svg>
    </div>
    <div class="burger-icon">
        <div class="trait trait-1"></div>
        <div class="trait trait-2"></div>
        <div class="trait trait-3"></div>
    </div>
</header>
<div class="panel-menu">
    <nav>
        <div  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/']" class="bt-menu-container menu-home">
            <button type="button" class="main-btn ">
                accueil
                <div class="trait">
                    <div class="trait-anim"></div>
                </div>
            </button>
        </div>
        <div routerLinkActive="active" [routerLink]="['/l-agence']" class="bt-menu-container menu-agence">
            <button  type="button" class="main-btn ">
                l'agence
                <div class="trait">
                    <div class="trait-anim"></div>
                </div>
            </button>
        </div>
        <div routerLinkActive="active" [routerLink]="['/references']" class="bt-menu-container menu-references">
            <button  type="button" class="main-btn ">
                références
                <div class="trait">
                    <div class="trait-anim"></div>
                </div>
            </button>
        </div>
        <div routerLinkActive="active" [routerLink]="['/le-feed']" class="bt-menu-container menu-feed">
            <button  type="button" class="main-btn">
                le feed
                <div class="trait">
                    <div class="trait-anim"></div>
                </div>
            </button>
        </div>
        <div routerLinkActive="active" [routerLink]="['/contact']" class="bt-menu-container menu-contact">
            <button  type="button" class="main-btn">
                contact
                <div class="trait">
                    <div class="trait-anim"></div>
                </div>
            </button>
        </div>
        <div class="preview-menu"></div>
    </nav>
    <div class="nav-footer">
        <div class="rs">
            <a href="https://fr-fr.facebook.com/AgenceCommunicationPure/" target="_blank">
                Facebook
            </a>
            <a href="https://www.instagram.com/agencepure/" target="_blank">
                Instagram
            </a>
            <a href="https://fr.linkedin.com/company/agence-pure" target="_blank">
                LinkedIn
            </a>
        </div>
        <div class="copyright">
            &copy; Pure - No Standards - Toulouse - Paris - 2021
        </div>
    </div>
</div>

<div [routerLink]="['/contact']" class="contact-elem">
    <span>\ Contact</span>
</div>