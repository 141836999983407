import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ContactService } from 'src/app/contact.service';
import { SmoothScroll } from 'src/app/smoothscroll';
import { gsap, ScrollTrigger } from 'gsap/all';
import { Globals } from 'src/app/global';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-candidature',
  templateUrl: './candidature.component.html',
  styleUrls: ['./candidature.component.scss']
})
export class CandidatureComponent implements OnInit, AfterViewInit {
  FormData: FormGroup;
  formSend = false;
  formError = false;
  file_data = new FormData();

  //key : 6LewBzYaAAAAADERtNuET2hFF0LedBrtEB8pAVfQ
  //secret : 6LewBzYaAAAAAA_aXLlXnMUequaBzLhK4kb3n6pk
  //key : 6LemCDYaAAAAAGgC-PLBgTK-y2U-rZXDncFpvcB9
  //secret : 6LemCDYaAAAAABGIo7vroyBf1FkzlNkQsHgyUa2F

  constructor(public http: HttpClient,
    private builder: FormBuilder,
    private contact: ContactService,
    private _smoothScroll: SmoothScroll,
    public globals: Globals) { }

  ngOnInit(): void {
    this._smoothScroll.destroyScrollBar();
    this.globals.globalScrollbar = this._smoothScroll.setSmoothScrolling();
    this.globals.pageType = "scroll";

    this.FormData = this.builder.group({
      Nom: new FormControl('', [Validators.required]),
      file: [''],
      Prenom: new FormControl('', [Validators.required]),
      Telephone: new FormControl('', [Validators.required]),
      Email : new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
      Message: new FormControl('', [Validators.required]),
      _subject: new FormControl('Nouveau message'),
      _after: new FormControl(''),
      _confirmation: new FormControl('Votre message à bien été envoyé !'),
      recaptchaReactive: new FormControl('', [Validators.required]),
      });
        
      gsap.fromTo('#scrolltop', 
      {
        opacity: 0,
      }
      ,
      { 
        opacity: 1,
        scrollTrigger: {
          trigger: "#header",
          start: "bottom top",
          toggleActions: "play none reverse reset"
        }
      });
  }

  fileChange(event) {
    const fileList: FileList = event.target.files;
    //check whether file is selected or not
    if (fileList.length > 0) {

        const file = fileList[0];
        //get file information such as name, size and type
        console.log('finfo',file.name,file.size,file.type);
        //max file size is 4 mb
        if((file.size/1048576)<=4)
        {
          let formData = new FormData();
          let info={id:2,name:'raja'}
          formData.append('file', file, file.name);
          formData.append('id','2');
          formData.append('tz',new Date().toISOString())
          formData.append('update','2')
          formData.append('info',JSON.stringify(info))
          this.file_data=formData
          
        }else{
          //this.snackBar.open('File size exceeds 4 MB. Please choose less than 4 MB','',{duration: 2000});
        }
        
    }
  }

  onSubmit(FormDataForm){
      this.formError = false;
      this.formSend = false;
  
      document.getElementById('formContact').classList.add('processing');
      this.file_data.append('Nom', FormDataForm.Nom);
      this.file_data.append('Prenom', FormDataForm.Prenom);
      this.file_data.append('Telephone', FormDataForm.Telephone);
      this.file_data.append('Email', FormDataForm.Email);
      this.file_data.append('Message', FormDataForm.Message);

    /*
    this.contact.PostMessageRecrutement(this.file_data)
      .subscribe(response => {
        console.log(response);
        if(response === 'send'){
          this.formSend = true;
          this.FormData.reset();
        }else{
          this.formError = true;
        }
      }, error => {
        this.formError = true;
      })*/
      this.http.post("https://agence-pure.com/mail-service-candidature.php",this.file_data,{responseType: 'text'})
        .subscribe(response => {
          if(response === 'send'){
            this.formSend = true;
            this.FormData.reset();
          }else{
            this.formError = true;
          }
        }, (err) => {
          this.formError = true;
      });

  }

  resolved(captchaResponse: string) {
    //console.log(`Resolved response token: ${captchaResponse}`);
  }

  ngAfterViewInit(): void {
    var thiObj = this;
    setTimeout(function(){
      thiObj.startAnimations();
    }, 500);
  }

  startAnimations(){
    var section = document.querySelector("#separateur-reunion");
    let bg = <HTMLElement>section.querySelector(".bg");
    bg.style.backgroundPosition = `50% ${-innerHeight / 2}px`;

    gsap.to(bg, {
      backgroundPosition: `50% ${innerHeight / 2}px`,
      ease: "none",
      scrollTrigger: {
        trigger: section,
        scrub: true
      }
    });
  }

}
