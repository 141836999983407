import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import { ContactService } from 'src/app/contact.service';
import { SmoothScroll } from 'src/app/smoothscroll';
import { gsap, ScrollTrigger } from 'gsap/all';
import { Globals } from 'src/app/global';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, AfterViewInit {
  FormData: FormGroup;
  formSend = false;
  formError = false;

  //key : 6LewBzYaAAAAADERtNuET2hFF0LedBrtEB8pAVfQ
  //secret : 6LewBzYaAAAAAA_aXLlXnMUequaBzLhK4kb3n6pk
  //key : 6LemCDYaAAAAAGgC-PLBgTK-y2U-rZXDncFpvcB9
  //secret : 6LemCDYaAAAAABGIo7vroyBf1FkzlNkQsHgyUa2F

  constructor(private builder: FormBuilder,
    private contact: ContactService,
    private _smoothScroll: SmoothScroll,
    public globals: Globals) { }

  ngOnInit(): void {
    this._smoothScroll.destroyScrollBar();
    this.globals.globalScrollbar = this._smoothScroll.setSmoothScrolling();
    this.globals.pageType = "scroll";

    this.FormData = this.builder.group({
      Nom: new FormControl('', [Validators.required]),
      Prenom: new FormControl('', [Validators.required]),
      Telephone: new FormControl('', [Validators.required]),
      Entreprise: new FormControl('', [Validators.required]),
      Email : new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
      Message: new FormControl('', [Validators.required]),
      recaptchaReactive: new FormControl('', [Validators.required]),
      });
        
      gsap.fromTo('#scrolltop', 
      {
        opacity: 0,
      }
      ,
      { 
        opacity: 1,
        scrollTrigger: {
          trigger: "#header",
          start: "bottom top",
          toggleActions: "play none reverse reset"
        }
      });
      this.testMail();
  }

  testMail(){
  }

  onSubmit(FormData){
    this.formError = false;
    this.formSend = false;

    document.getElementById('formContact').classList.add('processing');
    delete FormData.recaptchaReactive;

    
    this.contact.PostMessage(FormData)
      .subscribe(response => {
        if(response === 'send'){
          this.formSend = true;
          this.FormData.reset();
        }else{
          this.formError = true;
        }
      }, error => {
        this.formError = true;
      }) 
  }

  resolved(captchaResponse: string) {
    //console.log(`Resolved response token: ${captchaResponse}`);
  }

  ngAfterViewInit(): void {
    var thiObj = this;
    setTimeout(function(){
      thiObj.startAnimations();
    }, 500);
  }

  startAnimations(){
    var section = document.querySelector("#separateur-reunion");
    let bg = <HTMLElement>section.querySelector(".bg");
    bg.style.backgroundPosition = `50% ${-innerHeight / 2}px`;

    gsap.to(bg, {
      backgroundPosition: `50% ${innerHeight / 2}px`,
      ease: "none",
      scrollTrigger: {
        trigger: section,
        scrub: true
      }
    });
  }

}
