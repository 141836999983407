import { Component, OnInit, OnDestroy  } from '@angular/core';
import { gsap, ScrollTrigger, Power0 } from 'gsap/all';
import { Globals } from './global';
import { SEOService } from './seo.services';
import { SmoothScroll } from './smoothscroll';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute, RouterOutlet } from '@angular/router';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription }   from 'rxjs';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Pure';
  menuTl;
  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  constructor(private ccService: NgcCookieConsentService,
    private router: Router,
    private route: ActivatedRoute,
    private _seoService:SEOService,
    private _smoothScroll: SmoothScroll,
    public globals: Globals) {
      this.router.events.subscribe(event => {
        if(event instanceof NavigationEnd){
            gtag('config', 'UA-49809543-1', 
                  {
                    'page_path': event.urlAfterRedirects
                  }
                  );
          }
      }
    )

    gsap.registerPlugin(ScrollTrigger);
  }

  ngOnInit(): void {
  // subscribe to cookieconsent observables to react to main events
  this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
  () => {
  // you can use this.ccService.getConfig() to do stuff...
  });

  this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
  () => {
  // you can use this.ccService.getConfig() to do stuff...
  });

  this.initializeSubscription = this.ccService.initialize$.subscribe(
  (event: NgcInitializeEvent) => {
  // you can use this.ccService.getConfig() to do stuff...
  });

  this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
  (event: NgcStatusChangeEvent) => {
  // you can use this.ccService.getConfig() to do stuff...
  });

  this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
  () => {
  // you can use this.ccService.getConfig() to do stuff...
  });

  this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
  (event: NgcNoCookieLawEvent) => {
  // you can use this.ccService.getConfig() to do stuff...
  });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.route),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
    .subscribe((event) => {
      this._seoService.updateTitle(event['title']);
      this._seoService.updateOgUrl(event['ogUrl']);
      this._seoService.updateOgTitle(event['title']);
      this._seoService.updateOgDesc(event['description']);
      //Updating Description tag dynamically with title
      this._seoService.updateDescription(event['description'])
    }); 

    this.setAnimation();
    this._smoothScroll.setSmoothScrolling();
    document.querySelector(".burger-icon").addEventListener('click', event => {
      document.querySelector('header').classList.toggle('active-menu');
      if(document.querySelector("header").classList.contains('active-menu')){
        document.querySelector('.panel-menu').classList.add('preventPointer');
        this.menuTl.resume();
      }else{
        this.menuTl.progress(0).pause();
      }
    });
  }

  changeOfRoutes(){
    if(document.querySelector("header").classList.contains('active-menu')){
      document.querySelector('header').classList.remove('active-menu');
      this.menuTl.progress(0).pause();
    }
    this.animHeaderElem();
  }

  animHeaderElem(){
    var headerTl = gsap.timeline();
    headerTl.delay(1);
    headerTl.fromTo("h1:not(.ignore)", {yPercent: 100}, {yPercent: 0, duration: .4, ease:"none"});
    headerTl.fromTo("#formContact", {opacity: 0}, {opacity: 1, duration: .4, ease:"none"});
    headerTl.fromTo(".logo-pure", {opacity: 0}, {opacity: 1, duration: .4, ease:"none"});
    headerTl.fromTo(".contact-elem", {opacity: 0}, {opacity: 1, duration: .4, ease:"none"});
    headerTl.fromTo(".scroll-bottom", {opacity: 0}, {opacity: 1, duration: .4, ease:"none"});
    headerTl.fromTo(".burger-icon .trait-1", {xPercent: 100}, {xPercent: 1, duration: .3, ease:"none"});
    headerTl.fromTo(".burger-icon .trait-2", {xPercent: 100}, {xPercent: 1, duration: .3, ease:"none"}, "-=.1");
    headerTl.fromTo(".burger-icon .trait-3", {xPercent: 100}, {xPercent: 1, duration: .3, ease:"none"}, "-=.1");
  }

  setAnimation(){
    this.menuTl = gsap.timeline();
    this.menuTl.fromTo(".panel-menu .menu-home button", {yPercent: 100}, {yPercent:0, duration: .4, ease:"none"},"+=.4");
    this.menuTl.fromTo(".panel-menu .menu-agence button", {yPercent: 100}, {yPercent:0, duration: .4, ease:"none"},"-=.2");
    this.menuTl.fromTo(".panel-menu .menu-references button", {yPercent: 100}, {yPercent:0, duration: .4, ease:"none"},"-=.2");
    this.menuTl.fromTo(".panel-menu .menu-feed button", {yPercent: 100}, {yPercent:0, duration: .4, ease:"none"},"-=.2");
    this.menuTl.fromTo(".panel-menu .menu-contact button", {yPercent: 100}, {yPercent:0, duration: .4, ease:"none"},"-=.2");
    this.menuTl.fromTo(".panel-menu .nav-footer", {opacity: 0}, {opacity: 1, duration: .4, ease:"none"},"-=.2");
    this.menuTl.fromTo(".panel-menu .preview-menu", {opacity: 0}, {opacity: 1, duration: .2, ease:"none"});
    this.menuTl.add( function(){ document.querySelector('.panel-menu').classList.remove('preventPointer'); } );
    this.menuTl.pause();
  }

  scrollTopEvent(){
    console.log(typeof this.globals.globalScrollbar);
    
    if(this.globals.pageType === "fullpage"){
      this.globals.globalScrollbar.moveTo('top');
    }
    if(this.globals.pageType === "scroll"){
      var isTouchDevice =
      (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0));
      if(isTouchDevice){
          document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
      }else{
        this.globals.globalScrollbar.scrollIntoView(document.querySelector('#header'), {
          offsetTop: -this.globals.globalScrollbar.containerEl.scrollTop,
        });
      }
    }
  }
  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}
