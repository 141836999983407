import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private api = 'https://agence-pure.com/mail-service.php';
  private apiR = 'https://mail-service.agence-pure.com/mail-service-candidature.php';

  constructor(private http: HttpClient) { }

  PostMessage(input: any){
    return this.http.post(this.api, input, {responseType: 'text'}).pipe(
      map(
        (response) => {
          if(response){
            return response;
          }
        },
        (error: any) => {
          return error;
        }
      )
    )
  }

  oldPostMessageRecrutement(input: any){
    const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
    return this.http.post(this.apiR, input, {headers, responseType: 'text'}).pipe(
      map(
        (response) => {
          if(response){
            return response;
          }
        },
        (error: any) => {
          return error;
        }
      )
    )
  }

  PostMessageRecrutement(input: any)
    {
      this.http.post(this.apiR+'upload-file.php',input)
      .subscribe(res => {
        return res;
      }, (err) => {
        return err;
    });
    }
  
}
