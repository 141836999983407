import { Reference } from './references';
/*

id: number;
slug: string;
metaDesc: '',
                shortName: string;
shortDesc: string;
fullName: string;
quote: string;
content: string;

*/
export const REFERENCES: Reference[] = [
    {
        category: 'Services',
        slug: 'services',
        projet: [
            //Groupe Mounès
            {
                nextRefSlug: 'culo',
                nextRefName: 'Culo',
                nextRefCat: 'services',
                slug: 'groupe-mounes',
                name: 'Groupe Mounès',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne le Groupe Mounès, concessionnaire automobile, dans sa stratégie de marque et sa stratégie digitale.',
                shortName: 'Groupe Mounès',
                shortDesc: `Accélérateur de la mobilité `,
                fullName: 'Groupe Mounès',
                quote: `Accélérateur de la mobilité`,
                content: `Concessionnaire automobile créé en 1970, le Groupe Mounès entreprend en 2019 un repositionnement stratégique important. Après plus de 50 ans en mono marque, le groupe souhaite désormais devenir un acteur multimarque incontournable de la mobilité sur la région Occitanie. 
                <br><br>
                Pour soutenir cette nouvelle ambition de développement, nos équipes ont accompagné le groupe dans la création et le déploiement de la marque Groupe Mounès : indépendante, au service de la mobilité de ses clients, et experte automobile. Après une analyse minutieuse du marché et de ses perspectives d’évolution, nos équipes ont travaillé sur un positionnement différenciant, précurseur et unique. Celui de partenaire d’auto-mobilité. Un logotype, un territoire visuel a également été imaginé puis décliné en supports de la marque. `,
                lineSkill: 'Audit de l’existant – Étude de marché & data – Planning stratégique – Naming & signature de marque – Logotype & identité visuelle – Conception rédaction – Supports de marque',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'groupe-mounes-row-1-img-1.jpg',
                                alt: 'Groupe Mounès logo before / after',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'mounes-new-row.jpg',
                                alt: 'Groupe Mounès',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'groupe-mounes-row-2-img-1.jpg',
                                alt: 'Groupe Mounès identité graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'groupe-mounes-row-3-img-1.jpg',
                                alt: 'Groupe Mounès charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'groupe-mounes-row-4-img-1.jpg',
                                alt: 'Groupe Mounès mockup magasine',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'groupe-mounes-row-5-img-1.jpg',
                                alt: 'Groupe Mounès construction logo',
                            },
                            {
                                url: 'groupe-mounes-row-5-img-2.jpg',
                                alt: 'Groupe Mounès logo',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'groupe-mounes-row-6-img-1.jpg',
                                alt: 'Groupe Mounès identité graphique',
                            },
                            {
                                url: 'groupe-mounes-row-6-img-2.jpg',
                                alt: 'Groupe Mounès identité graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'groupe-mounes-row-7-img-1.jpg',
                                alt: 'Groupe Mounès',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'groupe-mounes-row-8-img-1.jpg',
                                alt: 'Groupe Mounès',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'groupe-mounes-row-9-img-1.jpg',
                                alt: 'Groupe Mounès photographie',
                            },
                            {
                                url: 'groupe-mounes-row-9-img-2.jpg',
                                alt: 'Groupe Mounès photographie',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'groupe-mounes-row-10-img-1.jpg',
                                alt: 'Groupe Mounès',
                            },
                            {
                                url: 'groupe-mounes-row-10-img-2.jpg',
                                alt: 'Groupe Mounès',
                            }
                        ]
                    }
                ]        
            },
            //Culo
            {
                nextRefSlug: 'atelier-2',
                nextRefName: 'Atelier 2',
                nextRefCat: 'services',
                slug: 'culo',
                name: 'Culo',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Culo, créateur d’objets d’arts de la table, dans sa stratégie de marque.',
                shortName: 'Culo',
                shortDesc: `Surcyclé en France`,
                fullName: 'Culo',
                quote: `Surcyclé en France`,
                content: `Culo, c’est un concept innovant d’ucycling, permettant de donner une seconde vie aux déchets en de verre. La démarche en faveur des Hommes et de la planète se transforme en art, sans se prendre au sérieux. Pour Culo, nos équipes ont imaginé et créé l’univers digital de la marque à travers une plateforme de e-commerce. Nos experts UX/UI se sont attachés à construire un parcours client fluide et intuitif, tandis que notre équipe créative s’est amusée à dérouler le fil rouge de cette identité ludique et unique.
                <br><br>
                <a href="https://www.culo.fr/" target="_blank" class="cas-client-decouvrir-site">Découvrir le site</a>`,
                lineSkill: 'Audit – Analyse data – Ux/Ui Design – Intégration – Développement',
                csm: true,
                bc: false,
                sip: false,
                edition: false,
                sod: true,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'culo-row-1-img-1.jpg',
                                alt: 'Culo site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'culo-row-2-img-1.jpg',
                                alt: 'Culo',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'culo-row-3-img-1.jpg',
                                alt: 'Photo produit Culo',
                            },
                            {
                                url: 'culo-row-3-img-2.jpg',
                                alt: 'Culo iPad site',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'culo-row-4-img-1.jpg',
                                alt: 'Culo palette couleur',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'culo-row-5-img-1.jpg',
                                alt: 'Culo site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'culo-row-6-img-1.jpg',
                                alt: 'Culo site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'culo-row-7-img-1.jpg',
                                alt: 'Culo',
                            }
                        ]
                    },
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'culo-row-8-img-1.jpg',
                                alt: 'Culo',
                            },
                            {
                                url: 'culo-row-8-img-2.jpg',
                                alt: 'Culo',
                            },
                            {
                                url: 'culo-row-8-img-3.jpg',
                                alt: 'Culo vase',
                            }
                        ]
                    }
                ]        
            },
            //Atelier 2
            {
                nextRefSlug: 'maison-b',
                nextRefName: 'Maison B',
                nextRefCat: 'services',
                slug: 'atelier-2',
                name: 'Atelier 2',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Atelier 2, cabinet d’architecture, dans son brand content et sa stratégie digitale.',
                shortName: 'Atelier 2',
                shortDesc: `Rêver, dessiner, imaginer, construire`,
                fullName: 'Atelier 2',
                quote: `Rêver, dessiner, imaginer, construire`,
                content: `Créé par Aurore Dehez, Atelier 2 est un cabinet d’architecture, d’architecture d’intérieur, de décoration et de photographie basé à Bayonne. Atelier 2 met au service de ses clients son sens de l’esthétisme pour créer des univers à la fois singuliers et affirmés. L’atelier 2 a confié à nos équipes la refonte de son site internet : entre sensibilité et regard aiguisé, Pure a entrepris la création d’un univers digital cohérent, fidèle à la marque, ses valeurs, son identité. 
                <br><br>
                Pour le développement du nouveau site internet Atelier 2, un soin particulier a été apporté à l’expérience et au parcours utilisateur : le visiteur est en immersion au cœur de l’univers de la marque.
                <br><br>
                <a href="https://latelier2.archi/" target="_blank" class="cas-client-decouvrir-site">Découvrir le site</a>`,
                lineSkill: 'Audit de l’existant – Positionnement – Logotype & identité visuelle – Plateforme de discours – Conception rédaction – UX/UI – Développement',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'atelier-2-row-1-img-1.jpg',
                                alt: 'Atelier 2',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'atelier-2-row-2-img-1.jpg',
                                alt: 'Atelier 2 charte graphique',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'atelier-2-row-3-img-1.jpg',
                                alt: 'Atelier 2 logo',
                            },
                            {
                                url: 'atelier-2-row-3-img-2.jpg',
                                alt: 'Atelier 2',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'atelier-2-row-4-img-1.jpg',
                                alt: 'Atelier 2 photographie',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'atelier-2-row-5-img-1.jpg',
                                alt: 'Atelier 2 site internet',
                            },
                            {
                                url: 'atelier-2-row-5-img-2.jpg',
                                alt: 'Atelier 2 logo',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'atelier-2-row-6-img-1.jpg',
                                alt: 'Atelier 2 carte de visite',
                            },
                            {
                                url: 'atelier-2-row-6-img-2.jpg',
                                alt: 'Atelier 2 tampon',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'atelier-2-row-7-img-1.jpg',
                                alt: 'Atelier 2 site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'atelier-2-row-8-img-1.jpg',
                                alt: 'Atelier 2 photographie',
                            }
                        ]
                    },
                    {
                        type: "col-left",
                        images: [
                            {
                                url: 'atelier-2-row-9-img-1.jpg',
                                alt: 'Atelier 2 site internet',
                            },
                            {
                                url: 'atelier-2-row-9-img-2.jpg',
                                alt: 'Atelier 2 site internet tablette',
                            },
                            {
                                url: 'atelier-2-row-9-img-3.jpg',
                                alt: 'Atelier 2',
                            }
                        ]
                    }
                ]        
            },
            //Maison B
            {
                nextRefSlug: 'struktura',
                nextRefName: 'Struktura',
                nextRefCat: 'services',
                slug: 'maison-b',
                name: 'Maison B',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Maison B, concept store, dans sa stratégie de marque et sa stratégie digitale.',
                shortName: 'Maison B',
                shortDesc: `Bobinne & Bobinette`,
                fullName: 'Maison B',
                quote: `Bobinne & Bobinette`,
                content: `Entrepreneuse ambitieuse et précurseuse de nouvelles tendances, nous avons accompagné la fondatrice des concept stores toulousains Bobine et Bobinette, dans le déploiement online de son activité commerciale. Notre premier objectif a été la création d’une marque ombrelle, pour structurer et uniformiser un écosystème de marques filles déjà existant. La marque ombrelle Maison B a alors été imaginée, comme un label, une maison de mode, capable de porter les deux univers distincts mais cohérents de Bobine et Bobinette. 
                <br><br>
                Sous la coupe de la marque maison B, nos équipes sont intervenues dans le développement et le déploiement d’une solution e-commerce pour les concept stores Bobine et Bobinette. Sur une seule et même plateforme cohabitent les deux univers de marque. De concept stores indépendants et autonomes, nous passons à une logique globalisée, celle d’une maison de mode “à la française”, offrant une attractivité et une réassurance nouvelle à ses clients.
                <br><br>
                <a href="https://maison-b.fr/" target="_blank" class="cas-client-decouvrir-site">Découvrir le site</a>`,
                lineSkill: 'Architecture de marque – Positionnement – Logotype et identité visuelle – UI/UX – Développement',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'maison-b-row-1-img-1.jpg',
                                alt: 'Maison B logo',
                            },
                            {
                                url: 'maison-b-row-1-img-2.jpg',
                                alt: 'Maison B bobine et bobinette',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'maison-b-row-2-img-1.jpg',
                                alt: 'Maison B tee-shirt',
                            },
                            {
                                url: 'maison-b-row-2-img-2.jpg',
                                alt: 'Maison B sac',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'maison-b-row-3-img-1.jpg',
                                alt: 'Maison B charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'maison-b-row-4-img-1.jpg',
                                alt: 'Maison B mockup PC',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'maison-b-row-5-img-1.jpg',
                                alt: 'Maison B',
                            },
                            {
                                url: 'maison-b-row-5-img-2.jpg',
                                alt: 'Maison B',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'maison-b-row-6-img-1.jpg',
                                alt: 'Maison B Bobinette',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'maison-b-row-7-img-1.jpg',
                                alt: 'Maison B carte de visite',
                            },
                            {
                                url: 'maison-b-row-7-img-2.jpg',
                                alt: 'Maison B mockup pc',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'maison-b-row-8-img-1.jpg',
                                alt: 'Maison B',
                            },
                            {
                                url: 'maison-b-row-8-img-2.jpg',
                                alt: 'Maison B',
                            }
                        ]
                    }
                ]        
            },
            //Struktura
            {
                nextRefSlug: 'solum',
                nextRefName: 'Solum',
                nextRefCat: 'services',
                slug: 'struktura',
                name: 'Struktura',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Struktura Design, agence de design et d’architecture intérieure, dans sa stratégie de marque.',
                shortName: 'Struktura',
                shortDesc: `Home life design`,
                fullName: 'Struktura',
                quote: `Home life design`,
                content: `Struktura Design est une agence de design et d’architecture d’intérieur basée à Toulouse. Elle est spécialisée dans la création, l’agencement et la rénovation d’espaces sur-mesure. Souffrant d’un déficit d’image, et d’un manque de cohérence avec l’expertise qu’elle propose à ses clients, la société Struktura Design confie à nos équipes la refonte du site internet de la marque. 
                <br><br>
                Pure a imaginé un univers décliné du logotype et de la charte existante, capable de refléter l’ADN de l’entreprise. Struktura Design cultive la différenciation et la passion pour son métier. Nos équipes ont pris en charge l’architecture, le design l’intégration et la mise en ligne du nouveau site, dans le respect des délais imposés.
                <br><br>
                <a href="https://www.struktura-design.com/" target="_blank" class="cas-client-decouvrir-site">Découvrir le site</a>`,
                lineSkill: 'identité visuelle – Conception rédaction – UX/UI – Développement',
                csm: true,
                bc: false,
                sip: false,
                edition: false,
                sod: true,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'struktura-row-1-img-1.jpg',
                                alt: 'Struktura logo et identité graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'struktura-row-2-img-1.jpg',
                                alt: 'Struktura logo',
                            }
                        ]
                    },
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'struktura-row-3-img-1.jpg',
                                alt: 'Struktura site internet',
                            },
                            {
                                url: 'struktura-row-3-img-2.jpg',
                                alt: 'Struktura site internet',
                            },
                            {
                                url: 'struktura-row-3-img-3.jpg',
                                alt: 'Struktura site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'struktura-row-4-img-1.jpg',
                                alt: 'Struktura charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'struktura-row-5-img-1.jpg',
                                alt: 'Struktura site internet',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'struktura-row-6-img-1.jpg',
                                alt: 'Struktura site internet',
                            },
                            {
                                url: 'struktura-row-6-img-2.jpg',
                                alt: 'Struktura site internet',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'struktura-row-7-img-1.jpg',
                                alt: 'Struktura illustration',
                            },
                            {
                                url: 'struktura-row-7-img-2.jpg',
                                alt: 'Struktura photographie',
                            }
                        ]
                    }
                ]        
            },
            //Solum
            {
                nextRefSlug: 'hupper',
                nextRefName: 'Hupper',
                nextRefCat: 'services',
                slug: 'solum',
                name: 'Solum',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Solum, marque spécialisée dans l’éclairage, dans son brand content et sa stratégie digitale.',
                shortName: 'Solum',
                shortDesc: `Light is coming`,
                fullName: 'Solum',
                quote: `Light is coming`,
                content: `Solum est une marque spécialisée dans les solutions d’éclairages fonctionnels à LED, imaginées et conçues dans le sud-ouest de la France. Pour le lancement de la marque et de son activité, Pure a mené un accompagnement global, de la conception de packaging produit jusqu’au développement du site vitrine de la société. 
                <br><br>
                Notre première mission a été de de donner corps à la marque, avec la création d’un territoire d’expression cohérent avec son positionnement : des produits à la fois fiables, accessibles et beaux. Une marque agile et humaine, avec une forte expertise technique.
                <br><br>
                <a href="https://solum.fr/" target="_blank" class="cas-client-decouvrir-site">Découvrir le site</a>`,
                lineSkill: 'Étude de marché et data – Planning stratégique – Positionnement – Plateforme de de marque – Logotype & identité visuelle – Naming & signature de marque - Plateforme de discours – UX/UI – Développement',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'solum-row-1-img-1.jpg',
                                alt: 'Solum logo before after',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'solum-row-2-img-1.jpg',
                                alt: 'Solum logo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'solum-row-3-img-1.jpg',
                                alt: 'Solum charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'solum-row-4-img-1.jpg',
                                alt: 'Solum mockup site internet',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'solum-row-5-img-1.jpg',
                                alt: 'Solum mockup site internet',
                            },
                            {
                                url: 'solum-row-5-img-2.jpg',
                                alt: 'Solum photographie',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'solum-row-6-img-1.jpg',
                                alt: 'Solum photographie',
                            }
                        ]
                    },
                    {
                        type: "col-left",
                        images: [
                            {
                                url: 'solum-row-7-img-1.jpg',
                                alt: 'Solum packaging',
                            },
                            {
                                url: 'solum-row-7-img-2.jpg',
                                alt: 'Solum packaging',
                            },
                            {
                                url: 'solum-row-7-img-3.jpg',
                                alt: 'Solum dépliant',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'solum-row-8-img-1.jpg',
                                alt: 'Solum identité graphique',
                            }
                        ]
                    }
                ]        
            },
            //Hupper
            {
                nextRefSlug: 'portelli',
                nextRefName: 'Portelli',
                nextRefCat: 'services',
                slug: 'hupper',
                name: 'Hupper',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Hupper, entreprise spécialisée dans le développement commercial, dans sa stratégie de marque.',
                shortName: 'Hupper',
                shortDesc: `Conseil et management de stratégies commerciales`,
                fullName: 'Hupper',
                quote: `Conseil et management de stratégies commerciales`,
                content: `Hupper est une entreprise spécialisée dans le conseil et le management de stratégies commerciales, à destination de grands groupes, tous secteurs confondus. L’agence Pure a accompagné son fondateur dans chacune des étapes de la création de l’entreprise : de la définition de la marque, de son positionnement et de son identité, jusqu’au développement 
                des outils de communication print et digitaux. 
                <br><br>
                Nos équipes ont imaginé un nom, Hupper, et une signature « Ambition commune ». Un univers capable de porter les valeurs d’expertise, d’agilité et de performance, dans un format innovant, se rapprochant de l’univers des start-ups dans le domaine de l’innovation. Une identité forte, qui impose la marque comme un acteur référent auprès de ses clients. 
                Nos équipes sont également intervenues dans la création et le développement du site internet Hupper, et des différents outils de communication de la marque.
                <br><br>
                <a href="https://www.hupper.fr" target="_blank" class="cas-client-decouvrir-site">Découvrir le site</a>`,
                lineSkill: 'Étude de marché et data – Planning stratégique – Positionnement – Plateforme de de marque – Logotype & identité visuelle – Naming & signature de marque - Plateforme de discours – UX/UI – Développement',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'hupper-row-1-img-1.jpg',
                                alt: 'Hupper logo',
                            },
                            {
                                url: 'hupper-row-1-img-2.jpg',
                                alt: 'Hupper logo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'hupper-row-2-img-1.jpg',
                                alt: 'Hupper identité graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'hupper-row-3-img-1.jpg',
                                alt: 'Hupper charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'hupper-row-4-img-1.jpg',
                                alt: 'Hupper site internet mobile',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'hupper-row-5-img-1.jpg',
                                alt: 'Hupper logo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'hupper-row-6-img-1.jpg',
                                alt: 'Hupper mockup PC',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'hupper-row-7-img-1.jpg',
                                alt: 'Hupper',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'hupper-row-8-img-1.jpg',
                                alt: 'Hupper logo',
                            },
                            {
                                url: 'hupper-row-8-img-2.jpg',
                                alt: 'Hupper logo et picto',
                            }
                        ]
                    }
                ]        
            },
            //Portelli
            {
                nextRefSlug: 'mas-tolosa',
                nextRefName: 'Mas Tolosa',
                nextRefCat: 'services',
                slug: 'portelli',
                name: 'Portelli',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Portelli, pisciniste et paysagiste, dans sa stratégie de marque et stratégie digitale.',
                shortName: 'Portelli',
                shortDesc: `Créateur d’espaces de vie extérieurs`,
                fullName: 'Portelli',
                quote: `Créateur d’espaces de vie extérieurs`,
                content: `Portelli est une entreprise référente dans la création de piscines sur-mesure et l’aménagement paysager sur Toulouse et sa région. Si le bouche à oreille reste le premier levier de notoriété de l’activité, Portelli souhaite amorcer sa transformation digitale, et renforcer sa présence sur le web. 
                <br><br>
                Les équipes Pure ont pris en charge la réorganisation de l’écosystème digitale de la marque, avec comme objectifs l’amélioration du parcours et de l’expérience utilisateur, et la complémentarité des plateformes digitales. Pour cette raison nous avons entrepris la refonte du site internet et du territoire visuel de la marque, ainsi que la création et le déploiement d’une stratégie social media.
                <br><br>
                <a href="https://portelli.com/" target="_blank" class="cas-client-decouvrir-site">Découvrir le site</a>`,
                lineSkill: 'Audit de l’existant – Étude de marché & data – Naming & signature de marque – Logotype & identité visuelle – Conception rédaction - UX/UI – Développement – Référencement – Social media management',
                csm: true,
                bc: false,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'portelli-row-1-img-1.jpg',
                                alt: 'Portelli mockup site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'portelli-row-2-img-1.jpg',
                                alt: 'Portelli charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'portelli-row-3-img-1.jpg',
                                alt: 'Portelli picine extérieur',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'portelli-row-4-img-1.jpg',
                                alt: 'Portelli site mobile',
                            },
                            {
                                url: 'portelli-row-4-img-2.jpg',
                                alt: 'Portelli site mobile',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'portelli-row-5-img-1.jpg',
                                alt: 'Portelli',
                            }
                        ]
                    },
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'portelli-row-6-img-1.jpg',
                                alt: 'Portelli',
                            },
                            {
                                url: 'portelli-row-6-img-2.jpg',
                                alt: 'Portelli',
                            },
                            {
                                url: 'portelli-row-6-img-3.jpg',
                                alt: 'Portelli',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'portelli-row-7-img-1.jpg',
                                alt: 'Portelli picine interieur',
                            }
                        ]
                    }
                ]        
            },
            //Mas Tolosa
            {
                nextRefSlug: 'eurostat',
                nextRefName: 'Eurostat',
                nextRefCat: 'services',
                slug: 'mas-tolosa',
                name: 'Mas Tolosa',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Mas Tolosa, lieu de réception pour évènements, dans sa stratégie de marque et sa stratégie digitale.',
                shortName: 'Mas Tolosa',
                shortDesc: `Lieu de réception pour événements particuliers, professionnels, 
                mariages, journées d’études…`,
                fullName: 'Mas Tolosa',
                quote: `Lieu de réception pour événements particuliers, professionnels, 
                mariages, journées d’études…`,
                content: `Le Mas Tolosa est un lieu de réception unique pour événements professionnels et particuliers. Repère d’exception située en périphérie toulousaine, le Mas Tolosa confie à Pure la refonte de son site internet, première vitrine du domaine.  Pour ce projet, l’enjeu est d’offrir une première « visite virtuelle », qui s’imprègne de l’atmosphère authentique, conviviale et humaine du Mas Tolosa. Nos équipes ont imaginé un univers épuré, élégant, offrant une vraie capacité de projet pour les prospects. L’objectif est également de transmettre les valeurs de proximité, d’adaptabilité et de passion qui anime la gestion du lieu et des réceptions y prenant place.`,
                lineSkill: 'Audit de l’existant – UX/UI – Développement web – Référencement',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: false,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'mas-tolosa-row-1-img-1.jpg',
                                alt: 'Mockup Site internet Mas Tolosa',
                            }
                        ]
                    },
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'mas-tolosa-row-2-img-1.jpg',
                                alt: 'Vue aérienne Mas Tolosa',
                            },
                            {
                                url: 'mas-tolosa-row-2-img-2.jpg',
                                alt: 'Mockup site internet mobile Mas Tolosa',
                            },
                            {
                                url: 'mas-tolosa-row-2-img-3.jpg',
                                alt: 'Mas Tolosa espace coco',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'mas-tolosa-row-3-img-1.jpg',
                                alt: 'Mas Tolosa charte graphique',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'mas-tolosa-row-4-img-1.jpg',
                                alt: 'Mockup site internet Mas Tolosa',
                            },
                            {
                                url: 'mas-tolosa-row-4-img-2.jpg',
                                alt: 'Mockup site internet mobile Mas Tolosa',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'mas-tolosa-row-5-img-1.jpg',
                                alt: 'Espace Coco Mas Tolosa',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'mas-tolosa-row-6-img-1.jpg',
                                alt: 'Mas Tolosa mockup site page Coco',
                            }
                        ]
                    },
                    {
                        type: "col-left",
                        images: [
                            {
                                url: 'mas-tolosa-row-7-img-1.jpg',
                                alt: 'Photographie table vue de haut',
                            },
                            {
                                url: 'mas-tolosa-row-7-img-2.jpg',
                                alt: 'Photographie intérieur Mas Tolosa',
                            },
                            {
                                url: 'mas-tolosa-row-7-img-3.jpg',
                                alt: 'Photographie intérieur Mas Tolosa',
                            }
                        ]
                    }
                ]     
            },
            //Eurostat
            {
                nextRefSlug: 'la-cerise',
                nextRefName: 'La Cerise',
                nextRefCat: 'services',
                slug: 'eurostat',
                name: 'Eurostat',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Eurostat, spécialiste des solutions de protection aux ESD, dans le graphisme et l’évènementiel.',
                shortName: 'Eurostat',
                shortDesc: `Solution globale de protection aux ESD`,
                fullName: 'Eurostat',
                quote: `Solution globale de protection aux ESD`,
                content: `Eurostat est le spécialiste français des solutions globales de protection aux ESD (décharges électrostatiques) à destination des industriels. Nos équipes ont accompagné Eurostat dans la création et la production de supports commerciaux d’aide à la vente. Notre intervention s’est poursuivie sur le développement d’outils événementiels (stand et supports de communication) pour les salons professionnels spécialisés.`,
                lineSkill: 'Audit de l’existant – UX/UI – Développement web – Référencement',
                csm: false,
                bc: false,
                sip: false,
                edition: true,
                sod: false,
                sms: false,
                event: true,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'eurostat-row-1-img-1.jpg',
                                alt: 'Logo Eurostat couleur',
                            },
                            {
                                url: 'eurostat-row-1-img-2.jpg',
                                alt: 'Logo Eurostat blanc',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'eurostat-row-2-img-1.jpg',
                                alt: 'Charte graphique Eurostat',
                            }
                        ]
                    },
                    {
                        type: "col-left",
                        images: [
                            {
                                url: 'eurostat-row-3-img-1.jpg',
                                alt: 'Eurostat magasine',
                            },
                            {
                                url: 'eurostat-row-3-img-2.jpg',
                                alt: 'Eurostat magasine',
                            },
                            {
                                url: 'eurostat-row-3-img-3.jpg',
                                alt: 'Eurostat charte police',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'eurostat-row-4-img-1.jpg',
                                alt: 'Eurostat',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'eurostat-row-5-img-1.jpg',
                                alt: 'Eurostat',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'eurostat-row-6-img-1.jpg',
                                alt: 'Eurostat magasine',
                            },
                            {
                                url: 'eurostat-row-6-img-2.jpg',
                                alt: 'Eurostat magasine',
                            }
                        ]
                    },
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'eurostat-row-7-img-1.jpg',
                                alt: 'Eurostat magasine',
                            },
                            {
                                url: 'eurostat-row-7-img-2.jpg',
                                alt: 'Eurostat magasine',
                            },
                            {
                                url: 'eurostat-row-7-img-3.jpg',
                                alt: 'Eurostat magasine',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'eurostat-row-8-img-1.jpg',
                                alt: 'Eurostat',
                            }
                        ]
                    }
                ]  
            },
            //La Cerise
            {
                nextRefSlug: 'resineo',
                nextRefName: 'Résineo',
                nextRefCat: 'services',
                slug: 'la-cerise',
                name: 'La Cerise',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne La Cerise, agence de communication spécialisée en relations médias, sur la refonte de son identité.',
                shortName: 'La Cerise',
                shortDesc: `Relations médias & rédaction de contenu`,
                fullName: 'La Cerise',
                quote: `Relations médias & rédaction de contenu`,
                content: `Agence de communication spécialisée en relation médias et relations publics, La Cerise a mandaté Pure pour la refonte de son site internet. Nos équipes ont mené une réflexion autour d’une déclinaison de l’identité de la société, et la création d’une plateforme digitale capable de soutenir son discours expert et son offre de conseil et d’accompagnement. Pour ancrer son positionnement dans le temps, nos équipes ont également mené la création d’un brandbook, outil indispensable pour sceller le territoire d’expression de la marque. `,
                lineSkill: 'Positionnement – plateforme de marque – Identité visuelle – UX/UI design – Développement – Supports de marque',
                csm: true,
                bc: true,
                sip: false,
                edition: true,
                sod: true,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'la-cerise-row-1-img-1.jpg',
                                alt: 'La Cerise logo',
                            },
                            {
                                url: 'la-cerise-row-1-img-2.jpg',
                                alt: 'La Cerise logo blanc',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'la-cerise-row-2-img-1.jpg',
                                alt: 'La Cerise charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'la-cerise-row-3-img-1.jpg',
                                alt: 'La Cerise mockup Le Book',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'la-cerise-row-4-img-1.jpg',
                                alt: 'La Cerise mockup site internet',
                            },
                            {
                                url: 'la-cerise-row-4-img-2.jpg',
                                alt: 'La Cerise mockup Le Book',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'la-cerise-row-5-img-1.jpg',
                                alt: 'La Cerise photographie',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'la-cerise-row-6-img-1.jpg',
                                alt: 'La Cerise mockup web',
                            }
                        ]
                    },
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'la-cerise-row-7-img-1.jpg',
                                alt: 'La Cerise',
                            },
                            {
                                url: 'la-cerise-row-7-img-2.jpg',
                                alt: 'La Cerise',
                            },
                            {
                                url: 'la-cerise-row-7-img-3.jpg',
                                alt: 'La Cerise',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'la-cerise-row-8-img-1.jpg',
                                alt: 'La Cerise',
                            }
                        ]
                    }
                ]     
            },
            //Resineo
            {
                nextRefSlug: 'ye-organisation',
                nextRefName: 'Ye Organisation',
                nextRefCat: 'services',
                slug: 'resineo',
                name: 'Résineo',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Résinéo, fabriquant de résines industrielles, dans sa stratégie de marque et la conception graphique.',
                shortName: 'Résineo',
                shortDesc: `Revêtement drainant de sols`,
                fullName: 'Résineo',
                quote: `Revêtement drainant de sols`,
                content: `Fabriquant toulousain de résines industrielles de sol, et marque du Groupe LR Vision, la société Résinéo est un acteur pionnier et spécialiste du revêtement de sol. Labélisés par la French Tech, c’est en 2019, que l’entreprise entreprend la refonte de l’intégralité de ses supports de marque.
                <br><br>
                Nos équipes sont intervenues dans le déploiement des outils print, de la conception-rédaction, jusqu’à la création exclusivement en interne des illustrations, permettant de soutenir le discours commercial des solutions proposées par Résinéo. Des créations résolument modernes et dynamiques, des discours courts et impactant, avec l’objectif d’afficher visiblement le positionnement innovant de la marque.`,
                lineSkill: 'Conception rédaction – Supports de marque – Production – illustrations ',
                csm: true,
                bc: false,
                sip: false,
                edition: true,
                sod: false,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'resineo-row-1-img-1.jpg',
                                alt: 'Resineo logo',
                            },
                            {
                                url: 'resineo-row-1-img-2.jpg',
                                alt: 'Resineo logo blanc',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'resineo-row-2-img-1.jpg',
                                alt: 'Resineo mockup',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'resineo-row-3-img-1.jpg',
                                alt: 'Resineo charte graphique',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'resineo-row-4-img-1.jpg',
                                alt: 'Resineo mockup magasine',
                            },
                            {
                                url: 'resineo-row-4-img-2.jpg',
                                alt: 'Resineo mockup magasine',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'resineo-row-5-img-1.jpg',
                                alt: 'Resineo logo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'resineo-row-6-img-1.jpg',
                                alt: 'Resineo',
                            }
                        ]
                    },
                    {
                        type: "col-left",
                        images: [
                            {
                                url: 'resineo-row-7-img-1.jpg',
                                alt: 'Resineo',
                            },
                            {
                                url: 'resineo-row-7-img-2.jpg',
                                alt: 'Resineo',
                            },
                            {
                                url: 'resineo-row-7-img-3.jpg',
                                alt: 'Resineo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'resineo-row-8-img-1.jpg',
                                alt: 'Resineo',
                            }
                        ]
                    }
                ]        
            },
            //Ye Organisation
            {
                nextRefSlug: 'ace',
                nextRefName: 'ACE',
                nextRefCat: 'services',
                slug: 'ye-organisation',
                name: 'Ye Organisation',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Ye Organisation, agence événementielle, dans sa stratégie digitale.',
                shortName: 'Ye Organisation',
                shortDesc: `Agence conseil en Evénementiel, innovation création A à Z `,
                fullName: 'Ye Organisation',
                quote: `Agence conseil en Evénementiel, innovation création A à Z `,
                content: `YE Organisation est une agence de communication événementielle plaçant la création au cœur de sa démarche globale.
                <br><br>
                L’agence a confié à nos équipes la conception et le déploiement de son site internet. Nos équipes ont imaginé une plateforme au parcours utilisateur simplifié, valorisant l’approche créative, parfois artistique de la marque. Un univers à la fois audacieux et minimaliste, à l’image de son expertise métier.
                <br><br>
                <a href="https://agence-ye.fr/" target="_blank" class="cas-client-decouvrir-site">Découvrir le site</a>`,
                lineSkill: 'Audit de l’existant – logotype et identité visuelle – UX/UI design – développement ',
                csm: true,
                bc: false,
                sip: false,
                edition: false,
                sod: true,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'ye-organisation-row-1-img-1.jpg',
                                alt: 'Ye Organisation mockup site internet',
                            },
                            {
                                url: 'ye-organisation-row-1-img-2.jpg',
                                alt: 'Ye Organisation mockup site tablette',
                            },
                            {
                                url: 'ye-organisation-row-1-img-3.jpg',
                                alt: 'Ye Organisation site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ye-organisation-row-2-img-1.jpg',
                                alt: 'Ye Organisation charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ye-organisation-row-3-img-1.png',
                                alt: 'Ye Organisation logo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ye-organisation-row-4-img-1.jpg',
                                alt: 'Ye Organisation',
                            }
                        ]
                    },
                    {
                        type: "col-left",
                        images: [
                            {
                                url: 'ye-organisation-row-5-img-1.jpg',
                                alt: 'Ye Organisation site internet',
                            },
                            {
                                url: 'ye-organisation-row-5-img-2.jpg',
                                alt: 'Ye Organisation site mobile',
                            },
                            {
                                url: 'ye-organisation-row-5-img-3.jpg',
                                alt: 'Ye Organisation',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ye-organisation-row-6-img-1.jpg',
                                alt: 'Ye Organisation',
                            }
                        ]
                    }
                ]        
            },
            //ACE
            {
                nextRefSlug: 'ccl',
                nextRefName: 'CCL',
                nextRefCat: 'services',
                slug: 'ace',
                name: 'ACE',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Ace, société événementielle, dans la création d’une identité de marque et d’un site web.',
                shortName: 'ACE',
                shortDesc: `Voyages sur-mesure`,
                fullName: 'ACE',
                quote: `Voyages sur-mesure`,
                content: `Société événementielle basée à Toulouse, ACE conçoit et développe des voyages, événements, et séminaires sur-mesure pour ses clients. Avec un concept produit fort et une solide expertise sur ces métiers, ACE a mandaté l’Agence Pure dans la conception et la création de son territoire de marque, et le déploiement de sa stratégie de communication. 
                <br><br>
                Nos équipes sont intervenues dans la création d’un univers identitaire : un logotype, un territoire graphique et une signature. Dans la continuité de sa mission, l’Agence Pure a accompagné ACE dans la création et le développement du site internet de la marque, ainsi que l’élaboration de ses supports de communication print.
                <br><br>
                <a href="https://acevents.co/" target="_blank" class="cas-client-decouvrir-site">Découvrir le site</a>`,
                lineSkill: 'Étude de marché et data – Planning stratégique – Positionnement – Plateforme de de marque – Logotype & identité visuelle – Naming & signature de marque - Plateforme de discours – UX/UI – Développement',
                csm: true,
                bc: false,
                sip: false,
                edition: true,
                sod: true,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'ace-row-1-img-1.jpg',
                                alt: 'ACE mockup dépliant',
                            },
                            {
                                url: 'ace-row-1-img-2.jpg',
                                alt: 'ACE logo',
                            },
                            {
                                url: 'ace-row-1-img-3.jpg',
                                alt: 'ACE kakemono',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ace-row-2-img-1.jpg',
                                alt: 'ACE mockup dépliant',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ace-row-3-img-1.jpg',
                                alt: 'ACE logo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ace-row-4-img-1.jpg',
                                alt: 'ACE site internet',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'ace-row-5-img-1.jpg',
                                alt: 'ACE identité graphique',
                            },
                            {
                                url: 'ace-row-5-img-2.jpg',
                                alt: 'ACE site internet',
                            }
                        ]
                    }
                ]        
            },
            //CCL
            {
                nextRefSlug: 'locaforce',
                nextRefName: 'Locaforce',
                nextRefCat: 'services',
                slug: 'ccl',
                name: 'CCL',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne le Comptoir Commercial du Languedoc (CCL), dans sa stratégie graphique et digitale.',
                shortName: 'CCL',
                shortDesc: `Comptoir Commercial du Languedoc`,
                fullName: 'CCL',
                quote: `Comptoir Commercial du Languedoc`,
                content: `Le Comptoir Commercial du Languedoc (CCL) organise annuellement un salon professionnel nommé Unibat : un événement rassemblant 3 500 personnes, de tous les corps de métier liés à la construction et au bâtiment. Afin de soutenir le lancement et le succès de l’édition 2018 du salon, Pure s’est vu confier la communication digitale de la rencontre, avec notamment la création d’un site internet événementiel, ainsi que tous l’ensemble des supports prints et digitaux dédiés. En amont, nos équipes ont travaillé sur la création d’un territoire graphique déclinable, afin d’offrir à la marque Unibat une identité unique.`,
                lineSkill: 'Création & production évènementielle – UX/UI design – développement – Supports de marque',
                csm: true,
                bc: false,
                sip: false,
                edition: true,
                sod: true,
                sms: false,
                event: true,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ccl-row-1-img-1.jpg',
                                alt: 'CCL espace diagora',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ccl-row-2-img-1.jpg',
                                alt: 'CCL',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ccl-row-3-img-1.jpg',
                                alt: 'CCL charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ccl-row-4-img-1.jpg',
                                alt: 'CCL Salon unibat par CCL',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'ccl-row-5-img-1.jpg',
                                alt: 'CCL photographie',
                            },
                            {
                                url: 'ccl-row-5-img-2.jpg',
                                alt: 'CCL Salon unibat par CCL',
                            }
                        ]
                    }
                ]        
            },
            //Locaforce
            {
                nextRefSlug: 'm2-fabrications-publicitaires',
                nextRefName: 'M2 Fabrications Publicitaires',
                nextRefCat: 'services',
                slug: 'locaforce',
                name: 'Locaforce',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Locaforce, spécialiste de la location de véhicules, dans la création de son site web.',
                shortName: 'Locaforce',
                shortDesc: `Louez Malin, Louez Toulousain !`,
                fullName: 'Locaforce',
                quote: `Louez Malin, Louez Toulousain !`,
                content: `Depuis 26 ans, Locaforce se positionne comme le spécialiste de la location sur la région Midi-Pyrénées, pour les particuliers et les professionnels. Avec plus de 15 ans d’expérience, Locaforce profite d’un savoir-faire reconnu sur son marché, et entretient avec ses clients une relation de confiance. 
                <br><br>
                Avec l’arrivée de nouvelles offres de service, Locaforce a fait appel à Pure pour mener la refonte de son site internet. Nos équipes ont entrepris le projet dans sa globalité, et repensé l’expérience utilisateur lors de la navigation. Pure est intervenu sur le design, l’intégration et la mise en ligne du nouveau site, dans le respect des délais imposés.
                <br><br>
                <a href="https://locaforce.fr/" target="_blank" class="cas-client-decouvrir-site">Découvrir le site</a>`,
                lineSkill: 'identité visuelle – Conception rédaction – UX/UI – Développement',
                csm: true,
                bc: false,
                sip: false,
                edition: false,
                sod: true,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'locaforce-row-1-img-1.jpg',
                                alt: 'Locaforce logo et mockup mobile',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'locaforce-row-2-img-1.jpg',
                                alt: 'Locaforce charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'locaforce-row-3-img-1.png',
                                alt: 'Locaforce mockup site internet',
                            }
                        ]
                    },
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'locaforce-row-4-img-1.jpg',
                                alt: 'Locaforce site internet tablette',
                            },
                            {
                                url: 'locaforce-row-4-img-2.jpg',
                                alt: 'Locaforce site internet',
                            },
                            {
                                url: 'locaforce-row-4-img-3.jpg',
                                alt: 'Locaforce logo',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'locaforce-row-5-img-1.jpg',
                                alt: 'Locaforce',
                            },
                            {
                                url: 'locaforce-row-5-img-2.jpg',
                                alt: 'Locaforce site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'locaforce-row-6-img-1.jpg',
                                alt: 'Locaforce illustration',
                            }
                        ]
                    }
                ]        
            },
            //M2 Fabrication publicitaire
            {
                nextRefSlug: 'oceatech',
                nextRefName: 'Oceatech',
                nextRefCat: 'services',
                slug: 'm2-fabrications-publicitaires',
                name: 'M2 Fabrications<br>Publicitaires',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne M2 Fabrications Publicitaires, plateforme de conception de supports publicitaires, dans la refonte de son site.',
                shortName: 'M2 Fabrications Publicitaires',
                shortDesc: `Fabrications publicitaires`,
                fullName: 'M2 Fabrications Publicitaires',
                quote: `Fabrications publicitaires`,
                content: `Leader français de la conception, de la fabrication et du suivi logistique de supports de communication imprimés depuis plus de 20 ans, M2FP a sollicité nos équipes pour la refonte de son identité de marque. L’objectif ? Valoriser l’expérience de la marque sur son secteur, grâce à une image traduisant stature, expertise, et proactivité. Alors que les modes de consommation des professionnels évoluent, 
                <br><br>
                Pure a également mené la refonte du site internet d’M2FP, avec pour objectifs l’optimisation du parcours client, ainsi que le développement des ventes via les supports digitaux de la marque.
                <br><br>
                <a href="https://www.m2fp.com/" target="_blank" class="cas-client-decouvrir-site">Découvrir le site</a>`,
                lineSkill: 'Audit de l’existant – Étude de marché et data – Positionnement – Logotype & identité visuelle – Naming & signature de marque – UX/UI – Développement – Supports de marque',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'm2-fabrication-publicitaire-row-1-img-1.jpg',
                                alt: 'M2 Fabrication publicitaire logo before after',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'eurom2-fabrication-publicitairestat-row-2-img-1.jpg',
                                alt: 'M2 Fabrication publicitaire',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'm2-fabrication-publicitaire-row-3-img-1.jpg',
                                alt: 'M2 Fabrication publicitaire charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'm2-fabrication-publicitaire-row-4-img-1.jpg',
                                alt: 'M2 Fabrication publicitaire',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'm2-fabrication-publicitaire-row-5-img-1.jpg',
                                alt: 'M2 Fabrication publicitaire',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'm2-fabrication-publicitaire-row-6-img-1.jpg',
                                alt: 'M2 Fabrication publicitaire site internet',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'm2-fabrication-publicitaire-row-7-img-1.jpg',
                                alt: 'M2 Fabrication publicitaire logo',
                            },
                            {
                                url: 'm2-fabrication-publicitaire-row-7-img-2.jpg',
                                alt: 'M2 Fabrication publicitaire logo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'm2-fabrication-publicitaire-row-8-img-1.jpg',
                                alt: 'M2 Fabrication publicitaire site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'm2-fabrication-publicitaire-row-9-img-1.jpg',
                                alt: 'M2 Fabrication publicitaire identité graphique',
                            }
                        ]
                    }
                ]        
            },
            //Oceatech
            {
                nextRefSlug: 'vinci-autoroutes',
                nextRefName: 'Vinci Autoroutes',
                nextRefCat: 'institution-et-territoire',
                slug: 'oceatech',
                name: 'Oceatech',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Oceatech, société spécialisée pour les professionnels de santé, dans sa stratégie événementielle.',
                shortName: 'Oceatech',
                shortDesc: 'IT Médicale',
                fullName: 'Oceatech',
                quote: "IT Médicale",
                content: `Société spécialisée dans les équipements et services médicaux pour les professionnels de santé, Oceatech fêtait en 2018 ses 20 ans d’existence. 
                <br><br>
                À cette occasion, Pure s’est vu confier l’organisation la célébration de cette seconde décennie de succès pour le groupe. Nos équipes ont imaginé deux soirées intimistes et élégantes, dans un restaurant gastronomique étoilé. `,
                lineSkill: 'Création et production événementielle – Supports de marque',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: false,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'oceatech-row-1-img-1.jpg',
                                alt: 'Oceatech',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'oceatech-row-2-img-1.jpg',
                                alt: 'Oceatech',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'oceatech-row-3-img-1.jpg',
                                alt: 'Oceatech',
                            },
                            {
                                url: 'oceatech-row-3-img-2.jpg',
                                alt: 'Oceatech',
                            }
                        ]
                    },
                    {
                        type: "col-left",
                        images: [
                            {
                                url: 'oceatech-row-4-img-1.jpg',
                                alt: 'Oceatech',
                            },
                            {
                                url: 'oceatech-row-4-img-2.jpg',
                                alt: 'Oceatech',
                            },
                            {
                                url: 'oceatech-row-4-img-3.jpg',
                                alt: 'Oceatech',
                            }
                        ]
                    }
                ]        
            }
        ]
    },
    {
        category: 'Institutions & Territoires',
        slug: 'institution-et-territoire',
        projet: [
            //Vinci autoroutes
            {
                nextRefSlug: 'ariegez-vous',
                nextRefName: 'Ariègez-vous',
                nextRefCat: 'institution-et-territoire',
                slug: 'vinci-autoroutes',
                name: 'Vinci<br>Autoroutes',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Vinci Autoroutes dans la création de supports graphiques à l’occasion de l’élargissement d’une autoroute.',
                shortName: 'Vinci Autoroutes',
                shortDesc: 'Vinci Autoroutes vous donne de la voie !',
                fullName: 'Vinci Autoroutes',
                quote: "Vinci Autoroutes vous donne de la voie !",
                content: `Dans le cadre de l’élargissement de l’autoroute A10 mené par la société Vinci Autoroutes, Pure accompagne le groupe dans le déploiement d’un dispositif global qui s’étend sur 3 ans, avec pour objectif l’information et la sensibilisation au public sur un projet d’envergure régionale. 
                <br><br>
                Pour accompagner ceux-ci à travers la transformation du paysage autoroutier, nos équipes ont imaginé un plan de communication global avec la création de supports dédiés. Parmi ceux-ci, un magazine d’information baptisé « Les coulisses de l’autoroute A61 » à destination des institutions et parties prenantes au projet.  
                <br><br>
                Définition de la charte éditoriale, création des contenus, conduite d’interviews, réalisation d’illustrations et création de la charte graphique sont autant d’expertises déployées pour concevoir des supports cohérents, durables et utiles.`,
                lineSkill: 'Conception rédaction – Supports de marque – Illustration – Production ',
                csm: true,
                bc: true,
                sip: true,
                edition: true,
                sod: false,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'vinci-autoroute-row-1-img-1.jpg',
                                alt: 'Vinci Autoroute',
                            },
                            {
                                url: 'vinci-autoroute-row-1-img-2.jpg',
                                alt: 'Vinci Autoroute',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'vinci-autoroute-row-2-img-1.jpg',
                                alt: 'Vinci Autoroute',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'vinci-autoroute-row-3-img-1.jpg',
                                alt: 'Vinci Autoroute',
                            }
                        ]
                    },
                    {
                        type: "col-left",
                        images: [
                            {
                                url: 'vinci-autoroute-row-4-img-1.jpg',
                                alt: 'Vinci Autoroute',
                            },
                            {
                                url: 'vinci-autoroute-row-4-img-2.jpg',
                                alt: 'Vinci Autoroute',
                            },
                            {
                                url: 'vinci-autoroute-row-4-img-3.jpg',
                                alt: 'Vinci Autoroute',
                            }
                        ]
                    },
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'vinci-autoroute-row-5-img-1.jpg',
                                alt: 'Vinci Autoroute',
                            },
                            {
                                url: 'vinci-autoroute-row-5-img-2.jpg',
                                alt: 'Vinci Autoroute',
                            },
                            {
                                url: 'vinci-autoroute-row-5-img-3.jpg',
                                alt: 'Vinci Autoroute',
                            }
                        ]
                    }
                ]        
            },
            //Ariègez vous
            {
                nextRefSlug: 'ariege-tourisme',
                nextRefName: 'Ariège Tourisme',
                nextRefCat: 'institution-et-territoire',
                slug: 'ariegez-vous',
                name: 'Ariègez-<br>vous',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Ariège Tourisme, office de tourisme de l’Ariège, dans sa stratégie événementielle pour une opération.',
                shortName: 'Ariègez-vous',
                shortDesc: 'Ariège Tourisme',
                fullName: 'Ariègez-vous !',
                quote: "Ariège Tourisme",
                content: `Afin de promouvoir les multiples richesses touristiques du territoire de l’Ariège auprès des Montpelliérains, Pure a accompagné l’Office du Tourisme afin d’imaginer un événement ludique, offrant une nouvelle visibilité à la destination. 
                <br><br>
                Nos équipes ont imaginé un événement sur-mesure, autour des destinations touristiques clés du département. C’est alors qu’a été créé l’Escape Game de l’Ariège : une animation grandeur nature, vivante et attrayante, capable de susciter la notoriété et l’engagement auprès des cibles visées. `,
                lineSkill: 'Création et production événementielle – Scénographie – Brand content – Supports de marque',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: false,
                sms: false,
                event: true,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'ariegez-vous-row-1-img-1.jpg',
                                alt: 'Ariègez-vous',
                            },
                            {
                                url: 'ariegez-vous-row-1-img-2.jpg',
                                alt: 'Ariègez-vous',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ariegez-vous-row-2-img-1.jpg',
                                alt: 'Ariègez-vous',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ariegez-vous-row-3-img-1.jpg',
                                alt: 'Ariègez-vous',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'ariegez-vous-row-4-img-1.jpg',
                                alt: 'Ariègez-vous',
                            },
                            {
                                url: 'ariegez-vous-row-4-img-2.jpg',
                                alt: 'Ariègez-vous',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ariegez-vous-row-5-img-1.jpg',
                                alt: 'Ariègez-vous',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'ariegez-vous-row-6-img-1.jpg',
                                alt: 'Ariègez-vous',
                            },
                            {
                                url: 'ariegez-vous-row-6-img-2.jpg',
                                alt: 'Ariègez-vous',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'ariegez-vous-row-7-img-1.jpg',
                                alt: 'Ariègez-vous',
                            },
                            {
                                url: 'ariegez-vous-row-7-img-2.jpg',
                                alt: 'Ariègez-vous',
                            }
                        ]
                    }
                ]        
            },
            //Ariege tourisme
            {
                nextRefSlug: 'gourette',
                nextRefName: 'Gourette',
                nextRefCat: 'institution-et-territoire',
                slug: 'ariege-tourisme',
                name: 'Ariège<br>Tourisme',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Ariège Tourisme dans sa stratégie événementielle à l’occasion du Salon de l’Agriculture.',
                shortName: 'Ariège Tourisme',
                shortDesc: 'Salon de l’Agriculture 2020',
                fullName: 'Ariège Tourisme',
                quote: "Salon de l’Agriculture 2020",
                content: `À l’occasion du Salon annuel de l’Agriculture 2020 à Paris, le département de l’Ariège confie à Pure l’animation de son stand. L’objectif est de valoriser l’Ariège comme destination touristique, à travers son patrimoine, ses paysages et son terroir. L’animation doit répondre avec cohérence à l’univers de la marque et sa signature. 
                <br><br>
                En réponse, nos équipes ont imaginé un concept d’animation, capable de capter l’attention des visiteurs, promouvoir l’Ariège comme une destination touristique française incontournable, mais également de répondre à des objectifs marketing tels que la collecte de date et l’embasement client sur le stand. `,
                lineSkill: 'Création et production événementielle – Scénographie – Brand content – Supports de marque',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: false,
                sms: false,
                event: true,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ariege-tourisme-row-1-img-1.jpg',
                                alt: 'Ariège Tourisme',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ariege-tourisme-row-2-img-1.jpg',
                                alt: 'Ariège Tourisme',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'ariege-tourisme-row-3-img-1.jpg',
                                alt: 'Ariège Tourisme',
                            },
                            {
                                url: 'ariege-tourisme-row-3-img-2.jpg',
                                alt: 'Ariège Tourisme',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ariege-tourisme-row-4-img-1.jpg',
                                alt: 'Ariège Tourisme',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ariege-tourisme-row-5-img-1.jpg',
                                alt: 'Ariège Tourisme',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'ariege-tourisme-row-6-img-1.jpg',
                                alt: 'Ariège Tourisme',
                            },
                            {
                                url: 'ariege-tourisme-row-6-img-2.jpg',
                                alt: 'Ariège Tourisme',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'ariege-tourisme-row-7-img-1.jpg',
                                alt: 'Ariège Tourisme',
                            },
                            {
                                url: 'ariege-tourisme-row-7-img-2.jpg',
                                alt: 'Ariège Tourisme',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ariege-tourisme-row-8-img-1.jpg',
                                alt: 'Ariège Tourisme',
                            }
                        ]
                    }
                ]        
            },
            //Gourette
            {
                nextRefSlug: 'icade-promotion',
                nextRefName: 'Icade Promotion',
                nextRefCat: 'immobilier',
                slug: 'gourette',
                name: 'Gourette',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Gourette, station de ski, dans sa stratégie de communication globale.',
                shortName: 'Gourette',
                shortDesc: 'Une dose de montagne',
                fullName: 'Gourette',
                quote: "Une dose de montagne",
                content: `Destination touristique familiale des Pyrénées, la station de ski Gourette Eaux-Bonnes a mandaté l’Agence Pure pour un accompagnement global de sa stratégie de communication. Souffrant d’un déficit de notoriété et d’image, les équipes de l’Agence Pure ont travaillé sur des moyens et supports de communication permettant d’asseoir le positionnement de la marque, et d’affirmer son identité. 
                <br><br>
                Station vacancière dynamique et familiale, nous sommes intervenus pour la marque Gourette sur l’élaboration d’un plan de communication online et offline. La conception et la création d’une campagne publicitaire a permis à la marque de s’ériger comme une destination incontournable de la région Sud-Ouest. 
                <br><br>
                Un concept décalé et ludique, décliné sur différents médias on et offline, afin d’alimenter le discours de la marque. “Besoin d’une dose de montagne” devient alors la ligne éditoriale de la marque, et le fil conducteur d’une websérie dédiée. `,
                lineSkill: 'Positionnement – Plateforme de discours – Brand content – Concept publicitaire – Campagne media & hors media – Social media management ',
                csm: true,
                bc: true,
                sip: true,
                edition: true,
                sod: true,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'gourette-row-1-img-1.jpg',
                                alt: 'Station Gourette',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'gourette-row-2-img-1.jpg',
                                alt: 'Station Gourette',
                            },
                            {
                                url: 'gourette-row-2-img-2.jpg',
                                alt: 'Station Gourette',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'gourette-row-3-img-1.jpg',
                                alt: 'Station Gourette',
                            }
                        ]
                    },
                    {
                        type: "col-left",
                        images: [
                            {
                                url: 'gourette-row-4-img-1.jpg',
                                alt: 'Station Gourette',
                            },
                            {
                                url: 'gourette-row-4-img-2.jpg',
                                alt: 'Station Gourette',
                            },
                            {
                                url: 'gourette-row-4-img-3.jpg',
                                alt: 'Station Gourette',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'gourette-row-5-img-1.jpg',
                                alt: 'Station Gourette',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'gourette-row-6-img-1.jpg',
                                alt: 'Station Gourette',
                            }
                        ]
                    }
                ]        
            }
        ]
    },
    {
        category: 'Immobilier',
        slug: 'immobilier',
        projet: [
            //Icade
            {
                nextRefSlug: 'premium-promotion',
                nextRefName: 'Premium Promotion',
                nextRefCat: 'immobilier',
                slug: 'icade-promotion',
                name: 'Icade Promotion',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Icade Promotion, opérateur immobilier, dans son brand content et sa stratégie d’image et de publicité.',
                shortName: 'Icade Promotion',
                shortDesc: 'L’immobilier de tous vos futurs',
                fullName: 'Icade Promotion',
                quote: "L’immobilier de tous vos futurs",
                content: `Foncière, développeur et promoteur, Icade est un opérateur immobilier leader en France, qui conçoit des produits et des services immobiliers innovants, afin de répondre aux nouveaux usages et modes de vie urbains.
                <br><br>
                Dans le cadre de lancements de programmes, Icade Promotion a sollicité nos équipes pour la conception et le déploiement des supports destinés à la commercialisation de leurs résidences. En réponses, nous avons mené notre réflexion autour de supports adaptés à chacun de leurs produits et de leur besoin. Nous effectuons une recherche fondamentale, gage de qualité et de ROI pour notre client.`,
                lineSkill: 'Conseil & stratégie de marque – Stratégie d’image et publicité – Édition',
                csm: false,
                bc: true,
                sip: true,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'icade-row-1-img-1.jpg',
                                alt: 'Icade logo fond blanc',
                            },
                            {
                                url: 'icade-row-1-img-2.jpg',
                                alt: 'Icade logo fond violet',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'icade-row-2-img-1.jpg',
                                alt: 'Icade carte de visite',
                            },
                            {
                                url: 'icade-row-2-img-2.jpg',
                                alt: 'Icade dépliant',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'icade-row-3-img-1.jpg',
                                alt: 'Icade charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'icade-row-4-img-1.jpg',
                                alt: 'Icade illustration',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'icade-row-5-img-1.jpg',
                                alt: 'Icade palissade',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'icade-row-6-img-1.jpg',
                                alt: 'Icade',
                            },
                            {
                                url: 'icade-row-6-img-2.jpg',
                                alt: 'Icade',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'icade-row-7-img-1.jpg',
                                alt: 'Icade salon expo',
                            },
                            {
                                url: 'icade-row-7-img-2.jpg',
                                alt: 'Icade perspective immeuble',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'icade-row-8-img-1.jpg',
                                alt: 'Icade affiche abris bus',
                            }
                        ]
                    }
                ]        
            },
            //Premium Promotion
            {
                nextRefSlug: 'eiffage',
                nextRefName: 'Eiffage',
                nextRefCat: 'immobilier',
                slug: 'premium-promotion',
                name: 'Premium<br>Promotion',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Premium Promotion, promoteur immobilier, dans son brand content et sa stratégie digitale.',
                shortName: 'Premium Promotion',
                shortDesc: 'Un nouvel art de vivre',
                fullName: 'Premium Promotion',
                quote: "Un nouvel art de vivre",
                content: `Acteur majeur du secteur de la promotion immobilière sur la région toulousaine, et plus récemment en Ile-de-France, Pure accompagne Premium Promotion dans le déploiement de son activité auprès de ses publics. Afin de soutenir ses ambitions de développement, nous intervenons dans la création d’une identité de marque pérenne, capable d’incarner la vision, et la force de Premium Promotion sur son secteur. 
                <br><br>
                Pour Premium Promotion, l’art, le design et l’architecture sont autant de moyens mis en œuvre pour offrir aux habitants une vraie qualité d’usage. En réponse à cette démarche, et pour assurer la promotion des réalisations de Premium Promotion, nous avons imaginé un principe de “collection” haut-de-gamme. Chaque programme immobilier est interprété par un nom et un univers visuel. Les supports sont traités avec continuité, comme de vrais écrins. Chaque détail souligne la démarche qualitative et esthétique de Premium Promotion, et valorise son approche inédite de l’immobilier.
                <br><br>
                <a href="https://premium-promotion.fr/" target="_blank" class="cas-client-decouvrir-site">Découvrir le site</a>`,
                lineSkill: 'Conseil et stratégie de de marque – stratégie d’image et publicité  – Édition – Stratégie et outils digitaux – Social media strategy – évènementiel',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'premium-promotion-row-1-img-1.jpg',
                                alt: 'Premium Promotion logo noir',
                            },
                            {
                                url: 'premium-promotion-row-1-img-2.jpg',
                                alt: 'Premium Promotion logo blanc',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'premium-promotion-row-2-img-1.jpg',
                                alt: 'Premium Promotion',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'premium-promotion-row-3-img-1.jpg',
                                alt: 'Premium Promotion charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'premium-promotion-row-4-img-1.jpg',
                                alt: 'Premium Promotion',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'premium-promotion-row-5-img-1.jpg',
                                alt: 'Premium Promotion',
                            }
                        ]
                    },/*
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'premium-promotion-row-6-img-1.jpg',
                                alt: 'Premium Promotion art',
                            },
                            {
                                url: 'premium-promotion-row-6-img-2.jpg',
                                alt: 'Premium Promotion artiste sculpteur',
                            }
                        ]
                    },*/
                    {
                        type: "full",
                        images: [
                            {
                                url: 'premium-promotion-row-7-img-1.jpg',
                                alt: 'Premium Promotion programmes',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'premium-promotion-row-8-img-1.jpg',
                                alt: 'Premium Promotion',
                            },
                            {
                                url: 'premium-promotion-row-8-img-2.jpg',
                                alt: 'Premium Promotion',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'premium-promotion-row-9-img-1.jpg',
                                alt: 'Premium Promotion',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'premium-promotion-row-10-img-1.jpg',
                                alt: 'Premium Promotion site internet',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'premium-promotion-row-11-img-1.jpg',
                                alt: 'Premium Promotion plaquette programme',
                            },
                            {
                                url: 'premium-promotion-row-11-img-2.jpg',
                                alt: 'Premium Promotion',
                            }
                        ]
                    }
                ]        
            },
            //Eiffage
            {
                nextRefSlug: 'grand-construction',
                nextRefName: 'Grand Construction',
                nextRefCat: 'immobilier',
                slug: 'eiffage',
                name: 'Eiffage',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Eiffage, groupe de construction, dans sa stratégie de communication interne.',
                shortName: 'Eiffage',
                shortDesc: 'Groupe de construction et de concessions français depuis 1993',
                fullName: 'Eiffage',
                quote: "Groupe de construction et de concessions français depuis 1993",
                content: `Pure accompagne la division régionale Occitanie du groupe Eiffage Construction dans une problématique de communication interne : favoriser la rencontre entre les différents pôles métier du site, pour soutenir la création d’une vraie cohésion au sein du groupe. 
                <br><br>
                Pour y parvenir, nos équipes ont développé un outil ludique permettant l’échange entre secteurs d’activité. Pour soutenir le lancement de cet instrument, nos équipes sont également intervenues sur la mise en place d’un plan de communication interne autour de ce nouvel outil. `,
                lineSkill: 'Conseil & stratégie de marque – Stratégie d’image et publicité - Brand content – Édition',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'eiffage-row-1-img-1.jpg',
                                alt: 'Eiffage',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'eiffage-row-2-img-1.jpg',
                                alt: 'Eiffage charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'eiffage-row-3-img-1.jpg',
                                alt: 'Eiffage affiche',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'eiffage-row-4-img-1.jpg',
                                alt: 'Eiffage perspective immeuble',
                            },
                            {
                                url: 'eiffage-row-4-img-2.jpg',
                                alt: 'Eiffage',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'eiffage-row-5-img-1.jpg',
                                alt: 'Eiffage plaquette',
                            },
                            {
                                url: 'eiffage-row-5-img-2.jpg',
                                alt: 'Eiffage plaquette',
                            }
                        ]
                    }
                ]        
            },
            //Grand Construction
            {
                nextRefSlug: 'place-de-l-immo',
                nextRefName: 'Place de l\'immo',
                nextRefCat: 'immobilier',
                slug: 'grand-construction',
                name: 'Grand<br>Construction',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Grand Construction, constructeur immobilier, dans sa stratégie de marque.',
                shortName: 'Grand Construction',
                shortDesc: 'La seule mesure à prendre',
                fullName: 'Grand Construction',
                quote: "La seule mesure à prendre",
                content: `Alain Grand Construction organise et déploie son activité autour de la construction de maisons individuelles sur un marché haut de gamme en région Occitanie. 
                <br><br>
                Après une réputation clairement identifiée grâce à la qualité de ses prestations, la société souhaite aujourd’hui déployer sa notoriété et son expertise, afin d’assurer la pérennité et le dynamisme de l’entreprise. Pure a accompagné Alain Grand Construction dans la définition d’une stratégie de marque adaptée aux futurs enjeux de la société. L’affirmation du positionnement rare de la marque a été l’un des piliers de la démarche de nos équipes. Celle-ci passe par une refonte du territoire visuel de la marque, mettant en perspective les valeurs centrales de la marque : le luxe contenu et le savoir-faire.`,
                lineSkill: 'Conseil et stratégie de marque – édition – stratégie et outils digitaux',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'grand-construction-row-1-img-1.jpg',
                                alt: 'Grand Construction',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'grand-construction-row-2-img-1.jpg',
                                alt: 'Grand Construction logo before after',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'grand-construction-row-3-img-1.jpg',
                                alt: 'Grand Construction charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'grand-construction-row-4-img-1.jpg',
                                alt: 'Grand Construction identité graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'grand-construction-row-5-img-1.jpg',
                                alt: 'Grand Construction',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'grand-construction-row-6-img-1.jpg',
                                alt: 'Grand Construction logo',
                            },
                            {
                                url: 'grand-construction-row-6-img-2.jpg',
                                alt: 'Grand Construction logo',
                            }
                        ]
                    },
                    {
                        type: "col-left",
                        images: [
                            {
                                url: 'grand-construction-row-7-img-1.jpg',
                                alt: 'Grand Construction photographie piscine',
                            },
                            {
                                url: 'grand-construction-row-7-img-2.jpg',
                                alt: 'Grand Construction livret',
                            },
                            {
                                url: 'grand-construction-row-7-img-3.jpg',
                                alt: 'Grand Construction dépliant',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'grand-construction-row-8-img-1.jpg',
                                alt: 'Grand Construction mockup magasine',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'grand-construction-row-9-img-1.jpg',
                                alt: 'Grand Construction mockup magasine',
                            },
                            {
                                url: 'grand-construction-row-9-img-2.jpg',
                                alt: 'Grand Construction mockup magasine',
                            }
                        ]
                    }
                ]        
            },
            //Place de l'immo
            {
                nextRefSlug: 'vinci-immobilier',
                nextRefName: 'Vinci Immobilier',
                nextRefCat: 'immobilier',
                slug: 'place-de-l-immo',
                name: 'Place<br>de l\'immo',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Place de l’immo, agence immobilière, dans la création d’une identité et de supports de communication.',
                shortName: 'Place de l\'immo',
                shortDesc: 'Agence immobilière à Montauban',
                fullName: 'Place de l\'immo',
                quote: "Agence immobilière à Montauban",
                content: `La place de l’Immo est une agence immobilière montalbanaise créée à l’initiative de ses 3 fondateurs, spécialistes des métiers de l’immobilier et du marché local. En proposant à ses clients des services de qualité et un accompagnement de tous les instants, La Place de l’Immo se positionne comme un acteur haut-de-gamme de l’Immobilier sur la ville de Montauban et ses alentours. 
                <br><br>
                Afin d’installer la marque, nos équipes sont intervenues dans la conception et la création d’une identité visuelle et narrative de la marque. Un univers alliant élégance et maîtrise, proximité et expertise. Notre intervention s’est poursuivie jusqu’au développement des supports de communication print et web.`,
                lineSkill: 'Conseil et stratégie de marque – édition – stratégie et outils digitaux',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'place-de-l-immo-row-1-img-1.jpg',
                                alt: 'Place de l\'immo logo',
                            },
                            {
                                url: 'place-de-l-immo-row-1-img-2.jpg',
                                alt: 'Place de l\'immo logo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'place-de-l-immo-row-2-img-1.jpg',
                                alt: 'Place de l\'immo identité',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'place-de-l-immo-row-3-img-1.jpg',
                                alt: 'Place de l\'immo charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'place-de-l-immo-row-4-img-1.jpg',
                                alt: 'Place de l\'immo',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'place-de-l-immo-row-5-img-1.jpg',
                                alt: 'Place de l\'immo',
                            },
                            {
                                url: 'place-de-l-immo-row-5-img-2.jpg',
                                alt: 'Place de l\'immo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'place-de-l-immo-row-6-img-1.jpg',
                                alt: 'Place de l\'immo site internet',
                            }
                        ]
                    },
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'place-de-l-immo-row-7-img-1.jpg',
                                alt: 'Place de l\'immo site mobile',
                            },
                            {
                                url: 'place-de-l-immo-row-7-img-2.jpg',
                                alt: 'Place de l\'immo',
                            },
                            {
                                url: 'place-de-l-immo-row-7-img-3.jpg',
                                alt: 'Place de l\'immo maquette site internet',
                            }
                        ]
                    }
                ]        
            },
            //Vinci immobilier
            {
                nextRefSlug: 'le-mas-toulousain',
                nextRefName: 'Le Mas Toulousain',
                nextRefCat: 'immobilier',
                slug: 'vinci-immobilier',
                name: 'Vinci<br>Immobilier',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Vinci Immobilier, promoteur immobilier, dans la création d’une identité et de supports de communication.',
                shortName: 'Vinci Immobilier',
                shortDesc: 'Principal acteur de la promotion immobilière en France, filiale du groupe VINCI',
                fullName: 'Vinci Immobilier',
                quote: "Principal acteur de la promotion immobilière en France, filiale du groupe VINCI",
                content: `Forts d’une expérience de plus de 20 ans dans les domaines de l’immobilier résidentiel et de l’immobilier d’entreprise, les filiales du groupe VINCI Immobilier ont à cœur de transformer les espaces avec des projets maîtrisés et innovants, qui anticipent les mutations des villes et des modes de vie. Vinci Immobilier a fait appel à nos équipes afin d’accompagner le lancement d’un nouveau programme résidentiel situé à Toulouse. 
                <br><br>
                Dans le cadre de sa commercialisation, Pure a imaginé un nom, une signature et l’identité visuelle de la résidence. Pour ce projet, notre parti a été de valoriser la démarche immobilière de Vinci : proposer un mode de vie nouveau, tourné vers le bien-vivre et la créativité. L’identité a été déployée de façon cohérente sur l’ensemble des supports de commercialisation.  `,
                lineSkill: 'Conseil & stratégie de marque – stratégie d’image et publicité – édition ',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'vinci-immobilier-row-1-img-1.jpg',
                                alt: 'Vinci Immobilier - L\'Estampe',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'vinci-immobilier-row-2-img-1.jpg',
                                alt: 'Vinci Immobilier - L\'Estampe',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'vinci-immobilier-row-3-img-1.jpg',
                                alt: 'Vinci Immobilier - L\'Estampe',
                            }
                        ]
                    },
                    {
                        type: "col-left",
                        images: [
                            {
                                url: 'vinci-immobilier-row-4-img-1.jpg',
                                alt: 'Vinci Immobilier - L\'Estampe',
                            },
                            {
                                url: 'vinci-immobilier-row-4-img-2.jpg',
                                alt: 'Vinci Immobilier - L\'Estampe',
                            },
                            {
                                url: 'vinci-immobilier-row-4-img-3.jpg',
                                alt: 'Vinci Immobilier - L\'Estampe',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'vinci-immobilier-row-5-img-1.jpg',
                                alt: 'Vinci Immobilier - L\'Estampe',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'vinci-immobilier-row-6-img-1.jpg',
                                alt: 'Vinci Immobilier - L\'Estampe',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'vinci-immobilier-row-7-img-1.jpg',
                                alt: 'Vinci Immobilier - L\'Estampe',
                            }
                        ]
                    }
                ]        
            },
            //Le Mas Toulousain
            {
                nextRefSlug: 'lco-concept',
                nextRefName: 'LCO Concept',
                nextRefCat: 'immobilier',
                slug: 'le-mas-toulousain',
                name: 'Le Mas<br>Toulousain',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Le Mas Toulousain, constructeur de maisons, dans son brand content et sa stratégie digitale.',
                shortName: 'Le Mas Toulousain',
                shortDesc: 'Des idées bien construites',
                fullName: 'Le Mas Toulousain',
                quote: "Des idées bien construites",
                content: `Le Mas Toulousain est un constructeur de maisons individuelles haut-de-gamme et personnalisées, sur Toulouse et sa région. Attentive aux attentes du marché, l’entreprise se démarque par sa capacité d’imagination et d’organisation rigoureuse des espaces qu’elle offre à ses clients. Cette démarche, le Mas Toulousain se doit de l’incarner en premier lieu sur son moyen de communication premier : son site internet. Pour nos équipes, l’enjeu est d’imaginer une plateforme ergonomique, structurée et moderne, fidèle reflet de l’expérience client proposée par le constructeur. 
                <br><br>
                Juxtaposer stratégie et créativité afin de positionner la marque de façon pertinente auprès de ses clients, mais également au sein du groupe mère CSP. `,
                lineSkill: 'Conseil & stratégie de marque -Stratégie d’image et publicité – stratégie et outils digitaux ',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'le-mas-toulousain-row-1-img-1.jpg',
                                alt: 'Le Mas Toulousain modèle de maison',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'le-mas-toulousain-row-2-img-1.jpg',
                                alt: 'Le Mas Toulousain site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'le-mas-toulousain-row-3-img-1.jpg',
                                alt: 'Le Mas Toulousain charte graphique',
                            }
                        ]
                    },
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'le-mas-toulousain-row-4-img-1.jpg',
                                alt: 'Le Mas Toulousain logo',
                            },
                            {
                                url: 'le-mas-toulousain-row-4-img-2.jpg',
                                alt: 'Le Mas Toulousain modèle de maison',
                            },
                            {
                                url: 'le-mas-toulousain-row-4-img-3.jpg',
                                alt: 'Le Mas Toulousain site mobile',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'le-mas-toulousain-row-5-img-1.jpg',
                                alt: 'Le Mas Toulousain stand salon expo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'le-mas-toulousain-row-6-img-1.jpg',
                                alt: 'Le Mas Toulousain',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'le-mas-toulousain-row-7-img-1.jpg',
                                alt: 'Le Mas Toulousain',
                            },
                            {
                                url: 'le-mas-toulousain-row-7-img-2.jpg',
                                alt: 'Le Mas Toulousain',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'le-mas-toulousain-row-8-img-1.jpg',
                                alt: 'Le Mas Toulousain',
                            },
                            {
                                url: 'le-mas-toulousain-row-8-img-2.jpg',
                                alt: 'Le Mas Toulousain perspective interieur',
                            }
                        ]
                    }
                ]        
            },
            //Socadex LCO
            {
                nextRefSlug: 'credit-agricole-immobilier',
                nextRefName: 'Crédit Agricole Immobilier',
                nextRefCat: 'immobilier',
                slug: 'lco-concept',
                name: 'LCO Concept',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne le groupe Socadex, spécialisé dans la construction immobilière, dans sa stratégie digitale.',
                shortName: 'LCO Concept',
                shortDesc: 'Groupe Socadex',
                fullName: 'LCO Concept',
                quote: "Groupe Socadex",
                content: `Depuis 1994, le groupe Socadex est spécialisé dans la construction immobilière sur la région du grand sud. Dans un contexte de profonde restructuration et face à une concurrence grandissante le groupe Socadex fait appel à Pure pour la refonte de l’ensemble de son écosystème digital. Le premier chantier mené par nos équipes est la refonte du site internet de la marque LCO Concept, filiale du groupe spécialiste de la maison individuelle. Pour offrir une réponse globale et experte, Pure a imaginé, notamment grâce à un atelier collaboratif, une structure capable de fluidifier le parcours client et favorisant la recherche d’informations. À terme, l’objectif de notre intervention pour le groupe Socadex est la construction d’un réseau digital interconnecté, offrant une visibilité maximale à la marque groupe, et une expérience lecture 360.`,
                lineSkill: 'Conseil et stratégie de marque – stratégie & outils digitaux ',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'socadex-row-1-img-1.jpg',
                                alt: 'Socadex les marques du groupe',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'socadex-row-2-img-1.jpg',
                                alt: 'Socadex',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'socadex-row-3-img-1.jpg',
                                alt: 'Socadex charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'socadex-row-4-img-1.jpg',
                                alt: 'Socadex',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'socadex-row-5-img-1.jpg',
                                alt: 'Socadex site internet',
                            }
                        ]
                    },
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'socadex-row-6-img-1.jpg',
                                alt: 'Socadex logo',
                            },
                            {
                                url: 'socadex-row-6-img-2.jpg',
                                alt: 'Socadex logo',
                            },
                            {
                                url: 'socadex-row-6-img-3.jpg',
                                alt: 'Socadex maquette site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'socadex-row-7-img-1.jpg',
                                alt: 'Socadex modèle de maison',
                            }
                        ]
                    }
                ]        
            },
            //Credit agricole immobilier
            {
                nextRefSlug: 'anywaves',
                nextRefName: 'Anywaves',
                nextRefCat: 'aerospatial',
                slug: 'credit-agricole-immobilier',
                name: 'Crédit Agricole<br>Immobilier',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne le Crédit Agricole Immobilier, société de gestion immobilière, dans sa stratégie événementielle. ',
                shortName: 'Crédit Agricole Immobilier',
                shortDesc: 'Société de gestion immobilière à Toulouse',
                fullName: 'Crédit Agricole Immobilier',
                quote: "Société de gestion immobilière à Toulouse",
                content: `Plus qu’un promoteur, Crédit Agricole Immobilier accompagne ses clients propriétaires avec des offres et services sur mesure. À l’occasion du Salon annuel du Logement Neuf de Toulouse, Pure a été missionné dans la création d’un stand événementiel dédié, avec pour objectif de recréer un espace à l’image des services proposés par la marque. Nos équipes ont pris en charge la direction artistique du projet, jusqu’à la production, le montage et le démontage sur site. `,
                lineSkill: 'Stratégie d’image et publicité - Édition - événementiel',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'credit-agricole-immobilier-row-1-img-1.jpg',
                                alt: 'Credit agricole immobilier stand salon expo',
                            },
                            {
                                url: 'credit-agricole-immobilier-row-1-img-2.jpg',
                                alt: 'Credit agricole immobilier stand salon expo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'credit-agricole-immobilier-row-2-img-1.jpg',
                                alt: 'Credit agricole immobilier',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'credit-agricole-immobilier-row-3-img-1.jpg',
                                alt: 'Credit agricole immobilier stand salon expo',
                            },
                            {
                                url: 'credit-agricole-immobilier-row-3-img-2.jpg',
                                alt: 'Credit agricole immobilier stand salon expo',
                            }
                        ]
                    }
                ]        
            }
        ]
    },
    {
        category: 'Aérospatial',
        slug: 'aerospatial',
        projet: [
            //Anywaves
            {
                nextRefSlug: 'aerospace-valley',
                nextRefName: 'Aerospace Valley',
                nextRefCat: 'aerospatial',
                slug: 'anywaves',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Anywaves, entreprise aérospatiale, dans sa stratégie de marque et sa stratégie digitale.',
                name: 'Anywaves',
                shortName: 'Anywaves',
                shortDesc: 'Control materiel to master waves',
                fullName: 'Anywaves',
                quote: "Control materiel to master waves",
                content: `Pionnier sur le secteur du New Space et soutenu par des organismes de recherches tels que le CNES, Anywaves conçoit et fabrique une nouvelle génération d’antennes miniatures, haute performance et de haute qualité pour des applications spatiales telles que les Télécommunications, la Navigation, l’Observation de la Terre, l’Entrée Atmosphérique et bien d’autres. La start-up toulousaine, en plein essor, a sollicité Pure pour la refonte et la structuration de sa marque. 
                <br><br>
                Nos équipes ont imaginé un univers audacieux, scientifique et contemporain, pour embarquer les nouvelles perspectives d’évolution de l’entreprise. D’une dynamique de start-up, Anywaves devient une entreprise incontournable sur le marché européen et international de l’innovation aérospatial et du New Space. `,
                lineSkill: 'Étude de marché & data – Positionnement – Plateforme de marque – Logotype & identité visuelle – Supports de marque',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'anywaves-row-1-img-1.jpg',
                                alt: 'Anywaves logo before after',
                            },
                            {
                                url: 'anywaves-row-1-img-2.jpg',
                                alt: 'Anywaves logo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'anywaves-row-2-img-1.jpg',
                                alt: 'Anywaves',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'anywaves-row-3-img-1.jpg',
                                alt: 'Anywaves charte graphique',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'anywaves-row-4-img-1.jpg',
                                alt: 'Anywaves fiche produit',
                            },
                            {
                                url: 'anywaves-row-4-img-2.jpg',
                                alt: 'Anywaves carte de visite',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'anywaves-row-5-img-1.jpg',
                                alt: 'Anywaves',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'anywaves-row-6-img-1.jpg',
                                alt: 'Anywaves presentation',
                            }
                        ]
                    }
                ]        
            },
            //Aerospace valley
            {
                nextRefSlug: 'intraspec',
                nextRefName: 'Intraspec',
                nextRefCat: 'aerospatial',
                slug: 'aerospace-valley',
                name: 'Aerospace<br>Valley',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Aerospace Valley, entreprise aérospatiale, dans sa stratégie de marque et sa stratégie graphique.',
                shortName: 'Aerospace Valley',
                shortDesc: 'Forum by Aerospace Valley',
                fullName: 'Aerospace Valley',
                quote: "Forum by Aerospace Valley",
                content: `Premier pôle de compétitivité mondial de la filière aérospatiale, Aerospace Valley est aujourd’hui un HUB incontournable dans les secteurs du Spatial, de l’Aéronautique et des Drones. Véritable acteur au service des régions « Occitanie » et « Nouvelle-Aquitaine », Aerospace Valley, se veut être un incubateur et un facilitateur sans égal dans la mise en relation des experts du marché. Notre Agence fut retenue en 2019- 2020, pour la définition du territoire de marque et la création de l’ensemble des supports du Forum Mondial, Édition 2020.
                <br>
                <br>
                Véhiculer au travers d’une image résolument moderne, les valeurs et le positionnement du Forum. Un événement mondial, où les plus grands experts se retrouvent autour de conférences thématiques pointues. Pour cela, la team projet de l’Agence s’est emparée des codes du digital, des start-ups, de l’aéronautique et du spatial dans l’objectif de conférer une identité forte, positionnant le Forum, Edition 2020, au centre de tous les projets.`,
                lineSkill: 'Étude de marché & data - Logotype & identité visuelle – Supports de marque – Production',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'aerospace-valley-row-1-img-1.jpg',
                                alt: 'Forum by Aerospace Valley',
                            },
                            {
                                url: 'aerospace-valley-row-1-img-2.jpg',
                                alt: 'Forum by Aerospace Valley',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'aerospace-valley-row-2-img-1.jpg',
                                alt: 'Forum by Aerospace Valley',
                            }
                        ]
                    },
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'aerospace-valley-row-3-img-1.jpg',
                                alt: 'Forum by Aerospace Valley',
                            },
                            {
                                url: 'aerospace-valley-row-3-img-2.jpg',
                                alt: 'Forum by Aerospace Valley',
                            },
                            {
                                url: 'aerospace-valley-row-3-img-3.jpg',
                                alt: 'Forum by Aerospace Valley',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'aerospace-valley-row-4-img-1.jpg',
                                alt: 'Forum by Aerospace Valley',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'aerospace-valley-row-5-img-1.jpg',
                                alt: 'Forum by Aerospace Valley',
                            },
                            {
                                url: 'aerospace-valley-row-5-img-2.jpg',
                                alt: 'Forum by Aerospace Valley',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'aerospace-valley-row-6-img-1.jpg',
                                alt: 'Forum by Aerospace Valley',
                            }
                        ]
                    }
                ]        
            },
            //Intraspec
            {
                nextRefSlug: 'les-boutiques-saint-georges',
                nextRefName: 'Les Boutiques Saint Georges',
                nextRefCat: 'centre-commerciaux',
                slug: 'intraspec',
                name: 'Intraspec',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Intraspec, entreprise spécialisée en défaillance système, dans son brand content et sa stratégie digitale.',
                shortName: 'Intraspec',
                shortDesc: 'The root to reliability',
                fullName: 'Intraspec Technologies',
                quote: "The root to reliability",
                content: `Créé en 2011, Intraspec est spécialisé dans la détection de défaillance système. Le groupe collabore se positionne comme partenaire privilégié d’institutions d’excellence telles que le CNES ou l’agence spatiale française. 
                <br><br>
                À l’aube d’un nouveau tournant dans son développement, Intraspec a fait appel à nos équipes pour l’évolution de son identité et la refonte de son site internet. Des éléments essentiels au développement de la marque, et à la valorisation de son expertise unique sur son marché.`,
                lineSkill: 'Audit de l’existant – Étude de marché et data – Positionnement – Logotype & identité visuelle – Naming & signature de marque – UX/UI – Développement – Supports de marque',
                csm: false,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'intraspec-row-1-img-1.jpg',
                                alt: 'Intraspec logo',
                            },
                            {
                                url: 'intraspec-row-1-img-2.jpg',
                                alt: 'Intraspec logo',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'intraspec-row-2-img-1.jpg',
                                alt: 'Intraspec enveloppe',
                            },
                            {
                                url: 'intraspec-row-2-img-2.jpg',
                                alt: 'Intraspec',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'intraspec-row-3-img-1.jpg',
                                alt: 'Intraspec',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'intraspec-row-4-img-1.jpg',
                                alt: 'Intraspec charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'intraspec-row-5-img-1.jpg',
                                alt: 'Intraspec',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'intraspec-row-6-img-1.jpg',
                                alt: 'Intraspec',
                            },
                            {
                                url: 'intraspec-row-6-img-2.jpg',
                                alt: 'Intraspec site internet',
                            }
                        ]
                    }
                ]        
            }
        ]
    },
    {
        category: 'Centres Commerciaux',
        slug: 'centre-commerciaux',
        projet: [
            //Les Boutiques Saint Georges
            {
                nextRefSlug: 'espace-saint-georges',
                nextRefName: 'Espace Saint-Georges',
                nextRefCat: 'centre-commerciaux',
                slug: 'les-boutiques-saint-georges',
                name: 'Les Boutiques<br>Saint Georges',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Les Boutiques Saint Georges, centre commercial, dans sa stratégie de communication globale.',
                shortName: 'Les Boutiques Saint Georges',
                shortDesc: 'Centre commercial Toulouse',
                fullName: 'Les Boutiques Saint Georges',
                quote: "Centre commercial Toulouse",
                content: `Centre commercial historique situé au cœur du centre- ville de Toulouse, l’Espace Saint Georges connaît une période de transformation : changement de nom, de décor et de boutiques… Le centre s’adapte et relève le défi de répondre aux nouveaux enjeux du retail. Depuis quelques années, Pure accompagne le centre commercial Espace Saint Georges dans cette nouvelle dynamique, mais également dans toute la communication du Centre et de son animation depuis 2016.
                <br><br>
                Les différents pôles de l’agence déploient l’ensemble de leurs expertises pour concevoir et développer une stratégie de communication globale, à même de répondre aux problématiques rencontrées par l’Espace Saint Georges : optimiser l’image et la notoriété, amener la considération et l’engagement, et développer la fidélisation. Pure s’attache à offrir un accompagnement et une créativité sans cesse renouvelée, pour alimenter et faire vivre l’Espace Saint Georges.
                <br><br>
                <a href="https://www.lesboutiquessaintgeorges.fr/" target="_blank" class="cas-client-decouvrir-site">Découvrir le site</a>`,
                lineSkill: 'Étude de marché et data – Planning stratégique – Audit de l’existant – Positionnement – Plateforme de marque – Naming & signature – Supports de marque – Social media management – UX/UI – Développement – Création & production évènementielle',
                csm: true,
                bc: true,
                sip: true,
                edition: false,
                sod: true,
                sms: true,
                event: true,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'bsg-row-1-img-1.jpg',
                                alt: 'BSG - Logo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'bsg-row-2-img-1.jpg',
                                alt: 'BSG site internet ',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'bsg-row-3-img-1.jpg',
                                alt: 'BSG site internet',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'bsg-row-4-img-1.png',
                                alt: 'BSG motif',
                            },
                            {
                                url: 'bsg-row-4-img-2.png',
                                alt: 'BSG motif',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'bsg-row-5-img-1.jpg',
                                alt: 'BSG site mobile',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'bsg-row-6-img-1.jpg',
                                alt: 'BSG abris bus',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'bsg-row-7-img-1.jpg',
                                alt: 'BSG',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'bsg-row-8-img-1.jpg',
                                alt: 'BSG',
                            },
                            {
                                url: 'bsg-row-8-img-2.jpg',
                                alt: 'BSG',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'bsg-row-9-img-1.jpg',
                                alt: 'BSG',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'bsg-row-10-img-1.jpg',
                                alt: 'BSG',
                            },
                            {
                                url: 'bsg-row-10-img-2.jpg',
                                alt: 'BSG',
                            }
                        ]
                    }
                ]        
            },
            //Espace Saint Georges
            {
                nextRefSlug: 'saint-martial',
                nextRefName: 'Saint Martial',
                nextRefCat: 'centre-commerciaux',
                slug: 'espace-saint-georges',
                name: 'Espace<br>Saint Georges',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Espace Saint Georges, centre commercial, dans sa stratégie de communication globale.',
                shortName: 'Espace Saint Georges',
                shortDesc: 'Centre commercial Toulouse',
                fullName: 'Espace Saint Georges',
                quote: "Centre commercial Toulouse",
                content: `Centre commercial historique situé au cœur du centre- ville de Toulouse, l’Espace Saint Georges connaît une période de transformation : changement de nom, de décor et de boutiques… Le centre s’adapte et relève le défi de répondre aux nouveaux enjeux du retail. Depuis quelques années, Pure accompagne le centre commercial Espace Saint Georges dans cette nouvelle dynamique, mais également dans toute la communication du Centre et de son animation depuis 2016.
                <br><br>
                Les différents pôles de l’agence déploient l’ensemble de leurs expertises pour concevoir et développer une stratégie de communication globale, à même de répondre aux problématiques rencontrées par l’Espace Saint Georges : optimiser l’image et la notoriété, amener la considération et l’engagement, et développer la fidélisation. Pure s’attache à offrir un accompagnement et une créativité sans cesse renouvelée, pour alimenter et faire vivre l’Espace Saint Georges.`,
                lineSkill: 'Étude de marché et data – Planning stratégique – Audit de l’existant – Positionnement – Plateforme de marque – Naming & signature – Supports de marque – Social media management – UX/UI – Développement – Création & production évènementielle',
                csm: true,
                bc: true,
                sip: true,
                edition: false,
                sod: true,
                sms: true,
                event: true,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'esg-row-1-img-1.jpg',
                                alt: 'Espace Saint Georges',
                            },
                            {
                                url: 'esg-row-1-img-2.jpg',
                                alt: 'Espace Saint Georges',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'esg-row-2-img-1.jpg',
                                alt: 'Espace Saint Georges',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'esg-row-3-img-1.jpg',
                                alt: 'Espace Saint Georges',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'esg-row-4-img-1.jpg',
                                alt: 'Espace Saint Georges',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'esg-row-5-img-1.jpg',
                                alt: 'Espace Saint Georges',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'esg-row-6-img-1.jpg',
                                alt: 'Espace Saint Georges',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'esg-row-7-img-1.jpg',
                                alt: 'Espace Saint Georges',
                            }
                        ]
                    },
                    {
                        type: "col-left",
                        images: [
                            {
                                url: 'esg-row-8-img-1.jpg',
                                alt: 'Espace Saint Georges',
                            },
                            {
                                url: 'esg-row-8-img-2.jpg',
                                alt: 'Espace Saint Georges',
                            },
                            {
                                url: 'esg-row-8-img-3.jpg',
                                alt: 'Espace Saint Georges',
                            }
                        ]
                    },
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'esg-row-9-img-1.jpg',
                                alt: 'Espace Saint Georges',
                            },
                            {
                                url: 'esg-row-9-img-2.jpg',
                                alt: 'Espace Saint Georges site internet',
                            },
                            {
                                url: 'esg-row-9-img-3.jpg',
                                alt: 'Espace Saint Georges - BD Super Georges',
                            }
                        ]
                    }
                ]        
            },
            //Saint Martial
            {
                nextRefSlug: 'bofrost',
                nextRefName: 'bofrost*',
                nextRefCat: 'food',
                slug: 'saint-martial',
                name: 'Saint Martial',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Saint Martial, centre commercial, dans sa stratégie de communication graphique et digitale.',
                shortName: 'Saint Martial',
                shortDesc: 'Centre commercial Limoges',
                fullName: 'Saint Martial',
                quote: "Centre commercial Limoges",
                content: `Après 30 ans d’existence, le Centre commercial Saint Martial s’impose comme le rendez-vous shopping incontournable du centre-ville de Limoges. Son positionnement stratégique et son offre complète en font un lieu à la fois au cœur des tendances, et au plus près de ses clients. 30 ans, c’est aussi l’âge de raison, le moment de s’affirmer, et l’occasion de prendre un virage stratégique. Pure mène alors la refonte de l’identité de la marque Saint Martial, afin d’affirmer son renouveau, et son positionnement de « Créateur d’émotions. ».
                <br><br>
                Nos équipes mènent également tout le processus d’activation de la marque on-going. Grâce à un plan d’actions de marketing opérationnel, Pure met en place une stratégie de communication globale permettant de répondre à des objectifs de trafic, de conversion et de fidélisation.`,
                lineSkill: 'Étude de marché et data – Planning stratégique –  Positionnement – Naming & signature – Supports de marque – Social media management – UX/UI – Développement – Création & production évènementielle',
                csm: true,
                bc: true,
                sip: true,
                edition: false,
                sod: true,
                sms: true,
                event: true,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'st-martial-row-1-img-1.jpg',
                                alt: 'St Martial',
                            },
                            {
                                url: 'st-martial-row-1-img-2.jpg',
                                alt: 'St Martial',
                            }
                        ]
                    },
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'st-martial-row-2-img-1.jpg',
                                alt: 'St Martial',
                            },
                            {
                                url: 'st-martial-row-2-img-2.jpg',
                                alt: 'St Martial',
                            },
                            {
                                url: 'st-martial-row-2-img-3.jpg',
                                alt: 'St Martial',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'st-martial-row-3-img-1.jpg',
                                alt: 'St Martial',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'st-martial-row-4-img-1.jpg',
                                alt: 'St Martial Happy Days',
                            },
                            {
                                url: 'st-martial-row-4-img-2.jpg',
                                alt: 'St Martial abris bus',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'st-martial-row-5-img-1.jpg',
                                alt: 'St Martial affiches',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'st-martial-row-6-img-1.jpg',
                                alt: 'St Martial Happy Summer',
                            },
                            {
                                url: 'st-martial-row-6-img-2.jpg',
                                alt: 'St Martial Happy Summer',
                            },
                            {
                                url: 'st-martial-row-6-img-3.jpg',
                                alt: 'St Martial Happy Summer',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'st-martial-row-7-img-1.jpg',
                                alt: 'St Martial',
                            }
                        ]
                    }
                ]        
            },
            //Intermarché
            /*
            {
                nextRefSlug: 'bofrost',
                nextRefName: 'bofrost*',
                nextRefCat: 'centre-commerciaux',
                slug: 'intermarche',
                name: 'Intermarché',
                metaDesc: '',
                shortName: 'Intermarché',
                shortDesc: 'Centre commercial Cugnaux',
                fullName: 'Intermarché',
                quote: "Centre commercial Cugnaux",
                content: `Après de nombreux mois de travaux d’agrandissement, l’enseigne Intermarché de Cugnaux a fait appel à nos équipes pour l’organisation de son inauguration ! Le temps d’une soirée, Pure a imaginé un événement sur-mesure, avec pour objectif la valorisation de nouveaux espaces consacrés à une nouvelle offre de producteurs locaux. `,
                lineSkill: 'Création et production événementielle – Supports de marque',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: false,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'intermarche-row-1-img-1.jpg',
                                alt: 'Intermarché',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'intermarche-row-2-img-1.jpg',
                                alt: 'Intermarché',
                            },
                            {
                                url: 'intermarche-row-2-img-2.jpg',
                                alt: 'Intermarché',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'intermarche-row-3-img-1.jpg',
                                alt: 'Intermarché',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'intermarche-row-4-img-1.jpg',
                                alt: 'Intermarché',
                            }
                        ]
                    }
                ]        
            },
            */
        ]
    },
    {
        category: 'Food',
        slug: 'food',
        projet: [
            //Bofrost
            {
                nextRefSlug: 'momes',
                nextRefName: 'Mômes',
                nextRefCat: 'food',
                slug: 'bofrost',
                name: 'bofrost*',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne bofrost*, groupe de distribution de produits surgelés, dans sa stratégie de marque.',
                shortName: 'bofrost*',
                shortDesc: 'Groupe de distribution de produits alimentaires surgelés',
                fullName: 'bofrost*',
                quote: "Groupe de distribution de produits alimentaires surgelés",
                content: `Leader européen de vente de produits surgelés à domicile, le groupe bofrost* est conscient des opportunités liées à une politique managériale innovante. Inclusion, agilité et bienveillance forment le socle de valeurs de la démarche RH de bofrost*. 
                <br><br>
                Pour affirmer et valoriser son positionnement auprès de ses partenaires et collaborateurs, bofrost* a confié à Pure la création de sa marque employeur : Time 4. Nos équipes ont accompagné le groupe dans sa stratégie de branding, avec la création d’une identité à part entière. Time 4 devient alors un emblème fédérateur pour tous les collaborateurs du groupe. `,
                lineSkill: 'Étude de marché & data – Positionnement – Plateforme de marque – Logotype & identité visuelle – Supports de marque',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: false,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'bofrost-row-1-img-1.jpg',
                                alt: 'bofrost*',
                            },
                            {
                                url: 'bofrost-row-1-img-2.jpg',
                                alt: 'bofrost*',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'bofrost-row-2-img-1.jpg',
                                alt: 'bofrost*',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'bofrost-row-3-img-1.jpg',
                                alt: 'bofrost* charte graphique',
                            }
                        ]
                    },
                    {
                        type: "col-left",
                        images: [
                            {
                                url: 'bofrost-row-4-img-1.jpg',
                                alt: 'bofrost*',
                            },
                            {
                                url: 'bofrost-row-4-img-2.jpg',
                                alt: 'bofrost* badge',
                            },
                            {
                                url: 'bofrost-row-4-img-3.jpg',
                                alt: 'bofrost* tee-shirt',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'bofrost-row-5-img-1.jpg',
                                alt: 'bofrost* sac',
                            },
                            {
                                url: 'bofrost-row-5-img-2.jpg',
                                alt: 'bofrost*',
                            }
                        ]
                    }
                ]        
            },
            //Momes
            {
                nextRefSlug: 'esprit-traiteur',
                nextRefName: 'Esprit traiteur',
                nextRefCat: 'food',
                slug: 'momes',
                name: 'Mômes',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Mômes, restaurateur traiteur gastronomique, dans la création d’une identité visuelle.',
                shortName: 'Mômes',
                shortDesc: 'Restaurateur traiteur gastronomique ',
                fullName: 'Mômes',
                quote: "Restaurateur traiteur gastronomique ",
                content: `Mômes est à l’origine d’un concept convivial et novateur de service traiteur gastronomique. Né au Pays Basque à l’initiative de trois passionnés ; Mômes a fait de son métier le partage du bien manger et du bien vivre. Afin de donner corps à cette toute nouvelle activité, nous avons accompagné Pierre, Pascal et Mikel dans la création de la création de la marque Mômes. 
                <br><br>
                Mômes, c’est un franc-parler et un caractère impertinent. C’est l’attitude espiègle et non-conformiste des fondateurs qui ont permis à nos équipes d’imaginer un territoire d’expression unique et fidèle au service proposé. La générosité, l’authenticité et la convivialité viennent enrichir le savoir-faire gastronomique de la marque. Autant de valeurs et de traits de caractères que Pure a synthétisé dans cette nouvelle identité. 
                `,
                lineSkill: 'Conseils et stratégie de marque – Brand content – Stratégie d’image et publicité – édition – stratégie et outils digitaux ',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'momes-row-1-img-1.jpg',
                                alt: 'Momes',
                            },
                            {
                                url: 'momes-row-1-img-2.jpg',
                                alt: 'Momes',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'momes-row-2-img-1.jpg',
                                alt: 'Momes',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'momes-row-3-img-1.jpg',
                                alt: 'Momes',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'momes-row-4-img-1.jpg',
                                alt: 'Momes',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'momes-row-5-img-1.jpg',
                                alt: 'Momes',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'momes-row-6-img-1.jpg',
                                alt: 'Momes',
                            },
                            {
                                url: 'momes-row-6-img-2.jpg',
                                alt: 'Momes',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'momes-row-7-img-1.jpg',
                                alt: 'Momes',
                            },
                            {
                                url: 'momes-row-7-img-2.jpg',
                                alt: 'Momes',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'momes-row-8-img-1.jpg',
                                alt: 'Momes',
                            }
                        ]
                    },
                ]        
            },
            //Esprit Traiteur
            {
                nextRefSlug: 'ncis-restauration',
                nextRefName: 'NCIS Restauration',
                nextRefCat: 'food',
                slug: 'esprit-traiteur',
                name: 'Esprit<br>Traiteur',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Esprit Traiteur, traiteur, dans sa stratégie de branding et sa stratégie digitale.',
                shortName: 'Esprit Traiteur',
                shortDesc: 'Traiteur à Toulouse & Pays Basque.',
                fullName: 'Esprit Traiteur',
                quote: "Traiteur à Toulouse & Pays Basque.",
                content: `Entreprise familiale créée il y a plus de 50 ans, Esprit Traiteur est devenu, à force de labeur d’une passion intarissable, leader du marché traiteur à Toulouse. Nous avons été sollicités afin de mener une réflexion autour du renouvellement du positionnement de la marque Esprit Traiteur, qui souffrait alors d’une image vieillissante, loin du dynamisme que connaît l’activité. 
                <br><br>
                Nous avons cherché à donner corps à l’identité d’Esprit Traiteur. Des gastronomes ambulants, des artisans du goût, au savoir-faire éprouvé et à la créativité débordante.
                <br>
                Notre intervention s’est portée sur la refonte du territoire de la marque Esprit Traiteur : un univers haut-de-gamme, un savoir-faire unique et une créativité caractéristique incarnés par 
                une nouvelle signature de marque. 
                <br>
                Pour appuyer ce renouveau, nous avons mené la création et la mise en place d’une campagne de communication, afin de porter haut et fort ce virage pour la marque.
                <br><br>
                <a href="https://esprit-traiteur.com/" target="_blank" class="cas-client-decouvrir-site">Découvrir le site</a>`,
                lineSkill: 'Conseil & stratégie de marque – Brand content – stratégie d’image et publicité – stratégie & outils digitaux – social media strategy',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'esprit-traiteur-row-1-img-1.jpg',
                                alt: 'Esprit Traiteur',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'esprit-traiteur-row-2-img-1.jpg',
                                alt: 'Esprit Traiteur',
                            },
                            {
                                url: 'esprit-traiteur-row-2-img-2.jpg',
                                alt: 'Esprit Traiteur',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'esprit-traiteur-row-3-img-1.jpg',
                                alt: 'Esprit Traiteur',
                            },
                            {
                                url: 'esprit-traiteur-row-3-img-2.jpg',
                                alt: 'Esprit Traiteur',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'esprit-traiteur-row-4-img-1.jpg',
                                alt: 'Esprit Traiteur charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'esprit-traiteur-row-5-img-1.jpg',
                                alt: 'Esprit Traiteur abris bus',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'esprit-traiteur-row-6-img-1.png',
                                alt: 'Esprit Traiteur',
                            },
                            {
                                url: 'esprit-traiteur-row-6-img-2.png',
                                alt: 'Esprit Traiteur',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'esprit-traiteur-row-7-img-1.png',
                                alt: 'Esprit Traiteur',
                            }
                        ]
                    },
                    {
                        type: "col-left",
                        images: [
                            {
                                url: 'esprit-traiteur-row-8-img-1.png',
                                alt: 'Esprit Traiteur site internet',
                            },
                            {
                                url: 'esprit-traiteur-row-8-img-2.png',
                                alt: 'Esprit Traiteur',
                            },
                            {
                                url: 'esprit-traiteur-row-8-img-3.png',
                                alt: 'Esprit Traiteur',
                            },
                            {
                                url: 'esprit-traiteur-row-8-img-4.png',
                                alt: 'Esprit Traiteur',
                            }
                        ]
                    }
                ]        
            },
            //NCIS Restauration
            {
                nextRefSlug: 'ekynox',
                nextRefName: 'Ekynox',
                nextRefCat: 'developpement-durable-et-ecologie',
                slug: 'ncis-restauration',
                name: 'NCIS<br>Restauration',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne NCIS Restauration, spécialiste de la restauration collective, dans sa stratégie de marque.',
                shortName: 'NCIS Restauration',
                shortDesc: 'Conseil, audit et formation en restauration collective & commerciale, pour promouvoir une alimentation saine et durable',
                fullName: 'NCIS Restauration',
                quote: "Conseil, audit et formation en restauration collective & commerciale, pour promouvoir une alimentation saine et durable",
                content: `Dans un contexte de profonde restructuration, le cabinet NCIS Restauration, spécialiste de la restauration collective basé à Paris, a confié à nos équipes le renouvellement de son identité de marque. Pour assurer sa visibilité sur son marché, et asseoir son expertise, 
                <br><br>
                NCIS Restauration doit donner corps à ses ambitions et à son savoir-faire. Nous avons donc mené une stratégie de branding, et la refonte de l’identité visuelle de la marque. Un territoire d’expression moderne et statutaire, permettant à NCIS Restauration de porter hauts les valeurs qui l’animent. `,
                lineSkill: 'Conseil & stratégie de marque – Stratégie d’image et publicité – Edition – Stratégie & outils digitaux ',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ncis-restauration-row-1-img-1.jpg',
                                alt: 'NCIS Restauration logo before after',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ncis-restauration-row-2-img-1.jpg',
                                alt: 'NCIS Restauration identité visuelle',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ncis-restauration-row-3-img-1.jpg',
                                alt: 'NCIS Restauration charte graphique',
                            }
                        ]
                    },
                    {
                        type: "col-left",
                        images: [
                            {
                                url: 'ncis-restauration-row-4-img-1.jpg',
                                alt: 'NCIS Restauration gobelet',
                            },
                            {
                                url: 'ncis-restauration-row-4-img-2.jpg',
                                alt: 'NCIS Restauration carte de visite',
                            },
                            {
                                url: 'ncis-restauration-row-4-img-3.jpg',
                                alt: 'NCIS Restauration carte de visite',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ncis-restauration-row-5-img-1.jpg',
                                alt: 'NCIS Restauration',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ncis-restauration-row-6-img-1.jpg',
                                alt: 'NCIS Restauration',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ncis-restauration-row-7-img-1.jpg',
                                alt: 'NCIS Restauration',
                            }
                        ]
                    }
                ]        
            }
        ]
    },
    {
        category: 'Développement durable & Écologie',
        slug: 'developpement-durable-et-ecologie',
        projet: [
            //Ekynox
            {
                nextRefSlug: 'solstice-consulting',
                nextRefName: 'Solstice Consulting',
                nextRefCat: 'developpement-durable-et-ecologie',
                slug: 'ekynox',
                name: 'Ekynox',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne le Groupe Solstice, energy manager, dans le branding et la stratégie digitale d’Ekynox.',
                shortName: 'Ekynox',
                shortDesc: 'Plateforme de financements participatifs',
                fullName: 'Ekynox',
                quote: "Plateforme de financements participatifs",
                content: `Solstice Groupe se définit comme un « Energy manager » qui conçoit, finance, développe, gère et exploite des solutions énergétiques éco-responsable. En 2019, celui-ci entreprend la création d’une plateforme de crowdfunding nommée Ekynox, pour venir en aide aux porteurs de projets innovants dans le secteur de l’énergie durable.
                <br><br>
                L’agence Pure a accompagné le Groupe Solstice dans la création de la marque Ekynox, ainsi que dans le développement d’une plateforme digitale de crowdfunding. Pour ça, nos équipes sont intervenues dans l’élaboration d’une plateforme de marque, ainsi que d’une signature, capable de positionner Ekynox comme un acteur incontournable du développement de nouvelles solutions d’énergie. 
                <br>
                Nous avons également mené la conception et le développement de la plateforme web de crowdfunding Ekynox. Un outil offrant légitimité et crédibilité passe par une expérience utilisateur facilité et intuitive, mais aussi par l’intégration d’innovations technologiques, imposant la marque comme référence sur son secteur. `,
                lineSkill: 'Positionnement – plateforme de marque – identité visuelle – UX/UI – Développement',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'ekynox-row-1-img-1.jpg',
                                alt: 'Ekynox logo',
                            },
                            {
                                url: 'ekynox-row-1-img-2.jpg',
                                alt: 'Ekynox logo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ekynox-row-2-img-1.jpg',
                                alt: 'Ekynox charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ekynox-row-3-img-1.jpg',
                                alt: 'Ekynox site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ekynox-row-4-img-1.jpg',
                                alt: 'Ekynox',
                            }
                        ]
                    },
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'ekynox-row-5-img-1.jpg',
                                alt: 'Ekynox site internet',
                            },
                            {
                                url: 'ekynox-row-5-img-2.jpg',
                                alt: 'Ekynox site internet',
                            },
                            {
                                url: 'ekynox-row-5-img-3.jpg',
                                alt: 'Ekynox maquette site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'ekynox-row-6-img-1.jpg',
                                alt: 'Ekynox',
                            }
                        ]
                    }
                ]        
            },
            //Solstice
            {
                nextRefSlug: 'symbiose-developpement',
                nextRefName: 'Symbiose Développement',
                nextRefCat: 'developpement-durable-et-ecologie',
                slug: 'solstice-consulting',
                name: 'Solstice Consulting',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Solstice Consulting, energy manager, dans le branding et la stratégie digitale.',
                shortName: 'Solstice Consulting',
                shortDesc: 'Concevoir l’écologie',
                fullName: 'Solstice Consulting',
                quote: "Concevoir l’écologie",
                content: `Solstice Consulting se définit comme un « Energy manager » qui conçoit, finance, développe, structure, gère et exploite des solutions énergétiques et éco-responsable. Solstice a consulté l’agence Pure pour le repositionnement de sa marque groupe. 
                <br><br>
                Pour définir et affirmer la mission et la vision de Solstice Consulting, nous sommes intervenus sur la création d’une signature de marque : « Concevoir l’écologie ». Pour pérenniser le positionnement, nos équipes ont accompagné Solstice sur le déploiement d’un plan de communication transversal, avec notamment le développement d’outils print et digitaux, et la mise en place d’une stratégie social media. `,
                lineSkill: 'Audit de l’existant – Positionnement – Plateforme de marque – Naming & signature – Supports de marque – Social media management – Création & production évenementielle',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'solstice-row-1-img-1.jpg',
                                alt: 'Solstice',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'solstice-row-2-img-1.jpg',
                                alt: 'Solstice logo',
                            },
                            {
                                url: 'solstice-row-2-img-2.jpg',
                                alt: 'Solstice',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'solstice-row-3-img-1.jpg',
                                alt: 'Solstice charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'solstice-row-4-img-1.jpg',
                                alt: 'Solstice',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'solstice-row-5-img-1.jpg',
                                alt: 'Solstice',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'solstice-row-6-img-1.jpg',
                                alt: 'Solstice site internet ',
                            },
                            {
                                url: 'solstice-row-6-img-2.jpg',
                                alt: 'Solstice site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'solstice-row-7-img-1.jpg',
                                alt: 'Solstice site internet',
                            }
                        ]
                    }
                ]        
            },
            //Symbiose
            {
                nextRefSlug: 'manentena',
                nextRefName: 'Manentena',
                nextRefCat: 'developpement-durable-et-ecologie',
                slug: 'symbiose-developpement',
                name: 'Symbiose Développement',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Symbiose Développement, investisseur écologique, dans sa stratégie de branding.',
                shortName: 'Symbiose Développement',
                shortDesc: 'Innovation en investissement écologique',
                fullName: 'Symbiose Développement',
                quote: "Innovation en investissement écologique",
                content: `Symbiose accompagne la transition écologique d’une enseigne, en associant sa clientèle au processus. La solution qu’elle propose permet à la fois une modernisation de l’espace, ainsi qu’un support d’investissement s’inscrivant dans une économie réelle : celle de l’énergie. 
                Afin soutenir le lancement de la solution,
                <br><br>
                 Symbiose a fait appel à Pure pour la création d’un support de présentation pédagogique et commercial. Nos équipes sont intervenues dans la création et la hiérarchisation des contenus et l’enrichissement du territoire visuel de la marque, pour soutenir ses propos. `,
                lineSkill: 'Logotype & identité visuelle – Naming & signature de marque – Conception rédaction – Supports de marque',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'symbiose-row-1-img-1.jpg',
                                alt: 'Symbiose logo',
                            },
                            {
                                url: 'symbiose-row-1-img-2.jpg',
                                alt: 'Symbiose logo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'symbiose-row-2-img-1.jpg',
                                alt: 'Symbiose',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'symbiose-row-3-img-1.jpg',
                                alt: 'Symbiose charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'symbiose-row-4-img-1.jpg',
                                alt: 'Symbiose site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'symbiose-row-5-img-1.jpg',
                                alt: 'Symbiose site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'symbiose-row-6-img-1.jpg',
                                alt: 'Symbiose',
                            }
                        ]
                    }
                ]        
            },
            //Manentena
            {
                nextRefSlug: 'vegebom',
                nextRefName: 'Vegebom',
                nextRefCat: 'education-et-sante',
                slug: 'manentena',
                name: 'Manentena',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Manentena, fondation humanitaire, dans sa stratégie de branding et sa stratégie digitale.',
                shortName: 'Manentena',
                shortDesc: 'Donnons de la ressource',
                fullName: 'Manentena',
                quote: "Donnons de la ressource",
                content: `Créée à l’initiative des groupes Solstice et Cap Sud, tout deux acteurs pour majeurs de la transition énergétique, la fondation Manentena a pour objectif d’amener de l’eau, de fournir de l’électricité, en un mot de donner de l’énergie aux populations les plus défavorisées. Elle propose des solutions accessibles et durables et œuvre pour rassembler les ressources nécessaires à leur mise en œuvre. 
                <br><br>
                Après l’évolution de son positionnement, la fondation Manentena souhaite donner à sa communication digitale une nouvelle trajectoire, avec pour objectif principal le développement des dons. 
                <br><br>
                Pure a été mandatée pour accompagner la fondation dans le développement d’un nouveau site internet, à la fois portail d’informations et première vitrine de Manentena. Nos équipes ont pris en charge l’architecture, le design l’intégration et la mise en ligne du nouveau site, dans le respect des délais imposés.                 
                <br><br>
                <a href="https://manentena-foundation.org/" target="_blank" class="cas-client-decouvrir-site">Découvrir le site</a>`,
                lineSkill: 'Positionnement – Plateforme de de marque – Logotype & identité visuelle – Conception rédaction – UX/UI – Développement',
                csm: true,
                bc: false,
                sip: false,
                edition: false,
                sod: true,
                sms: true,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'manentena-row-1-img-1.jpg',
                                alt: 'Manentena site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'manentena-row-2-img-1.jpg',
                                alt: 'Manentena',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'manentena-row-3-img-1.jpg',
                                alt: 'Manentena charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'manentena-row-4-img-1.jpg',
                                alt: 'Manentena',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'manentena-row-5-img-1.jpg',
                                alt: 'Manentena',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'manentena-row-6-img-1.jpg',
                                alt: 'Manentena',
                            },
                            {
                                url: 'manentena-row-6-img-2.jpg',
                                alt: 'Manentena',
                            }
                        ]
                    }
                ]        
            }
        ]
    },
    {
        category: 'Éducation & Santé',
        slug: 'education-et-sante',
        projet: [
            //Vegebom
            {
                nextRefSlug: 'inp-toulouse',
                nextRefName: 'INP Toulouse',
                nextRefCat: 'education-et-sante',
                slug: 'vegebom',
                name: 'Vegebom',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Vegebom, marque de produits pharmaceutiques, dans sa stratégie de branding. ',
                shortName: 'Vegebom',
                shortDesc: 'Marque pharmaceutique Tridem Pharma ',
                fullName: 'Vegebom',
                quote: "Marque pharmaceutique Tridem Pharma ",
                content: `Marque historique créée en 1868, Vegebom est aujourd’hui un acteur incontournable du marché de l’aroma-phytothérapie en France et à l’international. L’Agence Pure est intervenue dans la définition et le déploiement du territoire de la marque : une alternative efficace et naturelle aux médicaments traditionnels.
                <br><br>
                Afin d’assurer cohérence et différenciation à la marque Vegebom sur un marché en plein développement, nos équipes ont mené la réflexion et la conception de l’identité de la marque. Une signature “L’essentiel nature”, a été le fil conducteur de notre réflexion, et, dans un second temps, du déploiement du territoire d’expression de la marque. 
                <br><br>
                L’Agence Pure a accompagné Vegebom dans la conception et la création des supports de communication commerciale et institutionnelle de la marque. Avec l’intervention de consultants spécialistes du secteur, nos équipes se sont immergées, afin d’apporter une réponse juste, fiable, et qui fait sens. `,
                lineSkill: 'Positionnement – Logotype & identité visuelle – Naming & signature de marque – Plateforme de discours – Supports de marque',
                csm: true,
                bc: true,
                sip: true,
                edition: true,
                sod: true,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'vegebom-row-1-img-1.jpg',
                                alt: 'Vegebom logo',
                            },
                            {
                                url: 'vegebom-row-1-img-2.jpg',
                                alt: 'Vegebom logo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'vegebom-row-2-img-1.jpg',
                                alt: 'Vegebom charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'vegebom-row-3-img-1.jpg',
                                alt: 'Vegebom',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'vegebom-row-4-img-1.jpg',
                                alt: 'Vegebom',
                            }
                        ]
                    },
                    {
                        type: "col-right",
                        images: [
                            {
                                url: 'vegebom-row-5-img-1.jpg',
                                alt: 'Vegebom',
                            },
                            {
                                url: 'vegebom-row-5-img-2.jpg',
                                alt: 'Vegebom',
                            },
                            {
                                url: 'vegebom-row-5-img-3.jpg',
                                alt: 'Vegebom',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'vegebom-row-6-img-1.jpg',
                                alt: 'Vegebom',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'vegebom-row-7-img-1.jpg',
                                alt: 'Vegebom',
                            },
                            {
                                url: 'vegebom-row-7-img-2.jpg',
                                alt: 'Vegebom',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'vegebom-row-8-img-1.jpg',
                                alt: 'Vegebom',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'vegebom-row-9-img-1.jpg',
                                alt: 'Vegebom',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'vegebom-row-10-img-1.jpg',
                                alt: 'Vegebom',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'vegebom-row-11-img-1.jpg',
                                alt: 'Vegebom',
                            }
                        ]
                    }
                ]        
            },
            //INP Toulouse
            {
                nextRefSlug: 'biomega',
                nextRefName: 'Biomega',
                nextRefCat: 'education-et-sante',
                slug: 'inp-toulouse',
                name: 'INP Toulouse',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne le Groupe INP Toulouse, réseau d’écoles d’ingénieurs, dans sa stratégie de branding et sa stratégie web.',
                shortName: 'INP Toulouse',
                shortDesc: 'Passerelles et formations dans l’enseignement supérieur public',
                fullName: 'Groupe INP',
                quote: "Passerelles et formations dans l’enseignement supérieur public",
                content: `Le Groupe INP est le premier réseau d’écoles d’ingénieurs en France, avec près de 34 établissements formateurs. Afin de structurer et développer son activité de vente d’objets publicitaires à l’effigie de chaque école, le groupe d’enseignement INP a sollicité Pure afin de concevoir et de développer un site de e-commerce. Le groupe INP souhaite une solution propriétaire, autonome et accessible d’utilisation. 
                <br><br>
                En réponse, les équipes Pure ont imaginé et créé une plateforme digitale, intégrant une solution de vente en ligne pour les INP Toulouse et Bordeaux. Le projet comprend la création d’un univers graphique dédié, qui s’intègre avec harmonie à l’identité du groupe. `,
                lineSkill: 'Logotype & identité visuelle – UX/UI – Développement – Développement',
                csm: true,
                bc: true,
                sip: false,
                edition: false,
                sod: true,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'inp-row-1-img-1.jpg',
                                alt: 'INP Toulouse logo',
                            },
                            {
                                url: 'inp-row-1-img-2.jpg',
                                alt: 'INP Toulouse logo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'inp-row-2-img-1.jpg',
                                alt: 'INP Toulouse charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'inp-row-3-img-1.jpg',
                                alt: 'INP Toulouse site internet',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'inp-row-4-img-1.jpg',
                                alt: 'INP Toulouse',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'inp-row-5-img-1.jpg',
                                alt: 'INP Toulouse',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'inp-row-6-img-1.jpg',
                                alt: 'INP Toulouse',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'inp-row-7-img-1.jpg',
                                alt: 'INP Toulouse',
                            },
                            {
                                url: 'inp-row-7-img-2.jpg',
                                alt: 'INP Toulouse',
                            }
                        ]
                    }
                ]        
            },
            //Biomega
            {
                nextRefSlug: 'culo',
                nextRefName: 'Culo',
                nextRefCat: 'services',
                slug: 'biomega',
                name: 'Biomega',
                metaDesc: 'Pure, agence de communication à Toulouse, accompagne Biomega, société spécialisée dans le bio-nettoyage en milieu de santé, dans sa stratégie de communication globale.',
                shortName: 'Biomega',
                shortDesc: 'société spécialisée dans le bio-nettoyage, l’hygiène, les services hôteliers et la restauration en milieu sanitaire et social.',
                fullName: 'Bioméga',
                quote: "société spécialisée dans le bio-nettoyage, l’hygiène, les services hôteliers et la restauration en milieu sanitaire et social.",
                content: `Biomega est une société spécialisée dans le bio-nettoyage, l’hygiène et les services hôteliers en milieu de santé et en EHPAD. Face à de nouvelles perspectives de développement national,
                <br><br>
                Pure s’est vu confier le lifting de la marque et le renouvellement de son discours. Nos équipes sont également intervenues sur le développement de supports de marque cohérent avec l’évolution de l’ADN de la marque. `,
                lineSkill: 'Audit de l’existant – Étude de marché & data – Positionnement – Naming & signature de marque – Supports de marque – UX/UI – Développement ',
                csm: true,
                bc: false,
                sip: true,
                edition: true,
                sod: true,
                sms: false,
                event: false,
                gallery: [
                    {
                        type: "full",
                        images: [
                            {
                                url: 'biomega-row-1-img-1.jpg',
                                alt: 'Biomega logo avant après',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'biomega-row-2-img-1.jpg',
                                alt: 'Biomega charte graphique',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'biomega-row-3-img-1.jpg',
                                alt: 'Biomega all logo',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'biomega-row-4-img-1.jpg',
                                alt: 'Biomega',
                            }
                        ]
                    },
                    {
                        type: "1-1",
                        images: [
                            {
                                url: 'biomega-row-5-img-1.jpg',
                                alt: 'Biomega',
                            },
                            {
                                url: 'biomega-row-5-img-2.jpg',
                                alt: 'Biomega',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'biomega-row-7-img-1.jpg',
                                alt: 'Biomega',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'biomega-row-8-img-1.jpg',
                                alt: 'Biomega',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'biomega-row-9-img-1.jpg',
                                alt: 'Biomega',
                            }
                        ]
                    },
                    {
                        type: "full",
                        images: [
                            {
                                url: 'biomega-row-10-img-1.jpg',
                                alt: 'Biomega',
                            }
                        ]
                    }
                ]        
            }
        ]
    }
];